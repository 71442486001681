import React, {useEffect} from 'react';


// trimvideos.js is the parent file

/* global $ */
/* global moment */
import { showVideo, askSubt, showSubstyle, showTextstyle, showProgress, moveElement, setDim, strToMili, miliToTime, miliToMinTime} from './utils';
// import vid from './outputx.mp4';

// import {login_in_vid} from './firebase_login';

import {connect} from 'react-redux';

import {setTemplate1} from './setTemplate';
import {storeBar, storeSubstyle, storeHead, storeBox, storeVideo} from './storelocalstorage';

import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';

import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';

// import CancelIcon from '@material-ui/icons/Cancel';

import './xoxo.css';
// on hover, focus: resizable, drag
import './vid.css';

// import './playButton.css';
// import './bttn2.scss';
// import './zoomslider.css';

import './video_buttons.css';
import './vidslider.scss';
import CancelIcon from '@material-ui/icons/Cancel';

import Save from './downloadvid';

import Canvastry from './canvastry';

// import {dragresize} from './dragresize';
// import './dragresize.css';

import Nav from './navbar';


import Lang from './lang';

import Translate from './translate';

import axios from 'axios'; 

import Pricingvid from './pricingvid';
import { increment, update_x, edit_boxes, pop_boxes, invalidate, selectedText, render_x, decrement, reset } from "../actions/sampleAction";
import { navigate } from 'gatsby';



//  open vidstyle
const openStyle = function(e) {
  var x = document.getElementById('xcolumn').style.display 
  if(x=='flex') document.getElementById('xcolumn').style.display='none'
  else document.getElementById('xcolumn').style.display='flex'
}


//  close video modal
// const closeVid = function (e) {
//     document.getElementById("rx").style.display="block"
//     document.getElementById("xx").style.display="none"
//     document.getElementsByClassName("vid-fixed-top")[0].style.opacity = 1

// }


const clickbutton = function (e) {
	// console.log('event', e);
	e.preventDefault();
	e.stopPropagation();

	var play = document.getElementById("play");
  var pause = document.getElementById("pause");

	var v = document.getElementById('vid1');
  // alert("this should only appear once");
  // console.log('###### b bttn clas:', btn.className);
  if (v.paused) {
  	//console.log('b:pause',btn.className); 
  v.play(); 
  play.style.display='none';
  pause.style.display='inline-block';
  }
  else if (v.play) { 
  	//console.log('b:play',btn.className); 
  v.pause(); 
  play.style.display='inline-block';
  pause.style.display='none';
}
          
}


const muted = function(e) {

  var vUp = document.getElementById("vUp");
  var vOff = document.getElementById("vOff");

   var vm = document.getElementById("vid1");
  
   if ( vm.muted ) {
    vm.muted = false;
    vUp.style.display = 'inline-block';
    vOff.style.display = 'none';
 } else {
    vm.muted = true;
    vUp.style.display = 'none';
    vOff.style.display = 'inline-block';
 }

}





function Vid(props){

  console.log('props in Vid!', props.x)

const state = props.state;
// const changeCaptions = props.changeCaptions;
// const changeKey = props.changeKey;
console.log('in useeffect state', state);


// useEffect(() => {


// var t = 1*60*5*1000

// if(!localStorage.getItem('uid')) {
    
//     var log = setInterval(() => {
//     // if already signed In, clear this interval
//     if(localStorage.getItem('uid')) {
//       clearInterval(log)
//       return;
//     }

//     alert('Continue with Gmail')
//     login_in_vid(props.x, firebase)

//   }, t)
// }

// },[])




useEffect(() => {

var d = 0

var vid = document.getElementById("vid1"); 


vid.setAttribute("max", parseFloat(localStorage.getItem("duration")) )
document.getElementById("rangeset").setAttribute("max", parseFloat(localStorage.getItem("duration")))



var vCuTi = document.getElementById('vC');
// var dd = localStorage.getItem("duration")
  
if (vCuTi) {
  
  // vCuTi.innerText = miliToTime(vid.currentTime*1000).split('.')[0] + '/' + miliToTime(Math.ceil(props.state.duration)*1000).split('.')[0] ;
  // vCuTi.innerText = miliToTime(vid.currentTime*1000) + '/' + miliToTime(Math.ceil(parseFloat(props.state.duration))*1000) ;
  // vCuTi.innerText = miliToTime(vid.currentTime*1000) + '/' + miliToTime(Math.ceil(parseFloat(localStorage.getItem("duration")))*1000) ;
  // vCuTi.innerText = miliToTime(vid.currentTime*1000) + '/' + miliToTime(parseFloat(localStorage.getItem("duration"))*1000) ;
  vCuTi.innerText = miliToMinTime(vid.currentTime*1000) ;
  // + '/' + miliToTime(parseFloat(localStorage.getItem("duration"))*1000) ;
}



// seekbar
vid.ontimeupdate = function(e){
	// console.log('in seekbar');
	e.preventDefault();
  e.stopPropagation();

  try {
  var percentage = ( vid.currentTime / vid.duration ) * 100;

  if (percentage == 100) {
        var play = document.getElementById("play");
        var pause = document.getElementById("pause");

        play.style.display='inline-block';
        pause.style.display='none';
  }

  var slider = document.getElementById("rangeset");
  slider.value = vid.currentTime; 

  var sp1 = document.querySelectorAll('#custom-seekbar1 > span')[0];
  sp1.style.width = percentage+"%";

  console.log('@@@ PERCENTAGE @@@', percentage)

 } catch {}

};


// pause/play on video click 
var myVideo = document.getElementById("vid1");
// play pause video

// Zoom in video 
/* Array of possible browser specific settings for transformation */
var properties = ['transform', 'WebkitTransform', 'MozTransform','msTransform', 'OTransform'],
    prop = properties[0];

/* Iterators and stuff */    
var i,j;

var stage = document.getElementById('stage');
/* Find out which CSS transform the browser supports */
for(i=0,j=properties.length;i<j;i++){
  if(typeof stage.style[properties[i]] !== 'undefined'){
    prop = properties[i];
    break;
  }
}

// // console.log('prop', prop);

var v = document.getElementById('vid1');

var zoom = 1 ;
v.style[prop]='scale('+zoom+')';

var slider = document.getElementById("rangeset");
slider.addEventListener("input", function(e){
  e.preventDefault();
  e.stopPropagation();
  var v = document.getElementById('vid1');
    // document.getElementById("demo").innerHTML = this.value;
    slider.value = parseFloat(slider.value)
    
    // v.load()
    v.currentTime = parseFloat(slider.value);
  
});


if ( props.state.width ) {
  var g = (props.state.width/props.state.height).toFixed(1)

} else {
  console.log('local storage', localStorage.getItem("width"), localStorage.getItem("height"), localStorage.getItem("duration"))
  var g = parseFloat(localStorage.getItem("width"))/parseFloat(localStorage.getItem("height"))

  try {
  g = g.toFixed(1)
  console.log('localgg', g);
} catch {}
}
  // console.log('gg', g, props.state.width, props.state.height);
  setDim(g, props);

// }, [state, changeKey, changeCaptions])
}, [] )


useEffect(() => {

if (localStorage.getItem('progress') == "0" && localStorage.getItem('reload') == "1" && !localStorage.getItem('uid')) {

        window.location.href = window.location.origin;

}

// window.addEventListener("hashchange", function(e) {
//   alert("STOPPP!!!")
//   // ...
// })

$(window).on('beforeunload', function ()
    {
 
    if (localStorage.getItem('progress') == "0" ) {

      // localStorage.setItem("reload", "1")
      
      var user_id = localStorage.getItem('uid')
      var d = {}
      d['uid'] = user_id


      navigate(`/workspace/?w=${user_id}`, 
          {state: { videos: d }} )

    }

//       document.getElementById("alert").style.display = "block";
      
    var fname = localStorage.getItem('filename').split('.')[0] + '.json'
    var url = 'https://xplks8isk4.execute-api.us-west-2.amazonaws.com/default/json_to_s3?key=' + fname

    console.log('sending json to s3', url)
    axios.post(url, JSON.stringify(JSON.parse(JSON.stringify(localStorage))),{
            headers: {
              'Content-Type': 'application/json',
            },
          }).then((res) => {
            console.log('POST JSON res', res)}).catch(err => {console.log('json post ERROR', err)}) 
      return undefined;
});



}) //close useEffect





// mutation observer, to save the code


useEffect(() => {

document.getElementById('vid1').src= localStorage.getItem('vid')

// window.location.search.split('?v=')[1] + '.json'


document.getElementById('rangeset').setAttribute('max', localStorage.getItem("duration"))
},[])


// <CancelIcon id="cancel" onClick={closeVid} style={{ display: 'inline-block', cursor: 'pointer' }} />
// <span id="vidstyl" onClick={openStyle} style={{'cursor': 'pointer'}}>Click to Style Video</span>

return (
      <div>
      <Pricingvid props={props} />

      <Translate />

      
      <Lang />

      <div id="an" />

		  <div id="vcolumn">

      <div id="sav">
        <Save props={props.x} canv={props}/>
      </div>


      <div className="file-progress-container"></div>
			<div className='item'>

				<div id="vid_container">
					<div id="vid">

              <div id="stage">
              <div id="vx">
                <video id="vid1" />
                
              </div>
              <Canvastry props={props} />
              <rect id="custom-seekbar1">
                <span id="custom-seekbar11" />
              </rect>

              </div>
						
            



          </div>
        </div>





    
<div id="extra-control">
<div className="icon-bar">
  <a id="vol" onClick={muted}>
    <VolumeUpIcon id="vUp" style={{ display: 'inline-block' }}/>
    <VolumeOffIcon id="vOff" style={{  display: 'none' }}/>
  </a> 

  <a id = "playy" onClick={clickbutton}>
    <PlayCircleFilledIcon id="play" style={{  display: 'inline-block' }}/>
    <PauseCircleFilledIcon id="pause" style={{  display: 'none' }}/>
  </a> 


  <div id="time">
    <div id="vC"/>
    <span />
  </div>

  <div className="pbar" style={{ width:'100%' }}>
    <div className="slidercontainer">
    <input type="range" min="0.0" step="any" value="0.0" className="slide" id="rangeset" />
    </div>
  </div>

</div>
</div>









</div>


</div>
</div>
	)
}


const mapStateToProps = (state) => {
  console.log('!!!mapStateToProps', state)
  return {
  boxes2: state.data.boxes2,
  canvasValid: state.data.canvasValid,
  selectText: state.data.selectText,

  xout: state.data.xout,
  width: state.data.width,
  height: state.data.height,
  pc: state.data.pc,
  back_bar: state.data.back_bar, 
  front_bar: state.data.front_bar,
  bar_height: state.data.bar_height,
  duration: state.data.duration,
  dvid: state.data.dvid,
  pre_sub: state.data.pre_sub,
  ti: state.data.ti,

  render: state.data.render,
  subscribe: state.data.subscribe,
  uid: state.data.uid,
  email: state.data.email,
  pic: state.data.pic,
  filename: state.data.filename,
  
}}


Vid.displayName = "Vid";
export default connect(mapStateToProps,{ increment, update_x, edit_boxes, pop_boxes, invalidate, selectedText, render_x, decrement, reset })(React.memo(Vid));
// the setting bar towards left to change styling


import React, { useState,useEffect } from 'react';
// import Color from './colors';
import Colors2 from './colors2';
import Textstyle from './textstyle';
import './style.css';
// import Save from './saveState';
// import Save from './downloadvid';


import CancelIcon from '@material-ui/icons/Cancel';

import {setTemplate1} from './setTemplate';
import {setWidth, setDim, miliToTime} from './utils'
import axios from 'axios'; 

import {login_in_work} from './firebase_login';


// import {fade} from 'material-ui/styles/colorManipulator';

import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import SubtitlesOutlinedIcon from '@material-ui/icons/SubtitlesOutlined';
import TextFieldsOutlinedIcon from '@material-ui/icons/TextFieldsOutlined';



// import Color from './colors';


// import Tab1 from './tab1';


// import {fade} from 'material-ui/styles/colorManipulator';

import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import Boxstyle from './boxstyle';

import { showVideo, askSubt, showSubstyle, showTextstyle, showProgress } from './utils';

import {connect} from 'react-redux';
import { increment, update_x, edit_boxes, pop_boxes, invalidate, selectedText, render_x, decrement, reset } from "../actions/sampleAction"

import useFirebase from './useFirebase';
import { navigate } from "gatsby" 

const opentrans = function(e) {
    document.getElementById("trans").style.display = 'block'
}

const trans_1 = function(e) {
    e.preventDefault();
      e.stopPropagation();

      let data = JSON.parse(localStorage.getItem('langx'))

      if(!data) return;
      
      let k = data.length

      for (let i=0; i<k; i++) {
        document.getElementById(i).innerText = data[i]
      }

      e.currentTarget.setAttribute('x', '1')
      document.getElementById("an2").setAttribute('x', '0')

      localStorage.setItem('subx', localStorage.getItem('langx'))

      document.getElementById("an").click()

    
}


const trans_2 = function(e) {


      e.preventDefault();
      e.stopPropagation();

      let data = JSON.parse(localStorage.getItem('transx'))

      if(!data) return;
      let k = data.length

      for (let i=0; i<k; i++) {
        document.getElementById(i).innerText = data[i]
      }

      e.currentTarget.setAttribute('x', '1')
      document.getElementById("an1").setAttribute('x', '0')

      localStorage.setItem('subx', localStorage.getItem('transx'))
      document.getElementById("an").click()


}

function showWorkspace(e, props, firebase) {

  console.log(e, props, 'before workspace')


  var uid = localStorage.getItem('uid')
  if( uid ) {

    var d = {}
    d['uid'] = uid

    // props.props.history.push({
    //       pathname: `/workspace/${uid}`
    //       ,
    //       state: { videos: d }
    // })

      navigate(`/workspace/?w=${uid}`, 
          {state: { videos: d }
        })


} else {

  // debugger

  // original code
  // login_in_work(e, props.props, firebase)
  console.log('login_in_work', props)
  login_in_work(e, props, firebase)
}

  // window.history.back()

}



function Style(props){

  const firebase = useFirebase();

// set the bkgrnd color as default
 
  // const [target, setTarget] = useState(0); 
  // const [d, setD] = useState("s"); 
  // const [s, setS] = useState("false")



	useEffect(() => {


    document.getElementById('xcolumn').style.display='flex'

	},)


// vc-saturation selects the color
//  onClick={(e) => generateSub(e, s, setS)}

//      <Boxstyle state={props.state} s={s} />

// <button id="showProgress" onClick={(e) => showProgress(e)} type="button" className="btn btn-default" data-toggle="tab">Add Progress Bar</button>

return (
  <div id="stylex" className="styling">


    <div className='allfile_trans allfile_trans_2'>

    <button id='myvideos' onClick={(e)=> showWorkspace(e, props, firebase)} type="button" className="btn btn-default" data-toggle="tab">All Files</button>

    <button id="opentransx" onClick={(e) => opentrans(e)} type="button" className="btn btn-default" data-toggle="tab">Translate</button>

    <span id="an1" onClick={(e) => trans_1(e)} x="1" className="lang1"></span>  
    <span id="an2" onClick={(e) => trans_2(e)} x="0" className="lang1"></span>


    </div>

    <div className='allfile_trans'>

    	<div id="box">
       <Boxstyle state={props.state} />
      </div>


      <div id="onlystyle">
          <button id='videoEdit' onClick={showVideo} type="button" className="btn btn-default" data-toggle="tab"> Video <VideocamOutlinedIcon/> </button>

          <button id='askSubt' onClick={(e) => askSubt(e)} type="button" className="btn btn-default" data-toggle="tab" autoFocus> Auto Subtitles <SubtitlesOutlinedIcon/> </button>

          <button id="showSubstyle" type="button" onClick={(e) => showSubstyle(e)} className="btn btn-default" data-toggle="tab">Style Subtitle</button>
          <button id="showTextstyle" type="button" onClick={(e) => showTextstyle(e)} className="btn btn-default" data-toggle="tab"> Add Text <TextFieldsOutlinedIcon/> </button>
       
    	</div>

    </div>
  </div>
)
}

const mapStateToProps = (state) => {
  console.log('!!!mapStateToProps', state)
  return {
  boxes2: state.data.boxes2,
  canvasValid: state.data.canvasValid,
  selectText: state.data.selectText,

  xout: state.data.xout,
  width: state.data.width,
  height: state.data.height,
  pc: state.data.pc,
  back_bar: state.data.back_bar, 
  front_bar: state.data.front_bar,
  bar_height: state.data.bar_height,
  duration: state.data.duration,
  dvid: state.data.dvid,
  pre_sub: state.data.pre_sub,
  ti: state.data.ti,

  render: state.data.render,
  subscribe: state.data.subscribe,
  uid: state.data.uid,
  email: state.data.email,
  pic: state.data.pic,
  filename: state.data.filename,
  
}}


Style.displayName = "Style";
export default connect(mapStateToProps, { increment, update_x, edit_boxes, pop_boxes, invalidate, selectedText, render_x, decrement, reset })(React.memo(Style));

import axios from 'axios'; 

import React, { useState,useEffect } from 'react';
import './pricingvid.css';
import Navpricing from './navbarpricing';
import Navwork from './navbarwork';
// import Nav from './navbar';

import {login_pricing_home, login_sub, getProfilePicUrl, getUserName, isUserSignedIn, login_in_vid} from './firebase_login';
import CircularProgress from '@material-ui/core/CircularProgress';

import {checkout, stripe_checkout_download} from './striperedirect';

import {connect} from 'react-redux';
import { increment, update_x, edit_boxes, pop_boxes, invalidate, selectedText, render_x, decrement, reset } from "../actions/sampleAction";

import useFirebase from './useFirebase';

// import Stripex from './stripex';

function Pricingvid(props){

  const firebase = useFirebase();

// useEffect(() => {


// var t = 1*60*5*1000

// if(!localStorage.getItem('uid')) {
    
//     var log = setInterval(() => {
//     // if already signed In, clear this interval
//     if(localStorage.getItem('uid')) {
//       clearInterval(log)
//       return;
//     }

//     alert('Continue with Gmail')
//     login_in_vid(props.x, firebase)

//   }, t)
// }

// },[])



 useEffect(() => {

        document.title = 'TurtleClip | Pricing';
        // document.getElementById("raz").appendChild(script);
    // document.getElementsByName("email")[0].value = "oyeiitsme@gmail.com"

    // set value of subscribe in localstorage
    if (localStorage.getItem('uid')) {
        var suburl = 'https://mxfrnd93z6.execute-api.us-west-2.amazonaws.com/default/read_subscribe_table?email=' + localStorage.getItem('email')

        axios.get(suburl).then(response => {

          console.log('subscribe in Renderdown', response.data.Item)
          localStorage.setItem('cn', response.data.Item.count.S)
      
        // if the item doesnt exist in subscribe table
        }).catch(e => {
          localStorage.setItem('cn', '0')
        })

        console.log('subscribe in Renderdown', localStorage.getItem('cn'))
    }

    }, []);


   useEffect(() => {

   		try {
		document.getElementById('login_p').addEventListener('click', (e) => {

        e.preventDefault();
        e.stopPropagation();

        login_pricing_home(e,props,firebase)
}
);
} catch {}


   		try {
		document.getElementById('pay_').addEventListener('click', (e) => {

			console.log('first login in sub')


      var dd = window.location.href.split('/').slice(-1)[0]
			var email = localStorage.getItem('email')

        // e.preventDefault();
        // e.stopPropagation();

        console.log(dd)

        document.getElementById("circlePricing").style.display = 'block'
        stripe_checkout_download(dd, email)


  //      	var nav = <Navwork props={props} />;
		// var log = <a href={work} className="login_price">Try Now</a>

        // document.getElementsByClassName('PaymentButton')[0].click()

}
);
} catch {}


}) 


	 


return (

	<div id='pvid'>

	<CircularProgress style={{'display':'none'}} id="circlePricing" className="Mpbar" />

	<div id="vprizing">


	<div className='tier'>
		<div className="priceTag">PRO</div>
    <div id="pay_" className="vlogin_price"> SUBSCRIBE </div>
		<div className="vpriceVal">$19 <span className='permonth'>/Month</span></div>
		<div className='nv'>10 videos</div>
		<div>Automatic transcription</div>
		<div>Add text</div>
		<div>Add and style top heading</div>
		<div>Video resizing</div>
		<div>Progress bar</div>
		<div>Style and position subtitle</div>
		<div>No Watermark</div>
	</div>

	
	</div>



	</div>

)
}


const mapStateToProps = (state) => {
  console.log('!!!mapStateToProps', state)
  return {
  boxes2: state.data.boxes2,
  canvasValid: state.data.canvasValid,
  selectText: state.data.selectText,

  xout: state.data.xout,
  width: state.data.width,
  height: state.data.height,
  pc: state.data.pc,
  back_bar: state.data.back_bar, 
  front_bar: state.data.front_bar,
  bar_height: state.data.bar_height,
  duration: state.data.duration,
  dvid: state.data.dvid,
  pre_sub: state.data.pre_sub,
  ti: state.data.ti,

  render: state.data.render,
  subscribe: state.data.subscribe,
  uid: state.data.uid,
  email: state.data.email,
  pic: state.data.pic,
  filename: state.data.filename,
  
}}


Pricingvid.displayName = "Pricingvid";
export default connect(mapStateToProps, { increment, update_x, edit_boxes, pop_boxes, invalidate, selectedText, render_x, decrement, reset })(React.memo(Pricingvid));

import React, { useState,useEffect } from 'react';

import {connect} from 'react-redux';
/* global $ */
/* global moment */

import './xoxo.css';
import './tabbox.css';
// import './playButton.css';
import './bttn2.scss';
import './zoomslider.css';
// import {moveElement, keytimer, setWidth, setTickerState, getAbcString, gettime, tickerTime, strToMili, miliToTime, miliToMinTime, miliToHrTime, getLowestIndex} from './utils';

import {moveElement, setWidth, setTickerState, getAbcString, gettime, tickerTime, strToMili, miliToTime, miliToMinTime, miliToHrTime, getLowestIndex} from './utils';

// import {keytimer} from './canvastry';

import TickerTime from './div_ticker';
import Reset from './reset';
// import Save from './saveState';
// import Save from './downloadvid';

import Vidstyle from './vidstyle';
// import {arrangeSub} from './arrangesub';
import {setSubTime} from './arrangesub';


import AddCircleIcon from '@material-ui/icons/AddCircle';
import TimerOutlinedIcon from '@material-ui/icons/TimerOutlined';
import { increment, update_x, edit_boxes, pop_boxes, invalidate, selectedText, render_x, decrement, reset } from "../actions/sampleAction";

import {sendJsonToS3Export} from './storelocalstorage';


// const addticker = function(e, state, changeTime, changeBothTime, changeSub, changeCaptions) {
const addticker = function(e, props) { 
  console.log('in addticker');

  e.preventDefault();
  e.stopPropagation();

  // https://stackoverflow.com/questions/4456545/how-to-insert-text-at-the-current-caret-position-in-a-textarea

  // need to get the exact location where it should be added
  // + update time + update id + this.state 

  // https://stackoverflow.com/questions/2920150/insert-text-at-cursor-in-a-content-editable-div
    
  if (document.getElementsByClassName("current-caption") && document.getElementsByClassName("current-caption")[0]) {

// document.getElementsByClassName("caption")[idn] can't use this, span idn=8 before spna idn=6
// byclassnames will be a list in order flow 0-1-2-3-4-5-8-7-6

  // can have element with EXACTLY same text value! - whihc one to highlight then!?
  // var idn = window.getSelection().getRangeAt(0).startContainer.parentNode.id;
  const selectedSub = document.getElementsByClassName("current-caption")[0]
  const idn = selectedSub.id;
  console.log("span idn ", idn);


    /* var cursorPos = $('#text').prop('selectionStart') */
    // var cursorPos = window.getSelection().getRangeAt(0).startOffset;
    var cursorPos = window.getSelection().getRangeAt(0).startOffset;
    console.log('old curpos:', cursorPos)
    console.log(window.getSelection().getRangeAt(0).startContainer.parentNode)
    // var v = document.getElementById(idn).innerText;
    const v = selectedSub.innerText;
    console.log('value',v);
    console.log('char',v.charAt(cursorPos), 'cursor:',cursorPos,'v', v);

    var pa_stime = selectedSub.getAttribute("stime");
    var pa_etime = selectedSub.getAttribute("etime");
    // console.log(v.charAt(cursorPos) == ' ')

    if (v.charAt(cursorPos) != ' ' || v.charAt(cursorPos-1) != ' ') {

      var indices = [];
      indices.push(0); indices.push(v.length);
      for(var i=0; i<v.length;i++) {
      		// console.log('NO NO NO')
          if (v[i] === " ") indices.push(i);
      }

      var indices2 = indices.map(function(value) { 
          return Math.abs(value - cursorPos); 
        });

      console.log('position of space:',indices);
      var p = getLowestIndex(indices2);
      console.log(p)
      cursorPos = indices[p];
      

      }

    console.log('new curpos', cursorPos );
    // console.log('!!add ticker cursorPos', cursorPos, 'v',v.length);
    var tim = gettime(idn, cursorPos);
    // console.log('in add ticker get time:',t);
    var t = miliToMinTime(tim);
    //console.log('in add ticker get time:',t);

    // console.log('selected sub-', 'sub value before-',selectedSub.value);
    var textBefore = v.substring(0,  cursorPos );
    var textAfter  = v.substring( cursorPos, v.length );
    
    selectedSub.innerText =  textBefore;
    console.log(selectedSub.innerText, 'tB',textBefore);
    // console.log('selected sub', 'cur',cursorPos,'textBefore', textBefore, 'selected sub value:', selectedSub.value);
    // props.changeCaptions(textBefore);

    // console.log("child", document.getElementById("subdiv").children );
    // console.log(' strt length:', strt);

    var i = document.getElementsByClassName("caption").length;
    var pa = selectedSub;

    var cdiv = document.createElement("div");
    cdiv.setAttribute("caption-break", "");  

    // strt[i] = t;
    // console.log('strt[i]', t);

    // cdiv.setAttribute("time", t);
 
    cdiv.className = "caption-break";
    cdiv.contentEditable = "true";
    cdiv.draggable = "true";

    // var tickdiv = document.createElement("div");
    // tickdiv.setAttribute("className", 'input-group date datepicker-me-class');
    // tickdiv.setAttribute("class", 'input-group date datepicker-me-class');

    // var ticker = document.createElement("button");
    var ticker = document.createElement("span");

    //do not write random text in time button
    ticker.setAttribute("contentEditable", "false");
    ticker.setAttribute("id", "bt"+i.toString());
    ticker.setAttribute("class", "tickers");
    // console.log('bttn id', ticker.id);
    ticker.style.position = 'relative';

    // var spantick = document.createElement("SPAN");
    // spantick.setAttribute("className","glyphicon-time glyphicon");
    // spantick.setAttribute("class","glyphicon-time glyphicon");
    // // spantick.setAttribure("id", "ticker"+i.toString())

    // var inputtick = document.createElement("input");
    // inputtick.setAttribute("type", "hidden");
    // inputtick.setAttribute("id", "datetimepicker"+i.toString());
    
    // cdiv.appendChild(tickdiv);
// BEFORE TOOLTIPPP
    cdiv.appendChild(ticker);

// AFTER ADDING TOOLTIP
    // var z = document.createElement('DIV')
    // z.setAttribute("class","tooltip")
    // var zz = document.createElement('SPAN')
    // zz.setAttribute("class", "tooltiptext")
    // zz.innerText = "click to combine two subtitles or edit the timing"
    // z.append(zz)
    // z.append(ticker)
    // cdiv.append(z)


/////////////////


    // ticker.appendChild(spantick);
    // tickdiv.appendChild(inputtick);

    // pa.appendChild(cdiv);
    pa.insertAdjacentElement("afterend", cdiv);

    var cspan = document.createElement("span");
    cspan.setAttribute("stime", t);
    cspan.setAttribute("etime", pa_etime);

    selectedSub.setAttribute("etime", t);

    cspan.setAttribute("className", "caption current-caption");
    cspan.setAttribute("class", "caption current-caption");
        
    cspan.setAttribute("contentEditable", "true");

    cspan.innerText = textAfter; //sub[i];
    cspan.setAttribute("id", i);

    console.log('new ticker text', cspan.innerText);



    // pa.appendChild(cspan);
    cdiv.insertAdjacentElement("afterend", cspan);

    // cspan.className = "caption";
    cspan.setAttribute("className", "caption");
    cspan.setAttribute("class", "caption");

    // ticker.style.backgroundColor = 'black';
   	// ticker.style.color = 'blue';
   	// ticker.style.borderColor = "pink";
   	ticker.style.padding = "2px";
   	ticker.setAttribute("width", "30px");
   	// inputtick.setAttribute("type", "hidden");
   	cspan.setAttribute("lineHeight","10em");
    // ticker.innerHTML = t;
    ticker.innerHTML = `<li class='fa'>&#xf017</li>`;


    const newtime = JSON.parse(localStorage.getItem('strt')).slice()
    newtime[i] = t;

    const newsub = JSON.parse(localStorage.getItem('subx')).slice() //copy the array
    newsub[idn] = textBefore;

    newsub[i] = textAfter;

    const endnew = JSON.parse(localStorage.getItem('en')).slice();
    endnew[idn] = t; //parent ticker

    // console.log('!new ticker text', state.txt);
    // var endi = document.getElementById(i).nextSibling.nextSibling.getAttribute("id");
    // endnew[i] = endnew[endi]; 
    endnew[i] = JSON.parse(localStorage.getItem('en'))[idn];  //new ticker
 	// en[idn] = strt[i];
 	// en[i] = en[idn] ; //endtime for new ticker is endtime of parent ticker 
  console.log("change both time - ",'new',t);
 	console.log("change both time - ",'new',i, newsub[i], newtime[i], endnew[i]);
  console.log("change both time - ",'parent',idn, newsub[idn], newtime[idn], endnew[idn]);
  // props.changeBothTime(newsub, newtime, endnew) ; 

  //   document.getElementById("subtitle-text-container").innerText = document.getElementsByClassName('current-caption')[0].innerText;

  //   // setState() 
  // //  console.log('end of addticker state', props.state); 
  //   var vi = document.getElementById("subtitles-text-container");
  //   vi.style.visibility="visible";  
}
}

const showTicker = function(e, t, x, y) {
	console.log("showticker", e.target); //e.target: span id="bt6"

  var pa 


	if (e.target.classList.contains("caption-break") || e.target.parentNode.classList.contains("caption-break") || e.target.parentNode.parentNode.classList.contains("caption-break") ) {

      if ( e.target.parentNode.classList.contains("caption-break") ) {
        pa = e.target.parentNode
      }

      if ( e.target.parentNode.parentNode.classList.contains("caption-break") ) {
        pa = e.target.parentNode.parentNode
      }

      if ( e.target.classList.contains("caption-break") ) {
        pa = e.target
      }


      // var tickid = e.target.parentNode.nextSibling.id;
      var tickid = pa.nextSibling.id;

      var f = document.getElementById(tickid).previousSibling;

      var offset = f.getBoundingClientRect();  
      
// ///////////////////////////////////////////////////////////////////////////////
  //     // dont show ticker at BEGIN and END
  //     var gPa = document.getElementById("subdiv").children
	 //  if (tickid == gPa[1].id || tickid == gPa[gPa.length-1].id) {
		// return;
	 //  }
/////////////////////////////////////////////////////////////////////////////////////

	  document.getElementById("rows").style.display = "block";

	  setTickerState(tickid); 


      t(tickid);
      // x(e.clientX);
      // y(e.clientY);
      x(offset.left + offset.width/2);
      y(offset.top + offset.height);      
     } 
}


function Tabbox(props){

	const [tickid, setTickid] = useState("0"); //cspan
	const [posX, setPosX] = useState(0);
	const [posY, setPosY] = useState(0);
	const [display, setDisplay] = useState("none");

 // const [txy, setTxy] = useState([]);
	
  // const txy = localStorage.getItem("Yes");


	useEffect(() => {

  const txy = localStorage.getItem("Yes");

	document.getElementById("addSubz").addEventListener("click", (e) => {
		e.preventDefault();
    e.stopPropagation();
		// console.log('addticker before state', props.state);
		addticker(e, props)
		});

    // var key = document.getElementById("subdiv");

    // key.addEventListener("DOMSubtreeModified", (e) => keytimer(e));

    

	  // key.addEventListener("keydown", (e) => {keytimer(e) }); //slow


	// key.addEventListener("mouseup", (e) => keytimer(e)); //doin nthng
	// key.addEventListener("mousedown", (e) => keytimer(e));
	// key.addEventListener("click", (e) => {
 //    keytimer(e)
 //    // setWidth(2);

 //    // console.log('clinet', e.clientY, e.pageY)
    
 //    try {
 //    // document.getElementById("addSubz").removenext(document.getElementById("plus"))
 //    document.getElementById("plus").remove();
 //  } catch {
 //    console.log("no child");
 //  }


//   function getpos(e) {
//   var posx = 0;
//   var posy = 0;
//   if (!e) var e = window.event;
//   if (e.pageX || e.pageY)   {
//     posx = e.pageX;
//     posy = e.pageY;
//   }
//   else if (e.clientX || e.clientY)  {
//     posx = e.clientX + document.body.scrollLeft
//       + document.documentElement.scrollLeft;
//     posy = e.clientY + document.body.scrollTop
//       + document.documentElement.scrollTop;
//   }
//   return [posx, posy]
// }
//     document.getElementById("addSubz").style.marginTop = getpos(e)[1] - 190 + 'px';
//     // document.getElementById("addSubz").style.marginRight = -200 + 'px';


//     var xp = document.getElementById("addSubz").getBoundingClientRect();

//       var cy = getpos(e)[1]  ;
//       var color = 'rgba(255,0,255,0.3)';
//       var thickness = 1;
//       var cx = e.clientX;

      
//       var xx = xp.left + xp.width/3
//       var length = xx - cx  
//       var htmlLine = "<div id=plus style='padding:0px; margin:0px; height:" + thickness + "px; background-color:" + color + "; line-height:1px;left:" + cx + "px; position: fixed; top:" + cy + "px; width:" + length + "px' />";
//     //
//     // alert(htmlLine);
//     // document.getElementById("addSubz").innerHTML += htmlLine;
//     document.getElementsByClassName("tooltip")[0].insertAdjacentHTML('afterend', htmlLine);

//   });
	// key.addEventListener("keypress", (e) => keytimer(e));
	//key.addEventListener("keyup", (e) => keytimer(e));

	// key.removeEventListener('keydown', handleKeyDown, true);
 //  key.removeEventListener('mousedown', handlePointerDown, true);
 //  doc.removeEventListener('pointerdown', handlePointerDown, true);
 //  doc.removeEventListener('touchstart', handlePointerDown, true);
 //  doc.removeEventListener('visibilitychange', handleVisibilityChange, true);


	document.getElementById("subdiv").addEventListener("click", (e) => {
		// document.getElementById("rows").style.display = "block";
     //  	e.preventDefault();
    	// e.stopPropagation();
      	showTicker(e, setTickid, setPosX, setPosY);
      });

	},[])

useEffect(() => {  //never update this part

console.log('BBBBBBBBBBBB', JSON.parse(localStorage.getItem('subx')))
// console.log(JSON.parse(localStorage.getItem('subx')).length)

document.getElementById("subdiv").innerHTML = '';

console.log('DURATIONNNN', miliToTime(parseFloat(localStorage.getItem("duration"))*1000))

try {

// console.log('tab1 state', props.state.sub.length)
for (var i=0; i<JSON.parse(localStorage.getItem('subx')).length ; i++){

      localStorage.setItem("showSub", "1")
      console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA", props)
      var pa = document.getElementById("subdiv") ;

      var cdiv = document.createElement("div");
      cdiv.setAttribute("caption-break", "");  


      cdiv.className = "caption-break";
      cdiv.contentEditable = "false";
      cdiv.draggable = "true";
	  
      var ticker = document.createElement("span");

      //to not write random text in time button
      ticker.setAttribute("contentEditable", "false");
      ticker.setAttribute("class", "tickers");
      // ticker.className = "tooltiptext"

      // console.log('bttn id', ticker.id);
      ticker.style.position = 'relative';
      // console.log('input id', inputtick.id);

     // <div className="tooltip">
     // <span className="tooltiptext">Combine the two subtitles</span>

// ACTUALLLLLLLLLL
      cdiv.appendChild(ticker);

      pa.appendChild(cdiv);

      var cspan = document.createElement("span");

      // t = 00:01.23
      console.log('i',i, JSON.parse(localStorage.getItem('strt'))[i], JSON.parse(localStorage.getItem('subx')).length)
      var t = JSON.parse(localStorage.getItem('strt'))[i]

      // so that only one decimal place is included
      t = miliToMinTime(strToMili(t))

      cspan.setAttribute("stime", t);
      var t = JSON.parse(localStorage.getItem('en'))[i]

      // so that only one decimal place is included
      t = miliToMinTime(strToMili(t))

      cspan.setAttribute("etime", t);

      cspan.setAttribute("className", "caption current-caption");
      cspan.setAttribute("class", "caption current-caption");

      // var att = document.createAttribute("contentEditable");      
      // att.value = "true";                         
      cspan.setAttribute("contentEditable", "true");

    
    pa.appendChild(cspan);

    if (i!=0) {
    // console.log('i',i);
    cspan.setAttribute("className", "caption");
    cspan.setAttribute("class", "caption");
    }

   ticker.style.padding = "4px";

  var t = JSON.parse(localStorage.getItem('strt'))[i]
  t = miliToMinTime(strToMili(t))

  ticker.innerHTML = `<li class='fa'>&#xf017</li>`;

  ticker.setAttribute("id", "bt"+i.toString());
  cspan.setAttribute("id", i);

// if i refresh the page, and subx == transx, then this updates langx as well
  cspan.innerText = JSON.parse(localStorage.getItem('langx'))[i];

}

  sendJsonToS3Export(localStorage.getItem('filename'))

} catch(e) {
  console.log(e)
 // arrange subtitles at t=0
}
 

},[props.s])


// TRANSLATE 

useEffect(() => {

    try {

     document.getElementById('an1').innerText = localStorage.getItem('lang').split('-')[0]

  } catch(e) {
    console.log('ignore',e)
  }


    if( localStorage.getItem('trans') ) {
    document.getElementById('an2').style.display = 'block';
    document.getElementById('an2').innerText = localStorage.getItem('trans')
  } else {
    
  }




},)

	return (
	<div id="dcolumn">

		<div className="jss586">
			<div className="zub-editor-dkflgl">
           
       		 <TickerTime tickid={tickid} posX={posX} posY={posY} />
           

		       <div id="editor" className="editor">

		     	 <div id="subdiv" contentEditable="false" className="ProseMirror ProseMirror-example-setup-style" />

           <div className="tooltip">
           <span className="tooltiptext">Add Subtitle at Cursor position</span>
           <button id="addSubz" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <AddCircleIcon style={{ fontSize:30 }} />
           </button> 
            </div>

           </div>

            </div>
        </div>

        <div id="loading">
          <img id="loading-image" src="" alt="Loading..." />
        </div>
          
     </div>
		)
}


// Map Redux state to React component props
const mapStateToProps = (state) => {
  console.log('!!!mapStateToProps', state)
  return {
  boxes2: state.data.boxes2,
  canvasValid: state.data.canvasValid,
  selectText: state.data.selectText,

  xout: state.data.xout,
  width: state.data.width,
  height: state.data.height,
  pc: state.data.pc,
  back_bar: state.data.back_bar, 
  front_bar: state.data.front_bar,
  bar_height: state.data.bar_height,
  duration: state.data.duration,
  dvid: state.data.dvid,
  pre_sub: state.data.pre_sub,
  ti: state.data.ti,

  render: state.data.render,
  subscribe: state.data.subscribe,
  uid: state.data.uid,
  email: state.data.email,
  pic: state.data.pic,
  filename: state.data.filename,
  
}}

// Connect Redux to React
Tabbox.displayName = "Tabbox";
export default connect(mapStateToProps, { increment, update_x, edit_boxes, pop_boxes, invalidate, selectedText, render_x, decrement, reset })(React.memo(Tabbox));

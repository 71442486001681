// the setting bar towards left to change styling

/* global $ */

import { showTextstyle} from './utils';

import React, { useState,useEffect } from 'react';

import {connect} from 'react-redux';

// import Color from './colors';
import Colorsbox from './colorsbox';
import Textstyle from './textstyle';
import Textstylesub from './textstylesub';
import './boxstyle.css';

import Tabbox from './tabbox';

import CancelIcon from '@material-ui/icons/Cancel';

import {setTemplate1} from './setTemplate';
import {deleteText, setWidth, setDim, miliToTime, miliToHrCommaTime} from './utils'
import axios from 'axios'; 

// import {fade} from 'material-ui/styles/colorManipulator';

import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
// const closeStyle = function (e) {
//       document.getElementById("xcolumn").style.display="none"
// }
import CircularProgress from '@material-ui/core/CircularProgress';

import {arab_splitSub, splitSub} from './arrangesub';
import { increment, update_x, edit_boxes, pop_boxes, invalidate, selectedText, render_x, decrement, reset } from "../actions/sampleAction"

// import {storeHead} from './storelocalstorage';


// function deleteText(e) {
//   // var y = e.target.parentNode.classList.contains("delH") || e.target.parentNode.parentNode.classList.contains("delH")
  
//   console.log('deleteText', e.currentTarget, e.currentTarget.parentNode)
//   var y ;
//   if ( e.currentTarget.parentNode.classList.contains("delH") ) {
//     y = e.currentTarget.parentNode
//   } else {
//     y = e.currentTarget.parentNode.parentNode
//   }

//   var z = y.id
//   console.log('y', y, z.split('z')[0])
//   document.getElementById(z.split('z')[0]).remove()
//   y.remove()

//   // document.getElementsByClassName("heads-text-container")[0].remove()
//   // document.getElementsByClassName("delH")[0].remove()

// }






function closeWidget(e) {

// close if user is writing subtitles
// try{
//   if(document.getElementById("subdiv").contains(e.target)) {
    
//     document.getElementById('xcolumn').style.display='none'
    
//   }
// } catch {}

    var check = e.target.classList.contains("caption-break") || e.target.parentNode.classList.contains("caption-break") || e.target.parentNode.parentNode.classList.contains("caption-break")

   if (!check) {
      try {
     document.getElementById("rows").style.display = "none";
  } catch {}
     }

try{
var c = e.target.classList.contains("heads-text-container") || e.target.classList.contains("head-text-container")|| e.target.classList.contains("subtitles-text-container") || e.target.classList.contains("subtitle-text-container") || e.target.classList.contains("custom-seekbar1") || e.target.classList.contains("custom-seekbar11") || e.target.classList.contains("vx") || e.target.id == "vid1" || e.target.classList.contains("xheadx") || e.target.classList.contains("head") || document.getElementById("xcolumn").contains(e.target)
} catch {}

if ( !c ) {
      try {
        document.getElementsByClassName("move")[0].classList.remove("move");
      } catch {}

      }

try {
  if (!document.getElementById("xx").contains(e.target)) {
  
    document.getElementById("rx").style.display="block"
    document.getElementById("xx").style.display="none"
    document.getElementsByClassName("vid-fixed-top")[0].style.opacity = 1

    console.log(e.target)
  }
} catch {}
// id is either parent circle or button
	// if (e.target.classList.contains("bgColor")) {
	// // document.getElementById("ycolumn").style.display = "block";
	// return;
	// } 

  if (document.getElementById("ycolumn")) {
// if the target is a child of 'color widget'
	if (!document.getElementById("ycolumn").contains(e.target)) {
	   // document.getElementById("ycolumn").style.display = "block";
	  document.getElementById("ycolumn").style.display = "none";
	}



// Zoom slider
  // if (e.target.classList.contains("zoomd") || e.target.id == "zoomd") {
  //   // document.getElementById("overlayzoom").style.display = "block"
  //   return;
  // }
  
  // if (document.getElementById("overlayzoom")) {
  //     if (document.getElementById("overlayzoom").contains(e.target)) {
  //   // document.getElementById("overlayzoom").style.display = "block";
  //   return;
  // } 
  // }
  // document.getElementById("overlayzoom").style.display = "none"

  console.log('e.target', e.target)

  // fonts - head and sub
  var ft = document.getElementsByClassName("SubtitleTools_subtitleDropDownList__2Q4CU");
  // console.log('ft', ft[0], ft[1], e.target)
  var i = 0
  // if (ft[0].contains(e.target.id) == "fontin") {
  //   // ft[i].style.display = "block"
  //   return;
  // }

  console.log(ft[0].contains(e.target))
  

  var p =  document.getElementsByClassName("SubtitleTools_subtitleDropDownDisplay__2M4Ue")
  
  if(p[0].contains(e.target) || ft[0].contains(e.target)) {
    ft[0].style.display = 'block'
    return;
  }

  if(p[1].contains(e.target) || ft[1].contains(e.target)) {
    ft[1].style.display = 'block'
    return;
  }


//   if (ft[0].style.display != "none") {
//       if (ft[0].contains(e.target)) {
//         // ft[i].style.display = "block";
//         return;
//   } else {

//     ft[0].style.display = "none"
//     return;
//   }
// } 

//   if (ft[1].style.display != "none") {
//       if (ft[1].contains(e.target)) {
//         // ft[i].style.display = "block";
//         return;
//   } else {
//     ft[1].style.display = "none"
//     return;
//   }
// }

ft[0].style.display = "none"
ft[1].style.display = "none"
  
  
  
}

}



function zoomD(e) {
  console.log('zoom', e.target)
  document.getElementById("overlayzoom").style.display = "block"
}

function setbgColor(e, setTarget) {
  if (e.target.classList.contains("bgColor") || e.target.parentNode.classList.contains("bgColor")) {
  document.getElementById("ycolumn").style.display = "block";

  var tar = e.currentTarget;

  console.log('%',tar, tar.tagName == 'BUTTON', tar.children[0])
  if (tar.tagName == 'BUTTON') {
      tar = tar.children[0];
      console.log(tar);

  }

  console.log('setTarget', tar);

  setTarget(tar);

  var offset = tar.getBoundingClientRect();
  //var left = (offset.left + offset.width/2);
  var top = (offset.top + offset.height); 
  // var top = offset.top;  
  var left = offset.left;

  // e.currentTarget.style.color = document.getElementsByClassName('vc-sketch-active-color')[0].style.background.split(" none")[0];

  console.log('widget', tar, top, left);

  // document.getElementById("ycolumn").style.marginTop = top +'px';
  // document.getElementById("ycolumn").style.marginLeft = 0 + 'px';
}
}


const showSub = function (e) {

  // document.getElementsByClassName("clickedV")[0].setAttribute("class", "btn btn-default")
  // document.getElementById("generateSub").setAttribute("class", "clickedV btn btn-default")

  document.getElementById("textstyle").style.display = 'none';
    // document.getElementById("progressBar").style.display = 'none'

    document.getElementById("showVideo").style.display = 'none'
    document.getElementById("substyle").style.display = 'none'

        document.getElementById("dcolumn").style.display = 'block';
        document.getElementById("showSub").style.display = 'block';
        document.getElementById("askSub").style.display = 'none';
    

}


function calcSub() {

    const meta = JSON.parse(localStorage.getItem('meta'));

    console.log('meta', meta);
    // // console.log(props.match.params, props.location.state);
    // // console.log('dur',props.location.state.vid.duration);
    var strt=[];
    var en=[];
    var sub=[];
    for(var i=0;i<meta.length;i++){
      strt.push(meta[i].start_time);
      en.push(meta[i].end_time);
      sub.push(meta[i].words);
    }

    // var se = parseFloat(props.location.state.vid.duration).toFixed(1).split('.');
    // var se = parseFloat(localStorage.getItem("duration")).toFixed(1).split('.');
    var viddur = miliToHrCommaTime(parseFloat(localStorage.getItem("duration"))*1000)


    // var se = ['33', '9']

// change end_time to duration for last element
    en[i-1] = viddur;
    strt[i] = viddur;
    en[i] = viddur;
    sub[i] = " ";

    localStorage.setItem('langx', JSON.stringify(sub))
    localStorage.setItem('subx', JSON.stringify(sub));
    localStorage.setItem('en', JSON.stringify(en));
    localStorage.setItem('strt', JSON.stringify(strt));

    console.log('DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD');


}


function displaySub(e, res, setS, props, lang) {


          console.log("fetched transcript", res)
          // console.log('body', body)

          var meta = res.data
          localStorage.setItem('meta', JSON.stringify(meta))
          
          localStorage.removeItem("subx");
          localStorage.removeItem("langx");
          try {
            localStorage.removeItem("transx");
          } catch(e) {
            console.log(e)
          }
          localStorage.removeItem("en");
          localStorage.removeItem("strt");

          // calcSub();
          // setSubBegin();
          if( lang == 'ar-AE' || lang == 'ar-SA') arab_splitSub(lang) 
          else splitSub(lang);
          // console.log('sss',s)
          setS(true);

          showSub(e);

          localStorage.setItem("showSub", "1")

// /////////////////////////////////////////


    var subs = document.getElementsByClassName("caption")

    // document.getElementsByClassName("caption")[0].innerHTML
  // var hh = this.TwrapTextDummy(ctx, subs[0].innerText , 0, 0, 200 - 2*marginB, this.props.boxes2[0].fs + 0.4*marginB, this.props.boxes2[0].fs, this.props.boxes2[0].f)


    // props.update_x(0, 'h', 2*marginB + hh )
    props.update_x(0, 'text', JSON.parse(localStorage.getItem('subx'))[0])
    props.update_x(0, 'html', JSON.parse(localStorage.getItem('subx'))[0].replace(/\n/g,'<br>').replace(/&nbsp/g,'').replace(/&nbsp;/g,''))

    
    props.update_x(0, 'fill', 'rgba(100,100,100,0.5)')

    props.update_x(0, 'a', 0.5);

    localStorage.setItem("showSub", "1")

}


function generateSub(e, s, setS, props) {

  document.getElementById("Mpbar2").style.display = "none";

if(!localStorage.getItem("filename")) {


  return;
}

if(document.getElementById("askSub").getElementsByClassName("Mpbar")[0].style.display == "block") {


  return;

}

  // document.getElementById("askSub").setAttribute("x", "1")
  localStorage.setItem("showSub", "0")

  document.getElementById("askSub").getElementsByClassName("Mpbar")[0].style.display = "block"
  document.getElementById("askSub").getElementsByClassName("transcribe_")[0].style.display = "block"
  document.getElementsByClassName("waitSub")[0].style.opacity = 0.5;


  var filexame =  localStorage.getItem('filename');
  var lang = localStorage.getItem('lang')
  var json_name = filexame.split('.')[0] + '.json' 
  var audio = filexame.split('.')[0] + '.mp3'
  // var end = filexame.split('.')[1]
  // + '.mp3'


  var url = 'https://9px0z1hbrk.execute-api.us-west-2.amazonaws.com/default/create_audio_file?file=' + filexame + '&lang=' + lang + '&audio=' + audio



  // var xurl = 'https://807yynhrd3.execute-api.us-west-2.amazonaws.com/default/transcription_from_s3?key=88aee94b-677e-4c45-b2a8-87c76a916e04481597d3-73cf-4b15-8f3b-b744e3d87c13.json'

  // axios.get(xurl).then(res => console.log('fetched json', res))

  // console.log('xurl', xurl)

// url = 'http://localhost:5000/getsub'
// var data = {}
// data['filename'] = localStorage.getItem('filename');


// json_name = 'xxxauto_audio_1606101958359_small.json'

// //////////////////////////////////////////////////////////////////////////

// generate the audio file
axios.get(url).then(res => {
  console.log('Audio file created :)', res)

// start transcription job
    url = 'https://mzn16hec30.execute-api.us-west-2.amazonaws.com/default/create_transcript_job?audio=' + audio + '&lang=' + lang 

    // axios.get(url).then(res => {
    //   console.log('transcript started', res.data)

         // generate the transcript json
    // url = 'https://807yynhrd3.execute-api.us-west-2.amazonaws.com/default/transcribe-audio-file?audio=' + audio + '&lang=' + lang 
    // res.data

      axios.get(url).then(res => {
        console.log('started creating json', res)

        json_name = 'yjygj.json'
        json_name = 'small.json'

        var s = 'https://bc67h54zph.execute-api.us-west-2.amazonaws.com/default/transcription_from_s3?key=' + json_name + '&job=' + res.data
        
        // t is in seconds
        var t = parseFloat(localStorage.getItem('duration'))
        var tt = 60*1000
        if (t > 60) {tt = 60*1000}
        
        // call after half duration 
        t = 0.5*t*1000
        
// setTimeout
        const id = setTimeout(() => {

        console.log('s',s, 't',t)

        axios.get(s).then(res => {
        console.log('json fetched', res)

        displaySub(e, res, setS, props, lang)
        
      
      }).catch(err => {

        console.log(err)
        var dx = setInterval(() => {

          axios.get(s).then(res => {
        console.log('json fetched', res)
        clearInterval(dx)
        dx = 0;

        document.getElementById("askSub").getElementsByClassName("Mpbar")[0].style.display = "none"

        document.getElementById("askSub").getElementsByClassName("transcribe_")[0].style.display = "none"

        displaySub(e, res, setS, props, lang)


      })

        }, tt) //close setInterval
      })
    
    }, t); //close setTimeout

        // + '&cachebuster=' + Date.now()

    }).catch(err => {
      console.log('json', err) 

    document.getElementById("askSub").getElementsByClassName("Mpbar")[0].style.display = "none"
    document.getElementById("askSub").getElementsByClassName("transcribe_")[0].style.display = "none"
    document.getElementsByClassName("waitSub")[0].style.opacity = 1;

    })

  }).catch(err => {
    console.log('audio couldnt be generated', err)

    document.getElementById("askSub").getElementsByClassName("Mpbar")[0].style.display = "none"
    document.getElementById("askSub").getElementsByClassName("transcribe_")[0].style.display = "none"
    document.getElementsByClassName("waitSub")[0].style.opacity = 1;

    alert("uploaded video doesn't have audio")

})

///////////////////////////////////////////////////////////////////////////


}



function Boxstyle(props){
  const dispatch = props.dispatch
  const boxes2 = props.boxes2


// set the bkgrnd color as default
  const [target, setTarget] = useState(0); 
  const [d, setD] = useState("s"); 
  const [s, setS] = useState(false)

	useEffect(() => {

  // $("#ycolumn").draggable();

	document.body.addEventListener("click", (e) => closeWidget(e));

	},)


  useEffect(() => {

       var x = localStorage.getItem("showSub")
       document.getElementById("askSubt").setAttribute("class", "clickedV btn btn-default")
    // "0" => subtitles haven't been generated yet
    if ( x == "0") {
        document.getElementById("askSub").style.display = 'flex'
        document.getElementById("dcolumn").style.display = 'none';
        document.getElementById("showSub").style.display = 'none';
} else {
        document.getElementById("askSub").style.display = 'none';
        document.getElementById("dcolumn").style.display = 'block';
        document.getElementById("showSub").style.display = 'block';
}

},[])

// vc-saturation selects the color

return (
  <div style={{display: 'flex'}}>
	<div id="xcolumn">

  <Colorsbox d={d} target={target} props={props} />

	<div className="sidenav">

    <div className="flexy">

    <div className="flexy">

    
    <div className="coly" id="showVideo" style={{ display: 'none'}}>
        <div className='aspec'>

          <div className="dropdown aspect">
        			<button type="button" className="btn btn-default dropbtn" data-toggle="tab">Size
                <br/> 
                <span id='ar'>9:16</span>
                <ExpandMoreIcon/>
              </button>
        			

              <div id='aratio' className="dropdown-content">
          			<a id='1:1'>1:1 Square</a>
          			<a id='16:9'>16:9 LandScape</a>
          			<a id='9:16'>9:16 Vertical</a>
          			<a id='4:5'>4:5 Portrait</a>
          			<a id='org'>Org Size</a>	
        			</div>
      	 	</div>

          <button onClick={(e) => setbgColor(e, setTarget)} type="button" className="v btn btn-default bgColor" data-toggle="tab"><FiberManualRecordIcon id="v" onClick={(e) => setbgColor(e, setTarget)} className="v bgColor" style={{ fill: 'rgb(0,0,0)', 'fill-opacity':"1", display:'block', margin:'auto' }}/>video bckgrnd</button>

        </div>

      <span id="pbar">Progress Bar</span>
      <div className="flexy">
        <button onClick={(e) => setbgColor(e, setTarget)} type="button" className="bp btn btn-default bgColor" data-toggle="tab"><FiberManualRecordIcon id="bp" onClick={(e) => setbgColor(e, setTarget)} className="bp bgColor" style={{ fill: 'rgb(155,123,0)', 'fill-opacity':"1", display:'block', margin:'auto' }}/> back </button>
        <button onClick={(e) => setbgColor(e, setTarget)} type="button" className="fp btn btn-default bgColor" data-toggle="tab"><FiberManualRecordIcon id="fp" onClick={(e) => setbgColor(e, setTarget)} className="fp bgColor" style={{ fill: 'rgb(14,125,205)', 'fill-opacity':"1", display:'block', margin:'auto' }}/>front</button>
      </div>  

    </div>
    



    <div id="textstyle" style={{display: 'none'}}>
        <Textstyle d="h" setTarget={setTarget} setD={setD} props={props} />
        <div id="textstylx">

        </div>
        <div id="addT">  
          <AddOutlinedIcon />
        </div>

    </div>

    <div id="substyle" style={{display: 'none'}}>
        <Textstylesub id="substyle" d="s" setTarget={setTarget} setD={setD} style={{display: 'none'}} props={props}/>
    </div>

    <CircularProgress id="Mpbar2" />

    <div x="0" id="askSub" style={{display: 'none'}} >
      <button id="gensub" onClick={(e) => generateSub(e, s, setS, props)} type="button" className="waitSub btn btn-default" data-toggle="tab">Auto Generate Subtitles</button>
        
      <span id="askSubText" style={{display:'none'}} className="waitTSub">Please wait for video to upload 100% </span>
      <div className="transcribe_">
        <div>Generating subtitles. Please wait. <br/>Might take a few minutes, depending upon the length of the video</div>
        <CircularProgress className="Mpbar" />
      </div>

    </div>


    <div id="showSub" style={{display: 'none'}}>
      <Tabbox s={s} setS={setS} style={{position: 'absolute'}} />
    </div>

    </div>

    </div>




	   </div>

  </div>

	</div>
)
}

// Map Redux state to React component props
const mapStateToProps = (state) => {
  console.log('!!!mapStateToProps', state)
  return {
  boxes2: state.data.boxes2,
  canvasValid: state.data.canvasValid,
  selectText: state.data.selectText,

  xout: state.data.xout,
  width: state.data.width,
  height: state.data.height,
  pc: state.data.pc,
  back_bar: state.data.back_bar, 
  front_bar: state.data.front_bar,
  bar_height: state.data.bar_height,
  duration: state.data.duration,
  dvid: state.data.dvid,
  pre_sub: state.data.pre_sub,
  ti: state.data.ti,

  render: state.data.render,
  subscribe: state.data.subscribe,
  uid: state.data.uid,
  email: state.data.email,
  pic: state.data.pic,
  filename: state.data.filename,
  
}}


// Connect Redux to React
Boxstyle.displayName = "Boxstyle";
export default connect(mapStateToProps, { increment, update_x, edit_boxes, pop_boxes, invalidate, selectedText, render_x, decrement, reset })(React.memo(Boxstyle));


import {tickerTime, miliToTime, miliToMinTime, strToMili} from './utils';

export const increaseT = function(e) {
			console.log("in increaseT")
				// if id=0
			if (e.currentTarget.classList.contains('MuiSvgIcon-colorDisabled')) {
				return;
			}

			var pa = e.currentTarget.parentNode; //div
		 	var nt = e.currentTarget.nextSibling;

		 	var id = pa.id;
			var t = nt.textContent;

			var rowT = document.getElementById("rows")
			var getId = rowT.getAttribute("tickerId");

			var timNew = tickerTime(rowT.textContent);
			if (id == "zero") {timNew = timNew + 60*1000;}  
			if (id == "one") {timNew = timNew + 1000;}  
			if (id == "two") {timNew = timNew + 100;}

		 	var x = miliToMinTime(timNew);

		 	try{
		 	
		 	document.getElementById(getId).setAttribute("stime",x);
		 	// document.getElementById("bt"+getId).innerHTML = x;
		 	// document.getElementById("bt"+getId).innerHTML = `<li class='fa'>&#xf017</li>`

		 	document.getElementById(getId).previousSibling.previousSibling.setAttribute("etime", x);

		 	console.log('changing time', document.getElementById(getId))
		 	
		 	console.log('changing time', document.getElementById(getId).getAttribute("stime"), document.getElementById(getId).previousSibling.previousSibling.getAttribute("etime"))
		 
		 } catch {
		 	console.log('tickerId is null');
		 }


		 	var a = x.split(':')[0].padStart(2,0);
		 	var b = x.split(':')[1].split('.')[0].padStart(2,0);
		 	var c = x.split(':')[1].split('.')[1].padStart(1,0);

		 	document.getElementById("zero").childNodes[1].innerHTML = a;
		 	document.getElementById("one").childNodes[1].innerHTML = b;
		 	document.getElementById("two").childNodes[1].innerHTML = c;



}

export const decreaseT = function(e) {

					// if id=0
			if (e.currentTarget.classList.contains('MuiSvgIcon-colorDisabled')) {
				return;
			}

			var pa = e.currentTarget.parentNode; //div
		 	var id = pa.id;
			
			var nt = e.currentTarget.previousSibling;
			var t = nt.textContent;
			

			var rowT = document.getElementById("rows");
			var getId = rowT.getAttribute("tickerId");

			var timNew = tickerTime(rowT.textContent);
			if (id == "zero") {timNew = timNew - 60*1000;}  
			if (id == "one") {timNew = timNew - 1000;}  
			if (id == "two") {timNew = timNew - 100;}

		 	var x = miliToMinTime(timNew);

		 	try {
		 	document.getElementById(getId).setAttribute("stime",x);
		 	// document.getElementById("bt"+getId).innerHTML = x;

		 	document.getElementById(getId).previousSibling.previousSibling.setAttribute("etime", x);

		 	console.log('changing time', document.getElementById(getId).getAttribute("stime"), document.getElementById(getId).previousSibling.previousSibling.getAttribute("etime"))

 			} catch {
		 	console.log('tickerId is null');
		 }

		 	console.log('x',x);

		 	var a = x.split(':')[0].padStart(2,0);
		 	var b = x.split(':')[1].split('.')[0].padStart(2,0);
		 	var c = x.split(':')[1].split('.')[1].padStart(1,0);

		 	document.getElementById("zero").childNodes[1].innerHTML = a;
		 	document.getElementById("one").childNodes[1].innerHTML = b;
		 	document.getElementById("two").childNodes[1].innerHTML = c;
	
}
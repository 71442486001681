import React, { useEffect } from 'react';

import './lang.css';

import {flask_multipart} from './flask-s3-multipart';
import './flask_multipart.css';


function displaylang(e, props) {


    document.getElementById("langselect").style.display = "block"
    document.getElementById("Mpbar2").style.display = "block";
    
}


function selectlang(e, props) {
	
      e.preventDefault();
      e.stopPropagation();


  document.getElementsByClassName("input-lang")[0].value = e.target.textContent

  var lang = e.target.textContent.split('(')[1].split(')')[0]

  localStorage.setItem('lang', lang)
  
  document.getElementById('an1').innerText = localStorage.getItem('lang').split('-')[0]

  document.getElementById("langselect").style.display = "none"

  document.getElementById("lang").style.display = "none"

// generate sub if video upload = 100% and lang selected as well
// + subtitles shouldnt already be generated
  if(localStorage.getItem('progress') == 1) {
    document.getElementsByClassName('waitSub')[0].click() 
  }

  // setWidth(i+1)

}

function Lang(props){	


    useEffect(() => {

    if(!localStorage.getItem('lang')) {

        // document.getElementById("langselect").style.display = "block"
        document.getElementById("lang").style.display = "block"

    }




},[])


		// File is still uploading. File will not be saved if you leave the tab
		// <div className="select_alert">
		// <button>OK</button>
		// <button>Cancel</button>
		// </div> 

// onClick={(e) => selectfont(e, props)}

// <button type="button" data-toggle="tab" className="btn-default">Indian Hindi (hi-IN)</button>


return (
	<div id="lang">
	<span className="langalert">Please choose the language spoken in the video
  <div onClick={(e) => displaylang(e, props)} className="langinput">

  <input id="react-select-6-input" tabindex="0" readonly="" aria-autocomplete="list" class="input-lang" value="" disabled=""/>

  <div className="css-1wy0on6">
  <div aria-hidden="true" class=" css-5e2tja-indicatorContainer"><svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-19bqh2r"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
  </div>
  </div>

  </div>

  </span>


<div id="langselect" onClick={(e) => selectlang(e, props)}>
<button type="button" data-toggle="tab" className="btn-default">Gulf Arabic (ar-AE)</button>

<button type="button" data-toggle="tab" className="btn-default">Modern Standard Arabic (ar-SA)</button>

<button type="button" data-toggle="tab" className="btn-default">Chinese Mandarin-Mainland (zh-CN)</button>

<button type="button" data-toggle="tab" className="btn-default">Dutch (nl-NL)</button>




<button type="button" data-toggle="tab" className="btn-default">Australian English (en-AU)</button> 

<button type="button" data-toggle="tab" className="btn-default">British English (en-GB)</button>

<button type="button" data-toggle="tab" className="btn-default">Indian English (en-IN)</button>

<button type="button" data-toggle="tab" className="btn-default">Irish English (en-IE)</button>

<button type="button" data-toggle="tab" className="btn-default">Scottish English (en-AB)</button>

<button type="button" data-toggle="tab" className="btn-default" autoFocus>US English (en-US)</button>

<button type="button" data-toggle="tab" className="btn-default">Welsh English (en-WL)</button>

<button type="button" data-toggle="tab" className="btn-default">Farsi (fa-IR)</button>

<button type="button" data-toggle="tab" className="btn-default">French (fr-FR)</button>

<button type="button" data-toggle="tab" className="btn-default">Canadian French (fr-CA)</button>

<button type="button" data-toggle="tab" className="btn-default">German (de-DE)</button>

<button type="button" data-toggle="tab" className="btn-default">Swiss German (de-CH)</button>

<button type="button" data-toggle="tab" className="btn-default">Hebrew (he-IL)</button>

<button type="button" data-toggle="tab" className="btn-default">Indian Hindi (hi-IN)</button>

<button type="button" data-toggle="tab" className="btn-default">Indonesian (id-ID)</button>

<button type="button" data-toggle="tab" className="btn-default">Italian (it-IT)</button>

<button type="button" data-toggle="tab" className="btn-default">Japanese (ja-JP)</button>

<button type="button" data-toggle="tab" className="btn-default">Korean (ko-KR)</button>

<button type="button" data-toggle="tab" className="btn-default">Malay (ms-MY)</button>

<button type="button" data-toggle="tab" className="btn-default">Portuguese (pt-PT)</button>

<button type="button" data-toggle="tab" className="btn-default">Brazilian Portuguese (pt-BR)</button>

<button type="button" data-toggle="tab" className="btn-default">Russian (ru-RU)</button>

<button type="button" data-toggle="tab" className="btn-default">Spanish (es-ES)</button>

<button type="button" data-toggle="tab" className="btn-default">US Spanish (es-US)</button>

<button type="button" data-toggle="tab" className="btn-default">Tamil (ta-IN)</button>

<button type="button" data-toggle="tab" className="btn-default">Telugu (te-IN)</button>

<button type="button" data-toggle="tab" className="btn-default">Turkish (tr-TR)</button>
</div>


	</div>

)}


Lang.displayName = "Lang";
export default React.memo(Lang);

import React, { useEffect } from 'react';
import {strToMili} from './utils';
import RefreshIcon from '@material-ui/icons/Refresh';



function Reset(props){

const resetPage = function() {

  var pa = document.getElementById("subdiv") ;
  pa.innerHTML = "";

for (var i=0; i<props.subtext.length ; i++){


      // var s_pa = document.getElementById("editor");

      // var pa = document.createElement("div");
      // pa.setAttribute("id", "subdiv");
      // pa.setAttribute("contentEditable", "true");
      // pa.setAttribute("class", "ProseMirror ProseMirror-example-setup-style");

      var cdiv = document.createElement("div");
      cdiv.setAttribute("caption-break", "");  


      cdiv.className = "caption-break";
      cdiv.contentEditable = "true";
      cdiv.draggable = "true";
	  
      var ticker = document.createElement("span");

      //to not write random text in time button
      ticker.setAttribute("contentEditable", "false");
      
      // console.log('bttn id', ticker.id);
      ticker.style.position = 'relative';
      // console.log('input id', inputtick.id);

      cdiv.appendChild(ticker);

      // var ticktick = `<TickerTime time={props.state.strt[0]}/>`;
      // cdiv.insertAdjacentHTML('afterbegin', ticktick);

     //  ticker.addEventListener("click", (e) => {
     //  	e.preventDefault();
    	// e.stopPropagation();
     //  	showTicker(e, setTickid, setPosX, setPosY, setDisplay)
     //  });

      pa.appendChild(cdiv);

      var cspan = document.createElement("span");
      cspan.setAttribute("stime", props.stime[i]);
      cspan.setAttribute("etime", props.etime[i]);

      cspan.setAttribute("className", "caption current-caption");
      cspan.setAttribute("class", "caption current-caption");

      // var att = document.createAttribute("contentEditable");      
      // att.value = "true";                         
      cspan.setAttribute("contentEditable", "true");

    
    pa.appendChild(cspan);

    if (i!=0) {
    // console.log('i',i);
    cspan.setAttribute("className", "caption");
    cspan.setAttribute("class", "caption");
    }
   // cspan.removeClass("current-caption");

   ticker.style.backgroundColor = 'black';
   ticker.style.color = 'white';
   ticker.style.borderColor = "pink";
   ticker.style.padding = "4px";

   // ticker.setAttribute("width", "30px");
   if ( i == 0 ) {
   		ticker.innerHTML = " Begin ";	
   		ticker.style.backgroundColor = 'gold';
   		ticker.style.color = 'black';

   } else if ( strToMili(props.stime[i])/1000 == parseFloat(props.duration).toFixed(1) ){
   		ticker.innerHTML = " End ";	
   		ticker.style.backgroundColor = 'gold';
   		ticker.style.color = 'black';
} else {
	ticker.innerHTML = props.stime[i];
}
   // cspan.setAttribute("lineHeight","10em");

  
  ticker.setAttribute("id", "bt"+i.toString());
  cspan.setAttribute("id", i);

  cspan.innerText = props.subtext[i];
}

}


let styles = {
  margin:'auto',
  display:'block',
  border: "pink",
};



return (
<div>


<button id="reset" type="button" className="btn btn-default" data-toggle="tab" onClick={resetPage}>
<RefreshIcon id="refresh" style={styles} />Reset</button> 

</div>
		)
}


Reset.displayName = "Reset";
export default React.memo(Reset);


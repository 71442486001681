import React, { useState,useEffect } from 'react';

/* global $ */
/* global moment */

import './xoxo.css';
// import './playButton.css';
import './bttn2.scss';
import './zoomslider.css';
// import {keytimer, moveElement, setTickerState, getAbcString, gettime, tickerTime, strToMili, miliToTime, getLowestIndex} from './utils';


import {moveElement, setTickerState, getAbcString, gettime, tickerTime, strToMili, miliToTime, getLowestIndex} from './utils';

import TickerTime from './div_ticker';
import Reset from './reset';
import Save from './saveState';

import Vidstyle from './vidstyle';
// import {arrangeSub} from './arrangesub';
import {setSubTime} from './arrangesub';


import AddCircleIcon from '@material-ui/icons/AddCircle';




// const addticker = function(e, state, changeTime, changeBothTime, changeSub, changeCaptions) {
const addticker = function(e, props) { 
  console.log('in addticker');

  e.preventDefault();
  e.stopPropagation();

  // https://stackoverflow.com/questions/4456545/how-to-insert-text-at-the-current-caret-position-in-a-textarea

  // need to get the exact location where it should be added
  // + update time + update id + this.state 

  // https://stackoverflow.com/questions/2920150/insert-text-at-cursor-in-a-content-editable-div
    
  if (document.getElementsByClassName("current-caption") && document.getElementsByClassName("current-caption")[0]) {

// document.getElementsByClassName("caption")[idn] can't use this, span idn=8 before spna idn=6
// byclassnames will be a list in order flow 0-1-2-3-4-5-8-7-6

  // can have element with EXACTLY same text value! - whihc one to highlight then!?
  // var idn = window.getSelection().getRangeAt(0).startContainer.parentNode.id;
  const selectedSub = document.getElementsByClassName("current-caption")[0]
  const idn = selectedSub.id;
  console.log("span idn ", idn);


    /* var cursorPos = $('#text').prop('selectionStart') */
    // var cursorPos = window.getSelection().getRangeAt(0).startOffset;
    var cursorPos = window.getSelection().getRangeAt(0).startOffset;
    console.log('old curpos:', cursorPos)
    console.log(window.getSelection().getRangeAt(0).startContainer.parentNode)
    // var v = document.getElementById(idn).innerText;
    const v = selectedSub.innerText;
    console.log('value',v);
    console.log('char',v.charAt(cursorPos), 'cursor:',cursorPos,'v', v);

    var pa_stime = selectedSub.getAttribute("stime");
    var pa_etime = selectedSub.getAttribute("etime");
    // console.log(v.charAt(cursorPos) == ' ')

    if (v.charAt(cursorPos) != ' ' || v.charAt(cursorPos-1) != ' ') {

      var indices = [];
      indices.push(0); indices.push(v.length);
      for(var i=0; i<v.length;i++) {
      		// console.log('NO NO NO')
          if (v[i] === " ") indices.push(i);
      }

      var indices2 = indices.map(function(value) { 
          return Math.abs(value - cursorPos); 
        });

      console.log('position of space:',indices);
      var p = getLowestIndex(indices2);
      console.log(p)
      cursorPos = indices[p];
      

      }

    console.log('new curpos', cursorPos );
    // console.log('!!add ticker cursorPos', cursorPos, 'v',v.length);
    var tim = gettime(idn, cursorPos);
    // console.log('in add ticker get time:',t);
    var t = miliToTime(tim);
    //console.log('in add ticker get time:',t);

    // console.log('selected sub-', 'sub value before-',selectedSub.value);
    var textBefore = v.substring(0,  cursorPos );
    var textAfter  = v.substring( cursorPos, v.length );
    
    selectedSub.innerText =  textBefore;
    console.log(selectedSub.innerText, 'tB',textBefore);
    // console.log('selected sub', 'cur',cursorPos,'textBefore', textBefore, 'selected sub value:', selectedSub.value);
    // props.changeCaptions(textBefore);

    // console.log("child", document.getElementById("subdiv").children );
    // console.log(' strt length:', strt);

    var i = document.getElementsByClassName("caption").length;
    var pa = selectedSub;

    var cdiv = document.createElement("div");
    cdiv.setAttribute("caption-break", "");  

    // strt[i] = t;
    // console.log('strt[i]', t);

    // cdiv.setAttribute("time", t);
 
    cdiv.className = "caption-break";
    cdiv.contentEditable = "true";
    cdiv.draggable = "true";

    // var tickdiv = document.createElement("div");
    // tickdiv.setAttribute("className", 'input-group date datepicker-me-class');
    // tickdiv.setAttribute("class", 'input-group date datepicker-me-class');

    // var ticker = document.createElement("button");
    var ticker = document.createElement("span");

    //do not write random text in time button
    ticker.setAttribute("contentEditable", "false");
    ticker.setAttribute("id", "bt"+i.toString());
    // console.log('bttn id', ticker.id);
    ticker.style.position = 'relative';

    // var spantick = document.createElement("SPAN");
    // spantick.setAttribute("className","glyphicon-time glyphicon");
    // spantick.setAttribute("class","glyphicon-time glyphicon");
    // // spantick.setAttribure("id", "ticker"+i.toString())

    // var inputtick = document.createElement("input");
    // inputtick.setAttribute("type", "hidden");
    // inputtick.setAttribute("id", "datetimepicker"+i.toString());
    
    // cdiv.appendChild(tickdiv);
    cdiv.appendChild(ticker);
    // ticker.appendChild(spantick);
    // tickdiv.appendChild(inputtick);

    // pa.appendChild(cdiv);
    pa.insertAdjacentElement("afterend", cdiv);

    var cspan = document.createElement("span");
    cspan.setAttribute("stime", t);
    cspan.setAttribute("etime", pa_etime);

    selectedSub.setAttribute("etime", t);

    cspan.setAttribute("className", "caption current-caption");
    cspan.setAttribute("class", "caption current-caption");
        
    cspan.setAttribute("contentEditable", "true");

    cspan.innerText = textAfter; //sub[i];
    cspan.setAttribute("id", i);

    console.log('new ticker text', cspan.innerText);



    // pa.appendChild(cspan);
    cdiv.insertAdjacentElement("afterend", cspan);

    // cspan.className = "caption";
    cspan.setAttribute("className", "caption");
    cspan.setAttribute("class", "caption");

    // ticker.style.backgroundColor = 'black';
   	ticker.style.color = 'blue';
   	ticker.style.borderColor = "pink";
   	ticker.style.padding = "2px";
   	ticker.setAttribute("width", "30px");
   	// inputtick.setAttribute("type", "hidden");
   	cspan.setAttribute("lineHeight","10em");
    ticker.innerHTML = t;

    const newtime = JSON.parse(localStorage.getItem('strt')).slice()
    newtime[i] = t;

    const newsub = JSON.parse(localStorage.getItem('sub')).slice() //copy the array
    newsub[idn] = textBefore;

    newsub[i] = textAfter;

    const endnew = JSON.parse(localStorage.getItem('en')).slice();
    endnew[idn] = t; //parent ticker

    // console.log('!new ticker text', state.txt);
    // var endi = document.getElementById(i).nextSibling.nextSibling.getAttribute("id");
    // endnew[i] = endnew[endi]; 
    endnew[i] = JSON.parse(localStorage.getItem('en'))[idn];  //new ticker
 	// en[idn] = strt[i];
 	// en[i] = en[idn] ; //endtime for new ticker is endtime of parent ticker 
  console.log("change both time - ",'new',t);
 	console.log("change both time - ",'new',i, newsub[i], newtime[i], endnew[i]);
  console.log("change both time - ",'parent',idn, newsub[idn], newtime[idn], endnew[idn]);
  // props.changeBothTime(newsub, newtime, endnew) ; 

    document.getElementById("subtitle-text-container").innerText = document.getElementsByClassName('current-caption')[0].innerText;

    // setState() 
  //  console.log('end of addticker state', props.state); 
    var vi = document.getElementById("subtitles-text-container");
    vi.style.visibility="visible";  
}
}

const showTicker = function(e, t, x, y) {
	console.log("showticker"); //e.target: span id="bt6"

	if (e.target.parentNode.classList.contains("caption-break")) {

      var tickid = e.target.parentNode.nextSibling.id;

      var f = document.getElementById(tickid).previousSibling;
      var offset = f.getBoundingClientRect();  
      
      var gPa = document.getElementById("subdiv").children
	  if (tickid == gPa[1].id || tickid == gPa[gPa.length-1].id) {
		return;
	  }

	  document.getElementById("rows").style.display = "block";

	  setTickerState(tickid); 


      t(tickid);
      // x(e.clientX);
      // y(e.clientY);
      x(offset.left + offset.width/2);
      y(offset.top + offset.height);      
     } 
}


function Tab1(props){

	const [tickid, setTickid] = useState("0"); //cspan
	const [posX, setPosX] = useState(0);
	const [posY, setPosY] = useState(0);
	const [display, setDisplay] = useState("none");
	
 // const [txy, setTxy] = useState([]);
	
  const txy = localStorage.getItem("Yes");


	useEffect(() => {

	document.getElementById("addSubz").addEventListener("click", (e) => {
		e.preventDefault();
    e.stopPropagation();
		// console.log('addticker before state', props.state);
		addticker(e, props)
		});

    var key = document.getElementById("subdiv");
  //   // key.addEventListener("DOMSubtreeModified", (e) => keytimer(e));
	 //  key.addEventListener("keydown", (e) => {
  //   keytimer(e)

  //   // set width of sub container
  //   // console.log('set width in tab1')
  //   // setWidth(2);
  
  // }); //slow
	// key.addEventListener("mouseup", (e) => keytimer(e)); //doin nthng
	// key.addEventListener("mousedown", (e) => keytimer(e));
	key.addEventListener("click", (e) => {
    // keytimer(e)
    // setWidth(2);

    // console.log('clinet', e.clientY, e.pageY)
    
    try {
    // document.getElementById("addSubz").removenext(document.getElementById("plus"))
    document.getElementById("plus").remove();
  } catch {
    console.log("no child");
  }


  function getpos(e) {
  var posx = 0;
  var posy = 0;
  if (!e) var e = window.event;
  if (e.pageX || e.pageY)   {
    posx = e.pageX;
    posy = e.pageY;
  }
  else if (e.clientX || e.clientY)  {
    posx = e.clientX + document.body.scrollLeft
      + document.documentElement.scrollLeft;
    posy = e.clientY + document.body.scrollTop
      + document.documentElement.scrollTop;
  }
  return [posx, posy]
}
    document.getElementById("addSubz").style.marginTop = getpos(e)[1] - 190 + 'px';
    // document.getElementById("addSubz").style.marginRight = -200 + 'px';


    var xp = document.getElementById("addSubz").getBoundingClientRect();

      var cy = getpos(e)[1]  ;
      var color = 'rgba(255,0,255,0.3)';
      var thickness = 1;
      var cx = e.clientX;

      
      var xx = xp.left + xp.width/3
      var length = xx - cx  
      var htmlLine = "<div id=plus style='padding:0px; margin:0px; height:" + thickness + "px; background-color:" + color + "; line-height:1px;left:" + cx + "px; position: fixed; top:" + cy + "px; width:" + length + "px' />";
    //
    // alert(htmlLine);
    // document.getElementById("addSubz").innerHTML += htmlLine;
    document.getElementById("addSubz").insertAdjacentHTML('afterend', htmlLine);

  });
	// key.addEventListener("keypress", (e) => keytimer(e));
	//key.addEventListener("keyup", (e) => keytimer(e));

	// key.removeEventListener('keydown', handleKeyDown, true);
 //  key.removeEventListener('mousedown', handlePointerDown, true);
 //  doc.removeEventListener('pointerdown', handlePointerDown, true);
 //  doc.removeEventListener('touchstart', handlePointerDown, true);
 //  doc.removeEventListener('visibilitychange', handleVisibilityChange, true);

document.getElementById("head").addEventListener("keyup", function(e) {
  let h = `<div class="ui-resizable-handle ui-resizable-e" style="z-index: 90;"></div><div class="ui-resizable-handle ui-resizable-s" style="z-index: 90;"></div><div class="ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se" style="z-index: 90;"></div>`

  document.getElementById("head-text-container").textContent = e.currentTarget.innerText ; 
  

 
  // setWidth(1);
})

	document.getElementById("subdiv").addEventListener("click", (e) => {
		// document.getElementById("rows").style.display = "block";
     //  	e.preventDefault();
    	// e.stopPropagation();
      	showTicker(e, setTickid, setPosX, setPosY);
      });

	document.body.addEventListener("click", (e) => {

		if (!e.target.parentNode.classList.contains("caption-break")) {
      try {
		document.getElementById("rows").style.display = "none";
  } catch {

  }
			}
	})

	},[JSON.parse(localStorage.getItem('strt')), JSON.parse(localStorage.getItem('en')), JSON.parse(localStorage.getItem('sub'))])

useEffect(() => {  //never update this part

// set heading 

document.getElementById("head").innerText = localStorage.getItem('head') ;
// setWidth(1)
console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA")
console.log('BBBBBBBBBBBB', JSON.parse(localStorage.getItem('sub')))

// console.log('tab1 state', props.state.sub.length)
for (var i=0; i<JSON.parse(localStorage.getItem('sub')).length ; i++){

      var pa = document.getElementById("subdiv") ;

      var cdiv = document.createElement("div");
      cdiv.setAttribute("caption-break", "");  


      cdiv.className = "caption-break";
      cdiv.contentEditable = "true";
      cdiv.draggable = "true";
	  
      var ticker = document.createElement("span");

      //to not write random text in time button
      ticker.setAttribute("contentEditable", "false");
      
      // console.log('bttn id', ticker.id);
      ticker.style.position = 'relative';
      // console.log('input id', inputtick.id);

      cdiv.appendChild(ticker);

      // var ticktick = `<TickerTime time={props.state.strt[0]}/>`;
      // cdiv.insertAdjacentHTML('afterbegin', ticktick);

     //  ticker.addEventListener("click", (e) => {
     //  	e.preventDefault();
    	// e.stopPropagation();
     //  	showTicker(e, setTickid, setPosX, setPosY, setDisplay)
     //  });

      pa.appendChild(cdiv);

      var cspan = document.createElement("span");
      var t = JSON.parse(localStorage.getItem('strt'))[i]
      // console.log('tt',t,i)
      var v = (Math.round(parseFloat(t.split(':')[1])*10)/10).toFixed(1) 
      if(v.length==3) { t = t.split(':')[0]+':' + '0' + v}
      else { t = t.split(':')[0]+':' + v}

      cspan.setAttribute("stime", t);
      var t = JSON.parse(localStorage.getItem('en'))[i]
      // console.log('tt',t,i)
      var v = (Math.round(parseFloat(t.split(':')[1])*10)/10).toFixed(1) 
      if(v.length==3) { t = t.split(':')[0]+':' + '0' + v}
      else { t = t.split(':')[0]+':' + v}

      cspan.setAttribute("etime", t);

      cspan.setAttribute("className", "caption current-caption");
      cspan.setAttribute("class", "caption current-caption");

      // var att = document.createAttribute("contentEditable");      
      // att.value = "true";                         
      cspan.setAttribute("contentEditable", "true");

    
    pa.appendChild(cspan);

    if (i!=0) {
    // console.log('i',i);
    cspan.setAttribute("className", "caption");
    cspan.setAttribute("class", "caption");
    }
   // cspan.removeClass("current-caption");

   // ticker.style.backgroundColor = 'black';
   // ticker.style.color = 'white';
       // ticker.style.backgroundColor = 'black';
    ticker.style.color = 'blue';
   ticker.style.borderColor = "pink";
   ticker.style.padding = "4px";

   // ticker.setAttribute("width", "30px");
   if ( i == 0 ) {
   		ticker.innerHTML = " Begin ";	
   		// console.log('start', props.state.duration)
   		ticker.style.backgroundColor = 'gold';
   		ticker.style.color = 'black';

   } else if ( strToMili(JSON.parse(localStorage.getItem('strt'))[i])/1000 == parseFloat(localStorage.getItem("duration")).toFixed(1) ){
   		ticker.innerHTML = " End ";	
   		ticker.style.backgroundColor = 'gold';
   		ticker.style.color = 'black';
} else {


  var t = JSON.parse(localStorage.getItem('strt'))[i]
  // console.log('tt',t,i)
  var v = (Math.round(parseFloat(t.split(':')[1])*10)/10).toFixed(1) 
  if(v.length==3) { t = t.split(':')[0]+':' + '0' + v} 
  else { t = t.split(':')[0]+':' + v}
 // console.log('tx',t,i)
  
	ticker.innerHTML = t 
;
}
   // cspan.setAttribute("lineHeight","10em");


  // if ( i == sub.length) { i = 'xx'; }
  
  ticker.setAttribute("id", "bt"+i.toString());
  cspan.setAttribute("id", i);


  cspan.innerText = JSON.parse(localStorage.getItem('sub'))[i];
}

 // arrange subtitles at t=0
 
// var vidx = document.getElementById("vid1");
// vidx.onloadedmetadata = function() {

//   arrangeSub();

// };

function deleteNode(e)
{
  
  var cx = document.getElementById("subdiv").children;

  // console.log('cx', cx);
  for (var i=0; i<cx.length-1 ; i++){
    if (cx[i].classList.contains("caption") == cx[i+1].classList.contains("caption")) {
      cx[i].innerText = cx[i].innerText + cx[i+1].innerText;
      cx[i].setAttribute("etime", cx[i+1].getAttribute("etime"));
      cx[i+1].parentNode.removeChild(cx[i+1]);
    }
}
}
// Select the node that will be observed for mutations
const targetNode = document.getElementById('subdiv');

// Options for the observer (which mutations to observe)
// const config = { attributes: true, childList: true, subtree: true };
const config = { childList: true}
// Callback function to execute when mutations are observed
const callback = function(mutationsList, observer) {
    // Use traditional 'for loops' for IE 11
    for(let mutation of mutationsList) {
        if (mutation.type === 'childList') {
            deleteNode();
            console.log('A child node has been added or removed.');
        }
        // else if (mutation.type === 'attributes') {
        //     console.log('The ' + mutation.attributeName + ' attribute was modified.');
        // }
    }
};

// Create an observer instance linked to the callback function
const observer = new MutationObserver(callback);

// Start observing the target node for configured mutations
observer.observe(targetNode, config);

// Later, you can stop observing
// observer.disconnect();

// console.log('tickid', tickid, 'posX', posX, 'posY', posY)
// console.log('tick', document.getElementById(tickid))

  // <Vidstyle state={props.state} />

//   <Reset subtext={props.state.subtext} stime={props.state.stime} etime={props.state.etime} duration={props.state.duration} />

//           <Save changeBothTime={props.changeBothTime} state={props} />


},[props.s])
	return (
	<div id="dcolumn">

  

		<div className="jss586">
			<div className="zub-editor-dkflgl">
           
          <div id="xheadx">
          <div>Heading</div>
          <span id="head" contentEditable="true" autoFocus></span>
          </div>

       		 <TickerTime tickid={tickid} posX={posX} posY={posY} />

          <div id="headx">
          <div>Subtitles</div>
          </div>

		       <div id="editor" className="editor">

		     	 <div id="subdiv" contentEditable="true" className="ProseMirror ProseMirror-example-setup-style" />
           <button id="addSubz" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><AddCircleIcon style={{ fontSize:30 }}></AddCircleIcon></button> 

           </div>

            </div>
        </div>

        <div id="loading">
          <img id="loading-image" src="" alt="Loading..." />
        </div>

        
          <div id="save">
          <button type="button" onClick={setSubTime} autoFocus>arrange</button> 
          <Save />
          </div>
          
     </div>
		)
}


Tab1.displayName = "Tab1";
export default React.memo(Tab1);
/* global $ */

/*16:9 //1920:1080*/
/*9:16 //1080:1920 */
/* 1:1  //1080:1080*/
/* 4:5  //1080:1350*/
// original

// import {setSubTime} from './arrangesub';
import {addHead, hexToRgb} from './utils';

export const setVidDim = function(width, props) {

var c = document.getElementById("vid")
var g = (c.offsetWidth/c.offsetHeight).toFixed(1)
// var g = (props.state.width/props.state.height).toFixed(1)

// var vcx = document.getElementById("stage");
// var vidx = document.getElementById("vid1");

var r, nh, nw, bar_height, bar_height_new, head_pad
if ( g == "0.6") {
//  9:16 w=1080, h=1920

nw = 1080; nh=1920;
document.getElementById("ar").textContent = "9:16";
console.log('def width', width, width/1080);
r = 1080/width;

bar_height = 40 + 'px';
bar_height_new = parseInt(bar_height);


head_pad = 50 + 'px';
var head_pad_new = parseInt(head_pad);

var head_fs = 130 + 'px';
var head_fs_new = parseInt(head_fs);

var sub_pad = 50 + 'px';
var sub_pad_new = parseInt(sub_pad);

//pos from bottom (of text w/o padding)
var sub_pos = bar_height + sub_pad + 'px'; 
sub_pos = bar_height + 'px' ;
var sub_pos_new = parseInt(sub_pos);

var sub_fs = 110 + 'px';
var sub_fs_new = parseInt(sub_fs);

// console.log(bar_height_new, head_fs_new)
// return [bar_height_new, head_pad_new, head_fs_new*2/3, sub_pad_new, sub_pos_new, sub_fs_new*2/3]
}

else if (g == "1.8") {
	// 16:9 w=1920, h=1080

nw=1920; nh=1080;
document.getElementById("ar").textContent = "16:9";
console.log('def width', width, width/1920);
r = 1920/width;
bar_height = 30 + 'px';
bar_height_new = parseInt(bar_height);


head_pad = 50 + 'px';
var head_pad_new = parseInt(head_pad);

var head_fs = 130 + 'px';
var head_fs_new = parseInt(head_fs);


var sub_pad = 60 + 'px';
var sub_pad_new = parseInt(sub_pad);

//pos from bottom (of text w/o padding)
var sub_pos = bar_height + sub_pad + 'px'; 
var sub_pos = bar_height + 'px' ;
var sub_pos_new = parseInt(sub_pos);

var sub_fs = 120 + 'px';
var sub_fs_new = parseInt(sub_fs);

// console.log(bar_height_new, head_fs_new)
// return [bar_height_new, head_pad_new, head_fs_new*2/3, sub_pad_new, sub_pos_new, sub_fs_new*2/3]
}


else if (g == "1.0") {
	// 1:1 w=1080, h=1080

nw=1080; nh=1080;
r = 1080/width;
document.getElementById("ar").textContent = "1:1";
console.log('def width', width, width/1080);
bar_height = 25 + 'px';
bar_height_new = parseInt(bar_height);


head_pad = 25 + 'px';
var head_pad_new = parseInt(head_pad);

var head_fs = 110 + 'px';
var head_fs_new = parseInt(head_fs);


var sub_pad = 25 + 'px';
var sub_pad_new = parseInt(sub_pad);

//pos from bottom (of text w/o padding)
var sub_pos = bar_height + sub_pad + 'px'; 
var sub_pos = bar_height + 'px' ;
var sub_pos_new = parseInt(sub_pos);

var sub_fs = 90 + 'px';
var sub_fs_new = parseInt(sub_fs);

// console.log(bar_height_new, head_fs_new)
// return [bar_height_new, head_pad_new, head_fs_new*2/3, sub_pad_new, sub_pos_new, sub_fs_new*2/3]
}

else if(g == "0.8"){
	// 4:5 w=1080, h=1350	

nw=1080; nh=1350;
r = 1080/width;
document.getElementById("ar").textContent = "4:5";
console.log('def width', width, width/1080);
bar_height = 30 + 'px';
bar_height_new = parseInt(bar_height);


head_pad = 30 + 'px';
var head_pad_new = parseInt(head_pad);

var head_fs = 110 + 'px';
var head_fs_new = parseInt(head_fs);


var sub_pad = 30 + 'px';
var sub_pad_new = parseInt(sub_pad);

//pos from bottom (of text w/o padding)
var sub_pos = bar_height + sub_pad + 'px'; 
var sub_pos = bar_height + 'px' ;
var sub_pos_new = parseInt(sub_pos);

var sub_fs = 100 + 'px';
var sub_fs_new = parseInt(sub_fs);

// console.log(bar_height_new, head_fs_new)
// return [bar_height_new, head_pad_new, head_fs_new*2/3, sub_pad_new, sub_pos_new, sub_fs_new*2/3]
}

else {

// original w=1080 h=810  (4:3)
document.getElementById("ar").textContent = "Org";
console.log('def width', width, width/1080);
r = 1080/width;
var height = document.getElementById("stage").offsetHeight;
nw = 1080; nh = height*r
bar_height = 25 + 'px';
bar_height_new = parseInt(bar_height);


head_pad = 25 + 'px';
var head_pad_new = parseInt(head_pad);

var head_fs = 100 + 'px';
var head_fs_new = parseInt(head_fs);


var sub_pad = 25 + 'px';
var sub_pad_new = parseInt(sub_pad);

//pos from bottom (of text w/o padding)
var sub_pos = bar_height + sub_pad + 'px'; 
var sub_pos = bar_height + 'px' ;
var sub_pos_new = parseInt(sub_pos);

var sub_fs = 80 + 'px';
var sub_fs_new = parseInt(sub_fs);


// console.log(bar_height_new, head_fs_new)
// return [bar_height_new, head_pad_new, head_fs_new*2/3, sub_pad_new, sub_pos_new, sub_fs_new*2/3]

}

// store ratio in span-custom-seekbar11
document.getElementById("custom-seekbar11").setAttribute("ratio", r);
document.getElementById("custom-seekbar11").setAttribute("nw", nw);
document.getElementById("custom-seekbar11").setAttribute("nh", nh);

head_fs_new = head_fs_new*2/3
sub_fs_new = sub_fs_new*2/3

var c = 1/r
return [bar_height_new, head_pad_new, head_fs_new, sub_pad_new, sub_pos_new, sub_fs_new]

}

export const setTemplate1 = function(x, props) {

console.log('props setTemplate1', props)

// file has been uploaded
if(localStorage.getItem("filename")) {

console.log('HIIIIIIIIIIIIIIIIIIIIIII', document.getElementsByClassName("Mpbar")[0].style.display)
document.getElementsByClassName("Mpbar")[0].style.display = "none !important";
console.log('HIIIIIIIIIIIIIIIIIIIIIII', document.getElementsByClassName("Mpbar")[0].style.display)
}

// padding color for video
var v = document.getElementById("vid")
if (localStorage.getItem("videoColor")) {

var col = localStorage.getItem("videoColor")
v.style.backgroundColor = col;

var d = col.split("(")[1];
var dd = d.split(",");
try{
var a = parseFloat(dd[3].split(')')[0])
document.getElementById("v").style.fillOpacity = a;
} catch {}
var rgb =  [parseInt(dd[0]), parseInt(dd[1]),parseInt(dd[2])];

document.getElementById("v").style.fill = 'rgb('+rgb.join()+')';


} else {

v.style.backgroundColor = 'rgba(0,0,0,1)';

document.getElementById("v").style.fill = 'rgb(0,0,0)';
document.getElementById("v").style.fillOpacity = 1;


}

var vcx = document.getElementById("stage")
// 9:16
var hd = setVidDim(vcx.offsetWidth, props);


document.getElementById("vx").style.right = 0
document.getElementById("vx").style.left = 0
document.getElementById("vx").style.margin = 'auto'

// document.getElementById("vx").style.top = 0
// document.getElementById("vx").style.bottom = 0 + '%'


var c = 1/document.getElementById("custom-seekbar11").getAttribute("ratio");
var bar_height_new = c*hd[0];

var head_pad_new = c*hd[1];
var head_fs_new = c*hd[2];

var sub_pad_new = c*hd[3];
var sub_pos_new = c*hd[4];
var sub_fs_new = c*hd[5];

// if ratio changes?
if(JSON.parse(localStorage.getItem('seekbar'))[0]) {

	var x = JSON.parse(localStorage.getItem('seekbar'))
	var c = 1/document.getElementById("custom-seekbar11").getAttribute("ratio")

document.getElementById("custom-seekbar1").style.height = '3%';
console.log('XXXX', x)
console.log('SEEEEKBAAAR 1', document.getElementById("custom-seekbar1").style.height)
	// document.getElementById("custom-seekbar1").style.height = c*x[1] + 'px';
	// document.getElementById("custom-seekbar1").style.width = x[2] ;
	// document.getElementById("custom-seekbar1").style.top = x[3] ;
	document.getElementById("custom-seekbar11").style.backgroundColor = x[4];
	document.getElementById("custom-seekbar1").style.backgroundColor = x[5] ;
	// document.getElementById("custom-seekbar1").style.left = x[6] ;
	
	document.getElementById("fp").style.fill = x[4];
	document.getElementById("fp").style.fillOpacity = 1;

	document.getElementById("bp").style.fill = x[5];
	document.getElementById("bp").style.fillOpacity = 1;


} else {

console.log('SEEEEKBAAAR 2', document.getElementById("custom-seekbar1").style.height)
	// document.getElementById("custom-seekbar1").style.height = 2*bar_height_new + 'px';
	// document.getElementById("custom-seekbar1").style.width = 100 + '%';


	// document.getElementById("custom-seekbar1").style.top = 90 + '%';
	// document.getElementById("custom-seekbar11").style.backgroundColor = 'rgba(206,147,216,1)';
	// front
	document.getElementById("custom-seekbar11").style.backgroundColor = 'rgba(255,255,255,1)';
	// back
	document.getElementById("custom-seekbar1").style.backgroundColor = 'rgba(0,0,0,0.4)' ;
	// document.getElementById("custom-seekbar1").style.left = 0 + '%';
	
	document.getElementById("fp").style.fill = 'rgb(255,255,255)';
	document.getElementById("fp").style.fillOpacity = 1;

	document.getElementById("bp").style.fill = 'rgb(0,0,0)';
	document.getElementById("bp").style.fillOpacity = .4;
}


// var sub = document.getElementById("subtitle-text-container");
// sub.style.padding = sub_pad_new + 'px'

// document.getElementById("subtitle-text-container").style.fontSize = sub_fs_new + 'px'  //16px
// props.update_x(0, 'fs', sub_fs_new )

// document.getElementById("subtitle-text-container").style.padding = 0.5*sub_pad_new + 'px'


// if(JSON.parse(localStorage.getItem('substyle'))[0]) {



// 	// justifyContent = 'flex-start' 'flex-end' 'center'
// 	// if(x[1] == 'rgba(0, 0, 0, 0)') {
	
// 	if(x[1].split(',')[3] && x[1].split(',')[3].split(')')[0] == 0) {
// 		document.getElementsByClassName("wrap")[1].style.display = "block";
//   		document.getElementsByClassName("full")[1].style.display = "none";
//   		document.getElementsByClassName("tbg")[1].style.fill = x[10];
// 	} else {
// 		document.getElementsByClassName("wrap")[1].style.display = "none";
//   		document.getElementsByClassName("full")[1].style.display = "block";
//   		document.getElementsByClassName("tbg")[1].style.fill = x[1];
// 	}

// // in %
// 	// document.getElementById("subtitles-text-container").style.width = x[2]

// 	if(x[6]) { 

// 		document.getElementsByClassName("bold")[1].classList.add("clickedH")}
// 	if(x[7]) { 

// 		document.getElementsByClassName("italic")[1].classList.add("clickedH")
// 	}
// 	if(x[8]) { 

//   		document.getElementsByClassName("currentfont")[1].innerText = x[9];
// 	}

// 	// document.getElementById("subtitles-text-container").style.visibility = 'visible';
	
// 	document.getElementsByClassName("tfg")[1].style.fill = x[0];
// 	document.getElementsByClassName("tfg")[1].style.fillOpacity = 1;


// 	document.getElementsByClassName("tbg")[1].style.fillOpacity = 1;

// } else {




//   document.getElementsByClassName("wrap")[1].style.display = "none";
//   document.getElementsByClassName("full")[1].style.display = "block";


// 	document.getElementsByClassName("tfg")[1].style.fill = 'rgb(0,0,0)';
// 	document.getElementsByClassName("tfg")[1].style.fillOpacity = 1;

// 	document.getElementsByClassName("tbg")[1].style.fill = 'rgb(255,255,255)';
// 	document.getElementsByClassName("tbg")[1].style.fillOpacity = 0.5;

// 	document.getElementsByClassName("currentfont")[1].textContent = "Oswald"


// }


// var t = 0

// while(JSON.parse(localStorage.getItem(t))) {
// 		localStorage.removeItem(t)
// 		t = t+1
// }

// if there is already one head-container (=>all others will be there, dont do this)



var vid = document.getElementById("vid");

var texts = document.getElementsByClassName("head-text-container")
for (var k=0; k<texts.length; k++) {
	texts[k].style.fontSize = head_fs_new + 'px';
	texts[k].style.padding = 0.7*head_pad_new + 'px';
}

var rgb = hexToRgb(props.state.color)

rgb.push(0.5)
var hbc = 'rgba('+rgb.join()+')';

var sbc = 'rgba(255,255,255,0.5)';

var ht = 'rgba(251,241,251,1)';


var d = ht.split("(")[1];
var dd = d.split(",");
var a = parseFloat(dd[3].split(')')[0])
var rgb =  [parseInt(dd[0]), parseInt(dd[1]),parseInt(dd[2])];
// document.getElementById("bp").style.fill = 'rgb('+rgb.join()+')';
// document.getElementById("bp").style.fillOpacity = a




// document.getElementById("custom-seekbar11").getAttribute("ratio");


// var ht = 'rgba(255,0,0,1)';
var ht = props.state.color;

var rgb =  hexToRgb(props.state.color);
// document.getElementById("fp").style.fill = 'rgb('+rgb.join()+')';
// document.getElementById("fp").style.fillOpacity = a

var stc = 'rgba(0,0,0,1)';

// document.getElementById("subtitle-text-container").getBoundingClientRect().height - sub_pad_new

var sf = document.getElementsByClassName("fontsize")
// sub => 2nd element
sf[1].children[0].children[0].textContent = Math.round(sub_fs_new) ;

// document.getElementsByClassName("currentfont")[1].textContent = "Oswald"


// document.getElementsByText

// set subtitle text color
// var d = stc.split("(")[1];
// var dd = d.split(",");
// var a = parseFloat(dd[3].split(')')[0])
// var rgb =  [parseInt(dd[0]), parseInt(dd[1]),parseInt(dd[2])];
// document.getElementsByClassName("tfg")[1].style.fill = 'rgb('+rgb.join()+')';
// document.getElementsByClassName("tfg")[1].style.fillOpacity = a

// set subtitle back color
// var d = sbc.split("(")[1];
// var dd = d.split(",");
// var a = parseFloat(dd[3].split(')')[0])
// var rgb =  [parseInt(dd[0]), parseInt(dd[1]),parseInt(dd[2])];
// document.getElementsByClassName("tbg")[1].style.fill = 'rgb('+rgb.join()+')';
// document.getElementsByClassName("tbg")[1].style.fillOpacity = a



// // just around text padding/color
// var htc = 'rgba(255,255,255,1)';

// console.log('sf sf', sf[0].children[0])
sf[0].children[0].children[0].textContent = Math.round(head_fs_new);
document.getElementsByClassName("currentfont")[0].textContent = "Oswald"

// set heading text color
// var d = htc.split("(")[1];
// var dd = d.split(",");
// var a = parseFloat(dd[3].split(')')[0])
// var rgb =  [parseInt(dd[0]), parseInt(dd[1]),parseInt(dd[2])];
// document.getElementsByClassName("tfg")[0].style.fill = 'rgb('+rgb.join()+')';
// document.getElementsByClassName("tfg")[0].style.fillOpacity = a;

// set head back color
// console.log('hbc', hbc);
// var d = hbc.split("(")[1];
// var dd = d.split(",");
// var a = parseFloat(dd[3].split(')')[0])
// var rgb =  [parseInt(dd[0]), parseInt(dd[1]),parseInt(dd[2])];
// document.getElementsByClassName("tbg")[0].style.fill = 'rgb('+rgb.join()+')';
// document.getElementsByClassName("tbg")[0].style.fillOpacity = a;


// document.getElementById("askSub").style.display = 'block';

// set display subtitles as 0 => dont show subtitles
  // localStorage.setItem("showSub", "0")

// generate subtitle
document.getElementById("askSub").getElementsByClassName("Mpbar")[0].style.display = "none"
document.getElementById("askSub").getElementsByClassName("transcribe_")[0].style.display = "none"
 
// document.getElementById("askSub").getElementsByClassName("Mpbar")[0].style.display = "block"
// document.getElementById("askSub").getElementsByClassName("transcribe_")[0].style.display = "block"
// localStorage.setItem("showSub", "0")


}

import React from 'react';
import './navbar.css';


// import firebase from 'firebase/app';
// import 'firebase/auth';        // for authentication


import getFirebase from './firebase';
import { useEffect, useState } from 'react';    

import { Link } from "gatsby"

const firebase = getFirebase();




function Nav(props) {

    const [mount, setMount] = useState(false)

    const [signout, setSignout] = useState(false)
    

    // Signs-out of Friendly Chat.
    const signOut = () => setSignout(true)


  useEffect(() => {
    if (!firebase) return;
    
    if(!signout) return;

  // Sign out of Firebase.
  localStorage.clear()
  window.location.href = window.location.origin;

  firebase.auth().signOut()

  setSignout(false);

},[firebase, signout])

  useEffect(() => {

      setMount(true);

      // document.getElementById('price_path').href = window.location.origin + '/pricing'
      // const origin_ = window.location.origin
      // document.getElementById('href5').href = window.location.origin

// document.getElementById('pricingw').addEventListener('click', (e) => {

//         e.preventDefault();
//         e.stopPropagation();

//         login(e,props)
// }
// );

},[]) //useEffect




	return (
		<div id="NAVBAR">

		<nav class="navbar navbar-inverse navbar-fixed-top vid-fixed-top" role="navigation">
    <div class="container">

        <div class="navbar-header">

            <div class="navbar-brand">
                <Link classname="CB" to="/" >TurtleClip</Link>
            </div>
        </div>

        <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
            <ul class="nav navbar-nav navbar-right vid-list">
                <li><a >My Videos</a></li>
                <li><Link id='price_path' to="/pricing">Pricing</Link></li>
      
                <li class="nav-item dropdown">
      			     <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
        		      Account
      			     </a>
			           <div class="dropdown-menu">
			             <a onClick={signOut} class="dropdown-item">Sign Out</a>
			           </div>
			          </li>
            </ul>
        </div>
    </div>
</nav>
		</div>

		)
}


Nav.displayName = "Nav";
export default React.memo(Nav);

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tab1 from './tab1';
import Reset from './reset';

function TabPanel(props) {

  // const state = props.state;

  const { children, value, index, ...other } = props;
  // console.log("in tabpanel ", state);
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={5}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function SimpleTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // console.log("in simple tabs ", props.state);
  return (
  <div id="dcolumn">
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Subtitles" {...a11yProps(0)} />
          <Tab label="Style" {...a11yProps(1)} />
          <Tab label="Newletter" {...a11yProps(2)} />

        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Tab1 state={props.state} changeTime={props.changeTime} changeHead = {props.changeHead} changeSub={props.changeSub} changeBothTime={props.changeBothTime} changeKey={props.changeKey} changeCaptions={props.changeCaptions} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Reset state={props.state} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        Newletter vs Blog Content
      </TabPanel>
    </div>
  </div>
  );
}

SimpleTabs.displayName = "SimpleTabs";
export default React.memo(SimpleTabs);
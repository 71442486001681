
import React , { useState,useEffect } from 'react';

/* global $ */
/* global moment */

import '../components/xoxo.css';
// import './playButton.css';
import '../components/bttn2.scss';
import '../components/zoomslider.css';
import {processor} from '../components/processor.js';
// import vid from './outputx.mp4';
import Tabs from '../components/tabs';
import Vidstyle from '../components/vidstyle';
import Style from '../components/style';
import Vid from '../components/vid';

import {connect} from 'react-redux';

// import Nav from './navbar';
// import Tab1 from './tab1';

// import Downopt from './downopt';
import axios from 'axios';
// import useScript from './usescript.js';

// import './timer.js';
import { increment, update_x, edit_boxes, pop_boxes, invalidate, selectedText, render_x, decrement, reset } from "../actions/sampleAction";



function Vidx(props){



useEffect(() => {

      document.title = 'TurtleClip | Edit';

  // Webhook Way

//    if(window.location.search.split('=')[0] == "?payment_id") {

//     console.log('IN PAYMENT ID !!!')

//     localStorage.setItem('cn', '10') 

// // call convertToImage in canvastry.js to render video
//     document.getElementById("downloadx").click() 

//   } 

// onFiledrop
if( localStorage.getItem('blob') ) {
    var previewEl = document.getElementById("vid1")
    previewEl.src = localStorage.getItem('blob');
    document.getElementById("lang").style.display = "block"
    localStorage.removeItem('blob')
}


// from - onFiledrop, or workspace or direct link 
// call api if localStorage doesnt match with URL!!! YAYYYYY!!
// or if localStorage is empty (i.e. logged out), but you dont wanna show translated text yeah?
if(!localStorage.getItem('filename') || localStorage.getItem('filename').split('.')[0] != window.location.search.split('?v=')[1]) {

var url = 'https://la04iscxs9.execute-api.us-west-2.amazonaws.com/default/json_from_s3?key=' + window.location.search.split('?v=')[1] + '.json'

console.log('url', url)

axios.get(url).then((res) => {
      console.log('GET', res.data)
      var x = JSON.parse(res.data)

      var ks = Object.keys(x)

      var uid = localStorage.getItem("uid")
      var pic = localStorage.getItem("pic")
      var subscribe = localStorage.getItem("cn")
      var email = localStorage.getItem("email")
      var login = localStorage.getItem("login")

      localStorage.clear()

      if(uid) localStorage.setItem("uid", uid)
      if(pic) localStorage.setItem("pic", pic)
      if(subscribe) localStorage.setItem("cn", subscribe)
      if(email) localStorage.setItem('email', email)
      if(login) localStorage.setItem('login', '1')

      for (var i=0; i<ks.length; i++) {

          localStorage.setItem(ks[i], x[ks[i]])
      }

// subtitles have already been generated, hence showSub
      localStorage.setItem("showSub", "1")

}).catch(err => console.log(err))


}



// set subscribe

if(localStorage.getItem('email')) {
        var suburl = 'https://mxfrnd93z6.execute-api.us-west-2.amazonaws.com/default/read_subscribe_table?email=' + localStorage.getItem('email')

        axios.get(suburl).then(response => {

          console.log('subscribe', response.data.Item, response.data.Item.count.S)
          console.log(localStorage.getItem('cn'))
        
          localStorage.setItem('cn', response.data.Item.count.S)

          console.log(localStorage.getItem('cn'))
          // props.render_x('subscribe', response.data.Item.sub.S)
  

        // if the item doesnt exist in subscribe table
        }).catch(e => {
          
          localStorage.setItem('cn', '0');         
          // props.render_x('subscribe', '0');
        })

} //close IF



},[]) 

//  this.props.location.state.detail

// useEffect(() => {
    console.log(props)


    // const meta = props.location.state.vid.meta;

    const [stat, setStat ] =  useState( 
    {
      id: "outputx.mp4",
      // color: props.location.state.vid.color,
      color: '#e63946',
   
    });

    console.log('stat', stat)

// },[localStorage.getItem('meta'), props.location.state.vid.vid])
      // console.log('vid after setSTat',props.s.vid)

useEffect(() => {

setStat({...stat, 

      id: "outputx.mp4",
      // color: props.location.state.vid.color,
      color: '#e63946',
   
    
 })

},[])


    // <Tab1 state={stat} changeTime={changeTime} changeHead = {changeHead} changeSub={changeSub} changeBothTime={changeBothTime} changeKey={changeKey} changeCaptions={changeCaptions} />
    // <Tab1 />
    // <Vidstyle state={stat} />


// componentDidMount() {} 
  
  // render () {
    // console.log('common file sending state to vid/tab', this.state.sub);

    // <Downopt props={props} />
  return (

<div id="colx">

<div id="row">
    <Vid x={props} state={stat} />
    
    <Style state={stat} props={props}/>
    
</div>  
</div>   

      
    );
  }



const mapStateToProps = (state) => {
  console.log('!!!mapStateToProps', state)
  return {
  boxes2: state.data.boxes2,
  canvasValid: state.data.canvasValid,
  selectText: state.data.selectText,

  xout: state.data.xout,
  width: state.data.width,
  height: state.data.height,
  pc: state.data.pc,
  back_bar: state.data.back_bar, 
  front_bar: state.data.front_bar,
  bar_height: state.data.bar_height,
  duration: state.data.duration,
  dvid: state.data.dvid,
  pre_sub: state.data.pre_sub,
  ti: state.data.ti,

  render: state.data.render,
  subscribe: state.data.subscribe,
  uid: state.data.uid,
  email: state.data.email,
  pic: state.data.pic,
  filename: state.data.filename,
  
}}

Vidx.displayName = "Vidx";
export default connect(mapStateToProps, { increment, update_x, edit_boxes, pop_boxes, invalidate, selectedText, render_x, decrement, reset })(React.memo(Vidx));

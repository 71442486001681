import React from 'react';

import './translate.css';

import axios from 'axios'; 
import ClearIcon from '@material-ui/icons/Clear';
import CircularProgress from '@material-ui/core/CircularProgress';

function closetrans(e) {

      e.preventDefault();
      e.stopPropagation();

  document.getElementById("trans").style.display = 'none'

}

function selecttrans(e, props) {

        e.preventDefault();
      e.stopPropagation();



  document.getElementsByClassName("input-trans")[0].value = e.target.textContent

  var lang = e.target.textContent.split('(')[1].split(')')[0]

  localStorage.setItem('trans', lang)

  document.getElementById("oktrans").style.display = 'block'


}


function oksubmit(e) {

      e.preventDefault();
      e.stopPropagation();

// click an1 to set original transcription to subx
    document.getElementById("an1").click()


  document.getElementById("circleTrans").style.display = 'block'

  let lang = localStorage.getItem('lang').split('-')[0]

  if(localStorage.getItem('lang') == 'fr-CA') {
    lang = localStorage.getItem('lang')
  }

  let trans = {}

  // localStorage.setItem('subx', localStorage.getItem('langx'))

  let data = JSON.parse(localStorage.getItem('subx'))

  var url = 'https://416m0dr4vj.execute-api.us-west-2.amazonaws.com/default/translate-audio' + 
  '?review_id=' + Date.now()
  + '&source_language=' + lang 
  + '&target_language=' + localStorage.getItem('trans')

  console.log('url', url)
  let k = 0

  for (var i=0; i<data.length; i++) {

    console.log('i', i, 'post', data[i] )

// if empty string, move on
    if(data[i].trim().length == 0)  {k=k+1; trans[i] = ''; continue; }

 axios.post(url + '&i=' + i, data[i]
 ,{
            headers: {
              'Content-Type': 'application/json',
            },
          }).then((res) => {
            
            console.log('heyyy', k)
            console.log('res', res.config.data)
            console.log(res.data)

            k = k+1

            try {
            trans[res.data.number] = res.data.text.slice(1,-1)
          } catch(e) {
            trans[res.data.number] = res.data.text
          }

            console.log('k', k, 'data.length', data.length)
            

            if (k == data.length) {
              console.log('trans', trans) 


              document.getElementById('an2').innerText = localStorage.getItem('trans')

              let su = []


              for (let h=0; h<k; h++) {
                document.getElementById(h).innerText = trans[h]

                su.push(trans[h])

                console.log('su', su)
              }

              localStorage.setItem('transx', JSON.stringify(su)); 

              console.log('transx', localStorage.getItem('transx'))

              document.getElementById("circleTrans").style.display = 'none'
              document.getElementById("trans").style.display = 'none'

              document.getElementById("an1").setAttribute('x', "0")
              document.getElementById("an2").setAttribute('x', "1")

          }

          }).catch(err => {
            console.log('nooo error :(', err)
            document.getElementById("circleTrans").style.display = 'none'
          }) 
  }


}


function Translate(props){	


return (
	<div id="trans">

	<span className="transalert">
    <ClearIcon id='closetrans' onClick={(e) => closetrans(e)} />
  <span>Translate To </span>
    <CircularProgress id="circleTrans"/>



  <div className="transout">

  <input id="react-select-6-input" tabindex="0" readonly="" aria-autocomplete="list" className="input-trans" value="" disabled=""/>



  <div className="css-1wy0on6">
  <div aria-hidden="true" className=" css-5e2tja-indicatorContainer"><svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-19bqh2r"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
  </div>

  </div>

  </div>

  <button id='oktrans' onClick={(e) => oksubmit(e)}>OK</button>

  </span>


<div id="transselect" onClick={(e) => selecttrans(e, props)}>
<button type="button" data-toggle="tab" className="btn-default">Afrikaans (af)</button>

<button type="button" data-toggle="tab" className="btn-default">Albanian (sq)</button>

<button type="button" data-toggle="tab" className="btn-default">Amharic (am)</button>

<button type="button" data-toggle="tab" className="btn-default">Arabic (ar)</button>

<button type="button" data-toggle="tab" className="btn-default">Armenian (hy)</button> 

<button type="button" data-toggle="tab" className="btn-default">Azerbaijani (az)</button>

<button type="button" data-toggle="tab" className="btn-default">Bengali (bn)</button>

<button type="button" data-toggle="tab" className="btn-default">Bosnian (bs)</button>

<button type="button" data-toggle="tab" className="btn-default">Bulgarian (bg)</button>

<button type="button" data-toggle="tab" className="btn-default">Catalan (ca)</button>

<button type="button" data-toggle="tab" className="btn-default">Chinese (Simplified) (zh)</button>

<button type="button" data-toggle="tab" className="btn-default">Chinese (Traditional) (zh-TW)</button>

<button type="button" data-toggle="tab" className="btn-default">Croatian (hr)</button>

<button type="button" data-toggle="tab" className="btn-default">Czech (cs)</button>

<button type="button" data-toggle="tab" className="btn-default">Danish (da)</button>

<button type="button" data-toggle="tab" className="btn-default">Dari (fa-AF)</button>

<button type="button" data-toggle="tab" className="btn-default">Dutch (nl)</button>

<button type="button" data-toggle="tab" className="btn-default">English (en)</button>

<button type="button" data-toggle="tab" className="btn-default">Estonian (et)</button>

<button type="button" data-toggle="tab" className="btn-default">Farsi (Persian) (fa)</button>

<button type="button" data-toggle="tab" className="btn-default">Filipino Tagalog (tl)</button>

<button type="button" data-toggle="tab" className="btn-default">Finnish (fi)</button>

<button type="button" data-toggle="tab" className="btn-default">French (fr)</button>

<button type="button" data-toggle="tab" className="btn-default">French (Canada) (fr-CA)</button>

<button type="button" data-toggle="tab" className="btn-default">Georgian (ka)</button>

<button type="button" data-toggle="tab" className="btn-default">German (de)</button>

<button type="button" data-toggle="tab" className="btn-default">Greek (el)</button>

<button type="button" data-toggle="tab" className="btn-default">Gujarati (gu)</button>

<button type="button" data-toggle="tab" className="btn-default">Haitian Creole (ht)</button>

<button type="button" data-toggle="tab" className="btn-default">Hausa (ha)</button>

<button type="button" data-toggle="tab" className="btn-default">Hebrew (he)</button>

<button type="button" data-toggle="tab" className="btn-default">Hindi (hi)</button>
<button type="button" data-toggle="tab" className="btn-default">Hungarian (hu)</button>
<button type="button" data-toggle="tab" className="btn-default">Icelandic (is)</button>
<button type="button" data-toggle="tab" className="btn-default">Indonesian (id)</button>
<button type="button" data-toggle="tab" className="btn-default">Italian (it)</button>
<button type="button" data-toggle="tab" className="btn-default">Japanese (ja)</button>
<button type="button" data-toggle="tab" className="btn-default">Kannada (kn)</button>
<button type="button" data-toggle="tab" className="btn-default">Kazakh (kk)</button>


<button type="button" data-toggle="tab" className="btn-default">Korean (ko)</button>
<button type="button" data-toggle="tab" className="btn-default">Latvian (lv)</button>
<button type="button" data-toggle="tab" className="btn-default">Lithuanian (lt)</button>
<button type="button" data-toggle="tab" className="btn-default">Macedonian (mk)</button>
<button type="button" data-toggle="tab" className="btn-default">Malay (ms)</button>
<button type="button" data-toggle="tab" className="btn-default">Malayalam (ml)</button>
<button type="button" data-toggle="tab" className="btn-default">Maltese (mt)</button>

<button type="button" data-toggle="tab" className="btn-default">Mongolian (mn)</button>
<button type="button" data-toggle="tab" className="btn-default">Norwegian (no)</button>
<button type="button" data-toggle="tab" className="btn-default">Persian (fa)</button>
<button type="button" data-toggle="tab" className="btn-default">Pashto (ps)</button>
<button type="button" data-toggle="tab" className="btn-default">Polish (pl)</button>
<button type="button" data-toggle="tab" className="btn-default">Portuguese (pt)</button>
<button type="button" data-toggle="tab" className="btn-default">Romanian (ro)</button>


<button type="button" data-toggle="tab" className="btn-default">Russian (ru)</button>
<button type="button" data-toggle="tab" className="btn-default">Serbian (sr)</button>
<button type="button" data-toggle="tab" className="btn-default">Sinhala (si)</button>
<button type="button" data-toggle="tab" className="btn-default">Slovak (sk)</button>
<button type="button" data-toggle="tab" className="btn-default">Slovenian (sl)</button>
<button type="button" data-toggle="tab" className="btn-default">Somali (so)</button>
<button type="button" data-toggle="tab" className="btn-default">Spanish (es)</button>


<button type="button" data-toggle="tab" className="btn-default">Spanish (Mexico) (es-MX)</button>
<button type="button" data-toggle="tab" className="btn-default">Swahili (sw)</button>
<button type="button" data-toggle="tab" className="btn-default">Swedish (sv)</button>
<button type="button" data-toggle="tab" className="btn-default">Tagalog (tl)</button>
<button type="button" data-toggle="tab" className="btn-default">Tamil (ta)</button>
<button type="button" data-toggle="tab" className="btn-default">Telugu (te)</button>
<button type="button" data-toggle="tab" className="btn-default">Thai (th)</button>

<button type="button" data-toggle="tab" className="btn-default">Turkish (tr)</button>
<button type="button" data-toggle="tab" className="btn-default">Ukrainian (uk)</button>
<button type="button" data-toggle="tab" className="btn-default">Urdu (ur)</button>
<button type="button" data-toggle="tab" className="btn-default">Uzbek (uz)</button>
<button type="button" data-toggle="tab" className="btn-default">Vietnamese (vi)</button>
<button type="button" data-toggle="tab" className="btn-default">Welsh (cy)</button>
</div>


	</div>

)}


Translate.displayName = "Translate";
export default React.memo(Translate);

// the setting bar towards left to change styling

/* global $ */

import React from 'react';

// import pop from './xyz.mp4';
import './canvastry.css';

import {connect} from 'react-redux';

import {setDim} from './utils';
import axios from 'axios'; 

import {storeSub, storeBar, storeVideo, storeSubstyle, storeBox} from './storelocalstorage';
import {setSubTime, setSubBegin, splitSub} from './arrangesub';
import {gethex} from './utilsave.js';

import { strToMili,  miliToMinTime, gettime, showSubstyle,showTextstyle } from './utils';

import {login_in_create} from './firebase_login';
// import useFirebase from './useFirebase';

import getFirebase from './firebase';

import { increment, update_x, edit_boxes, pop_boxes, invalidate, selectedText, render_x, decrement, reset } from "../actions/sampleAction"

import { navigate } from "gatsby" ;

import {get_srt} from './get_srt';

// add 'download' link as well + watermark and no watermark flow
// send video generate link with watermark yes or no option
// how to check if payment was successful ? 
// change payment success page to video output page! create another button for this

//  dont clear localstorage while login when on vidx page - subtitles havent been saved yet.
//  rather send localstorage to awsdynamoDB

  // const increment = this.props.increment
  const boxes2 = []

  

// Last updated November 2010 by Simon Sarris
// www.simonsarris.com
// sarris@acm.org
//
// Free to use and distribute at will
// So long as you are nice to people, etc

// This is a self-executing function that I added only to stop this
// new script from interfering with the old one. It's a good idea in general, but not
// something I wanted to go over during this tutorial
// (function(window) {


  // holds all our boxes
  // var boxes2 = [];

  // New, holds the 8 tiny boxes that will be our selection handles
  // the selection handles will be in this order:
  // 0  1  2
  // 3     4
  // 5  6  7
  var selectionHandles = [];

  // Hold canvas information
  var canvas;
  var ctx;
  var WIDTH;
  var HEIGHT;
  var INTERVAL = 20; // how often, in milliseconds, we check to see if a redraw is needed

  var isDrag = false;
  var isResizeDrag = false;
  var expectResize = -1; // New, will save the # of the selection handle if the mouse is over one.
  var mx, my; // mouse coordinates

  // when set to true, the canvas will redraw everything
  // invalidate() just sets this to false right now
  // we want to call invalidate() whenever we make a change
  // var canvasValid = false;

  // The node (if any) being selected.
  // If in the future we want to select multiple objects, this will get turned into an array
  var mySel = null;

  // The selection color and width. Right now we have a red selection with a small width
  // var mySelColor = '#CC0000';
  var mySelColor = 'rgba(125,205,235,1)';
  var mySelWidth = 2;
  var mySelBoxColor = 'rgba(225,225,225,1)'; // New for selection boxes
  var mySelBoxSize = 6;

  // we use a fake canvas to draw individual shapes for selection testing
  var ghostcanvas;
  var gctx; // fake canvas context

  // since we can drag from anywhere in a node
  // instead of just its x/y corner, we need to save
  // the offset of the mouse when we start dragging.
  var offsetx, offsety;

  // Padding and border style widths for mouse offsets
  var stylePaddingLeft, stylePaddingTop, styleBorderLeft, styleBorderTop;

// x and y text distance from rectange
  var marginB

  // Box object to hold data
  class Box2 {

    constructor(){
    this.x = 0;
    this.y = 0;
    this.w = 1; // default width and height?
    this.h = 1;
    this.fill = '#444444';

    this.text = 'write text here';

    this.fs = 20;
    this.f = 'Oswald';
    this.align = 'center'; 
    this.tc = 'rgba(255,255,255,1)';
    this.html = 'write text here';
    this.b = '';
    this.i = '';
  }

  // New methods on the Box class
  // Box2.prototype = {
    // we used to have a solo draw function
    // but now each box is responsible for its own drawing
    // mainDraw() will call this with the normal canvas
    // myDown will call this with the ghost canvas with 'black'
    draw(context, optionalColor) {
      if (context === gctx) {
        context.strokeStyle = 'black'
        context.fillStyle = 'black'; // always want black for the ghost canvas
      } else {
        context.strokeStyle = this.fill
        context.fillStyle = this.fill;
      }

      const self = new Canvastry() 
// // to ensure that height of rect is in coherence with text height (while resizing)
     this.h = 2*marginB + self.TwrapTextDummy(ctx, this.html, 0, 0, this.w - 2*marginB, this.fs + 0.4*marginB, this.fs, this.f, this.b, this.i)

     console.log('this.h', this.h)

      // We can skip the drawing of elements that have moved off the screen:
      if (this.x > WIDTH || this.y > HEIGHT) return;

      if (this.x + this.w < 0 || this.y + this.h < 0) return;


      self.DrawText(context, this.x, this.y, this.w, this.h, this.text, this.fs, this.f, this.align, this.tc, this.b, this.i)
// make a rectangle
//       context.fillRect(this.x, this.y, this.w, this.h);

// these are 8 selection boxes

      // draw selection
      // this is a stroke along the box and also 8 new selection handles
      if (mySel === this) {
        context.strokeStyle = mySelColor;
        context.lineWidth = mySelWidth;
        context.strokeRect(this.x, this.y, this.w, this.h);

        // draw the boxes

        var half = mySelBoxSize / 2;

        // 0  1  2
        // 3     4
        // 5  6  7

        // top left, middle, right
        selectionHandles[0].x = this.x - half;
        selectionHandles[0].y = this.y - half;

        selectionHandles[1].x = this.x + this.w / 2 - half;
        selectionHandles[1].y = this.y - half;

        selectionHandles[2].x = this.x + this.w - half;
        selectionHandles[2].y = this.y - half;

        //middle left
        selectionHandles[3].x = this.x - half;
        selectionHandles[3].y = this.y + this.h / 2 - half;

        //middle right
        selectionHandles[4].x = this.x + this.w - half;
        selectionHandles[4].y = this.y + this.h / 2 - half;

        //bottom left, middle, right
        selectionHandles[6].x = this.x + this.w / 2 - half;
        selectionHandles[6].y = this.y + this.h - half;

        selectionHandles[5].x = this.x - half;
        selectionHandles[5].y = this.y + this.h - half;

        selectionHandles[7].x = this.x + this.w - half;
        selectionHandles[7].y = this.y + this.h - half;


// the selection small boxes
        context.fillStyle = mySelBoxColor;
        for (var i = 0; i < 8; i++) {
          var cur = selectionHandles[i];
          context.fillRect(cur.x, cur.y, mySelBoxSize, mySelBoxSize);
        }
      }

    } // end draw

  } // close class Box2 


class Canvastry extends React.Component {

constructor(props) {
    super(props);

// save mutation
    this.sendJsonToS3 = this.sendJsonToS3.bind(this);
// call this on resize or Aspect Ratio change
    this.setVideo = this.setVideo.bind(this);

    this.setARatio = this.setARatio.bind(this);
    this.init2 = this.init2.bind(this);
    this.addTextBox = this.addTextBox.bind(this);
    this.myDblClick = this.myDblClick.bind(this);
    this.edit_text = this.edit_text.bind(this);
    this.invalidate = this.invalidate.bind(this);
    this.getMouse = this.getMouse.bind(this);
    this.getMouse_global = this.getMouse_global.bind(this);
    this.DrawText = this.DrawText.bind(this);
    this.wrapText = this.wrapText.bind(this);
    this.TwrapTextDummy = this.TwrapTextDummy.bind(this);
    this.myUp = this.myUp.bind(this);
    this.myDown = this.myDown.bind(this);
    this.deselect = this.deselect.bind(this);
    this.addRect = this.addRect.bind(this);
    this.deleteText_canvas = this.deleteText_canvas.bind(this);
    this.clear = this.clear.bind(this);
    this.mainDraw = this.mainDraw.bind(this);
    this.roundRect = this.roundRect.bind(this);
    this.keytimer = this.keytimer.bind(this);
    this.keytime_2 = this.keytime_2.bind(this);
    this.myMove = this.myMove.bind(this);
    this.convertToImage = this.convertToImage.bind(this);
    this.setVidDim = this.setVidDim.bind(this);

}


sendJsonToS3(e) {

      e.preventDefault();
      e.stopPropagation();


      var fname = localStorage.getItem('filename').split('.')[0] + '.json'
    var url = 'https://xplks8isk4.execute-api.us-west-2.amazonaws.com/default/json_to_s3?key=' + fname

    console.log('sending json to s3', url)
    axios.post(url, JSON.stringify(JSON.parse(JSON.stringify(localStorage))),{
            headers: {
              'Content-Type': 'application/json',
            },
          }).then((res) => {
            console.log('POST JSON res', res)}).catch(err => {console.log('json post ERROR', err)})
}



setVideo(e) {

  var vcx = document.getElementById("stage")
  // 9:16 // sets r, nh, nw in seekbar
  var hd = this.setVidDim(vcx.offsetWidth, this.props);

  console.log('in init2', this.props)

  canvas = document.getElementById('canvas2');

  var X_old = canvas.width
  var Y_old = canvas.height

  HEIGHT = vcx.offsetHeight ;
  WIDTH = vcx.offsetWidth;
  canvas.height = HEIGHT;
  canvas.width = WIDTH;
  ghostcanvas.height = HEIGHT;
  ghostcanvas.width = WIDTH;
  console.log('New canvas dimensions', canvas.height, canvas.width)
  // gctx = ghostcanvas.getContext('2d');


  var X_new = canvas.width
  var Y_new = canvas.height

  var rx = X_new / X_old
  var ry = Y_new / Y_old

  var bx
  for (var k=0; k<this.props.boxes2.length; k++) {
      bx = this.props.boxes2[k]

      this.props.update_x(k, 'x', (bx.x)*rx)
      this.props.update_x(k, 'y', (bx.y)*ry)
      this.props.update_x(k, 'w', (bx.w)*rx)
      this.props.update_x(k, 'fs', (bx.fs)*ry) 

      this.invalidate()
  }

  this.invalidate();
}


setARatio(e, r=0) {
  var txtt = e.target.innerText.split(' ')[0]
  console.log('txtt', txtt)
  e.currentTarget.previousSibling.firstChild.innerHTML = txtt;

  var X_old = document.getElementById('stage').offsetWidth
  var Y_old = document.getElementById('stage').offsetHeight

  if(!r) r = e.target.id 

  setDim(r, this.props)

  var X_new = document.getElementById('stage').offsetWidth
  var Y_new = document.getElementById('stage').offsetHeight

  var rx = X_new / X_old
  var ry = Y_new / Y_old

  console.log('this.props.boxes2', this.props)
  var bx
  for (var k=0; k<this.props.boxes2.length; k++) {
      bx = this.props.boxes2[k]

      this.props.update_x(k, 'x', (bx.x)*rx)
      this.props.update_x(k, 'y', (bx.y)*ry)
      this.props.update_x(k, 'w', (bx.w)*rx)
      this.props.update_x(k, 'fs', (bx.fs)*ry) 

      this.invalidate()
  }


  // setDim(r, props)

  // setTemplate1(1, props);
  this.setVideo()

  this.sendJsonToS3(e)
}



init2(){

  console.log('in init2', this.props)
  canvas = document.getElementById('canvas2');

  HEIGHT = canvas.height;
  WIDTH = canvas.width;
  ctx = canvas.getContext('2d');
  ghostcanvas = document.createElement('canvas');
  ghostcanvas.height = HEIGHT;
  ghostcanvas.width = WIDTH;
  gctx = ghostcanvas.getContext('2d');



  console.log('CANVAS', canvas, HEIGHT, WIDTH)

  //fixes a problem where double clicking causes text to get selected on the canvas
  canvas.onselectstart = function() {
    return false;
  }

  // fixes mouse co-ordinate problems when there's a border or padding
  // see getMouse for more detail
  if (document.defaultView && document.defaultView.getComputedStyle) {
    stylePaddingLeft = parseInt(document.defaultView.getComputedStyle(canvas, null)['paddingLeft'], 10) || 0;
    stylePaddingTop = parseInt(document.defaultView.getComputedStyle(canvas, null)['paddingTop'], 10) || 0;
    styleBorderLeft = parseInt(document.defaultView.getComputedStyle(canvas, null)['borderLeftWidth'], 10) || 0;
    styleBorderTop = parseInt(document.defaultView.getComputedStyle(canvas, null)['borderTopWidth'], 10) || 0;
  }

  // make mainDraw() fire every INTERVAL milliseconds
  setInterval(this.mainDraw, INTERVAL);

  // set our events. Up and down are for dragging,
  // double click is for making new boxes
  canvas.onmousedown = this.myDown;

// while resize out of canvas, need mouseUp event for body
  // canvas.onmouseup = this.myUp;
  document.body.onmouseup = this.myUp;
  // document.body.onmousedown = this.deselect;
  // canvas.ondblclick = myDblClick;


// **********************************************************
// if you just want to resize rectangle just inside canvas
  // canvas.onmousemove = this.myMove;

// if getting out of canvas is also fine
  document.body.onmousemove = this.myMove;

  // set up the selection handle boxes
  for (var i = 0; i < 8; i++) {
    var rect = new Box2;
    selectionHandles.push(rect);
  }

  // add custom initialization here:

marginB = 12


// font_size, font, alignment, text-color, back-color
var fs, f, align, tc, bc

// 1. space after \n => space before first letter in 2nd line
// 2. need to use <br /> because \n doesnt reflect in offsetHeight of span

  // add a large green rectangle
  fs = 20 //px; 
  f = 'Oswald'
  align = 'center'
  tc = 'rgba(255,255,255,1)'
  bc = 'rgba(150,150,250,0)'


  // console.log('boxes2', this.props.boxes2)
// document.getElementsByClassName("caption")[0].innerHTML
  // var hh = this.TwrapTextDummy(ctx, "None <br>of these <br> things have" , 0, 0, 200 - 2*marginB, fs + 0.4*marginB, fs, f)
  // // add a smaller purple rectangle


// if box doesnt exist in boxes2
// if (!this.props.boxes2[0]) {
  console.log('this.props.boxes2', this.props)


if (!this.props.boxes2[0]) {
  this.addRect(0, HEIGHT/2, WIDTH, 0, bc, "", fs, f, align, tc, "", 0);
}
  // sub => 2nd element
  var sf = document.getElementsByClassName("fontsize")
  sf[1].children[0].children[0].textContent = Math.round(fs)
  document.getElementsByClassName("currentfont")[1].textContent = f

  var dox
  // if(window.localStorage.getItem("box_0")) {



// if the subtitles exist already
  if(JSON.parse(window.localStorage.getItem("subx")) && JSON.parse(window.localStorage.getItem("subx"))[0]) {

    dox = JSON.parse(window.localStorage.getItem("box_0"))
    this.props.update_x(0, 'x', dox.x)
    this.props.update_x(0, 'y', dox.y)
    this.props.update_x(0, 'w', dox.w)
    // this.props.update_x(0, 'h', dox.h)
    this.props.update_x(0, 'tc', dox.tc)

    var rxgbx = dox.fill
    var fd = rxgbx.split("(")[1];
    var fdd = fd.split(",");    
    var rgba_ = [parseInt(fdd[0]), parseInt(fdd[1]),parseInt(fdd[2]), 0]

    this.props.update_x(0, 'fill', 'rgba('+rgba_.join()+')')

    // this.props.update_x(0, 'fill', dox.fill)
    this.props.update_x(0, 'fs', dox.fs)
    this.props.update_x(0, 'f', dox.f)
    this.props.update_x(0, 'align', dox.align)
    // this.props.update_x(0, 'text', dox.text)
    // this.props.update_x(0, 'html', dox.html)
    this.props.update_x(0, 'a', dox.a)

    var sf = document.getElementsByClassName("fontsize")
    sf[1].children[0].children[0].textContent = Math.round(dox.fs)
    document.getElementsByClassName("currentfont")[1].textContent = dox.f


// whether to show subtitles at t = 0 or not
    var subs = document.getElementById('subdiv').children
    console.log('%%%%', subs, subs[1])
    if(JSON.parse(localStorage.getItem('strt'))[0] == "00:00.0") {

      var text_title = JSON.parse(localStorage.getItem('subx'))[0]

      var text_html = text_title.replace(/\n/g,'<br>')
      text_html = text_html.replace(/&nbsp/g,'').replace(/&nbsp;/g,'')

      this.props.update_x(0, 'text', text_title)
      this.props.update_x(0, 'html', text_html)

      rgba_ = [parseInt(fdd[0]), parseInt(fdd[1]),parseInt(fdd[2]), dox.a]

      this.props.update_x(0, 'fill', 'rgba('+rgba_.join()+')')

    }

  } 

  // console.log('boxes2', this.props.boxes2)

if(parseInt(localStorage.getItem('nBox')) && parseInt(localStorage.getItem('nBox'))>0) {

  for (var g=1; g<parseInt(localStorage.getItem('nBox')); g++) {
      dox = JSON.parse(window.localStorage.getItem("box_" + g))

      if (!this.props.boxes2[g]) {
      this.addRect(dox.x, dox.y, dox.w, dox.h, dox.fill, dox.text, dox.fs, dox.f, dox.align, dox.tc, dox.html, dox.a)
}
      sf[0].children[0].children[0].textContent = Math.round(dox.fs)
      
}

}

if (localStorage.getItem('videoColor') ){

document.getElementById("vid").style.backgroundColor = localStorage.getItem('videoColor');
} else {
 document.getElementById("vid").style.backgroundColor = 'rgba(0,0,0,1)';
}


// if ratio changes?
if(JSON.parse(localStorage.getItem('seekbar'))[0]) {

  var x = JSON.parse(localStorage.getItem('seekbar'))
  var c = 1/document.getElementById("custom-seekbar11").getAttribute("ratio")

  document.getElementById("custom-seekbar11").style.backgroundColor = x[4];
  document.getElementById("custom-seekbar1").style.backgroundColor = x[5] ;
  // document.getElementById("custom-seekbar1").style.left = x[6] ;
  
  document.getElementById("fp").style.fill = x[4];
  document.getElementById("fp").style.fillOpacity = 1;

  document.getElementById("bp").style.fill = x[5];
  document.getElementById("bp").style.fillOpacity = 1;


} else {

  document.getElementById("custom-seekbar1").style.width = 100 + '%';
  document.getElementById("custom-seekbar1").style.bottom = 0 + '%';
  // front
  document.getElementById("custom-seekbar11").style.backgroundColor = 'rgba(255,0,0,1)';
  // back
  document.getElementById("custom-seekbar1").style.backgroundColor = 'rgba(0,0,0,0.4)' ;
  document.getElementById("custom-seekbar1").style.left = 0 + '%';
  
  document.getElementById("fp").style.fill = 'rgb(255,0,0)';
  document.getElementById("fp").style.fillOpacity = 1;

  document.getElementById("bp").style.fill = 'rgb(0,0,0)';
  document.getElementById("bp").style.fillOpacity = .4;
}


document.getElementById("custom-seekbar1").style.height = '3%'

var vcx = document.getElementById("stage")
// 9:16
var hd = this.setVidDim(vcx.offsetWidth, this.props);



  // on timeupdate, update subtitles in the canvas box

document.getElementById('vid1').addEventListener("timeupdate", (e) =>{
// console.log('in add event');
e.stopPropagation();
e.preventDefault();

console.log('TIMEUPDATE')
// set width of subtitle container
  

  var vid = document.getElementById("vid1"); 
  var vCuTi = document.getElementById('vC');
  
  try{
  vCuTi.innerText = miliToMinTime(vid.currentTime*1000) 
  // + '/' + miliToTime(parseFloat(localStorage.getItem("duration"))*1000) ;

} catch {}


try {
//currentTime use second, if you want min *60
var vid = document.getElementById("vid1");

var subs = document.getElementsByClassName("caption")
  

  if (vid.paused) {
    // // console.log('hello');

      var play = document.getElementById("play");
      var pause = document.getElementById("pause");

      play.style.display='inline-block';
      pause.style.display='none';

  }

// debugger
  var c = 0
  for (var idxx=0; idxx<subs.length; idxx++){
    c =c +1;

    var srttime = strToMili(subs[idxx].getAttribute("stime"))/1000
    var endtime = strToMili(subs[idxx].getAttribute("etime"))/1000
  
  if (vid.currentTime >= srttime && vid.currentTime <= endtime) {
    
      try {
      document.getElementsByClassName("current-caption")[0].classList.remove("current-caption")
    } catch(e) {
      console.log(e,'no current caption');
    }

      subs[idxx].classList.add("current-caption")

      var str = subs[idxx].innerText ;

// boxes2[0] is for subtitles
      var text_title = subs[idxx].innerText

      var b = this.props.boxes2[0]

      var text_html = subs[idxx].innerHTML.replace(/\n/g,'<br>')
      text_html = text_html.replace(/&nbsp/g,'').replace(/&nbsp;/g,'')


        var rgba = b.fill
        var fd = rgba.split("(")[1];
        var fdd = fd.split(",");
        

  // not single, there could be multiple spaces!
    // length will be 0 if empty string 
        if (!text_title.replace(/\s/g, '').length) {

        var rgba_ = [parseInt(fdd[0]), parseInt(fdd[1]),parseInt(fdd[2]), 0]

        this.props.update_x(0, 'fill', 'rgba('+rgba_.join()+')')
        // this.props.update_x(0, 'h', 0)

        } else {



        var rgbax = [parseInt(fdd[0]), parseInt(fdd[1]),parseInt(fdd[2]), this.props.boxes2[0].a]



      // boxes2[0].fill = 'rgba(280,100,0,1)'
      // // ctx.clearRect(b.x, b.y, b.w, b.h)
      // boxes2[0].h = 2*marginB + this.TwrapTextDummy(ctx, text_html, 0, 0, b.w - 2*marginB, b.fs + 0.4*marginB, b.fs, b.f)


      this.props.update_x(0, 'fill', 'rgba('+rgbax.join()+')')
      // this.props.update_x(0, 'h', 2*marginB + this.TwrapTextDummy(ctx, text_html, 0, 0, b.w - 2*marginB, b.fs + 0.4*marginB, b.fs, b.f, b.b, b.i))

      }

      // boxes2[0].html = text_html
      // boxes2[0].text = text_title

      this.props.update_x(0, 'html', text_html)
      this.props.update_x(0, 'text', text_title)
      
     this.invalidate()
     break;
    } else {      
// no subtitle that can be shown
    
    var b = this.props.boxes2[0]

    var rgba = b.fill
    var fd = rgba.split("(")[1];
    var fdd = fd.split(",");

    var rgba_ = [parseInt(fdd[0]), parseInt(fdd[1]),parseInt(fdd[2]), 0]

    this.props.update_x(0, 'fill', 'rgba('+rgba_.join()+')')
    // this.props.update_x(0, 'h', 0)

    this.props.update_x(0, 'html', '')
    this.props.update_x(0, 'text', '')
    this.invalidate()
 
    }
  }

} catch {}


})  //end timeupdate eventlistener


}


addTextBox(i) {

  // var i= this.props.boxes2.length - 1
  var id = 'text_' + i.toString()
    // add container to edit the text
  var pa = document.getElementById("textstylx")
  var div = document.createElement("div");
  div.setAttribute('id', id)
  div.setAttribute('class', 'delH')
  pa.append(div)

  var div1 = document.createElement("div");
  div1.addEventListener("click", (e) => {this.deleteText_canvas(e) })
  // onClick={deleteText} 

  div1.innerHTML = `<li class='fa del'>&#xf1f8</li>`
  div.append(div1)


  var div2 = document.createElement("div");
  div2.setAttribute('class', 'xheadx')
  div.append(div2)

  var span = document.createElement("textarea");
  // span.setAttribute('placeholder', 'Enter Text Here');
  span.value = this.props.boxes2[i].text;
  span.setAttribute('class', 'head')
  div2.append(span)

  span.setAttribute('contentEditable',"true");

  span.addEventListener("keyup", (e) => {this.edit_text(e) })

}

  // adds a new node
myDblClick(e) {


    this.getMouse(e);
    // for this method width and height determine the starting X and Y, too.
    // so I left them as vars in case someone wanted to make them args for something and copy this code
    // var width = 100;
    var width = WIDTH;
    var height = 20;

    var fs = 20 //px; 
    var f = 'Oswald'

    console.log('position',mx - (width / 2), my - (height / 2))

    // this.addRect(WIDTH/2 - (width / 2), HEIGHT/2 - (height / 2), width, height, 'rgba(220,5,5,0)', 'Add Text', fs, f, "center", "rgba(0,0,0,1)", "Add Text", 0 );

// back, front
    this.addRect(0, 0, width, height, 'rgba(0,0,0,1)', 'Add Text', fs, f, "center", "rgba(255,255,255,1)", "Add Text", 0 );

    this.invalidate();

    // debugger
  var i= this.props.boxes2.length - 1
  var id = 'text_' + i.toString()
    // add container to edit the text
  var pa = document.getElementById("textstylx")
  var div = document.createElement("div");
  div.setAttribute('id', id)
  div.setAttribute('class', 'delH')
  pa.append(div)

  var div1 = document.createElement("div");
  div1.addEventListener("click", (e) => {this.deleteText_canvas(e) })
  // onClick={deleteText} 

  div1.innerHTML = `<li class='fa del'>&#xf1f8</li>`
  div.append(div1)


  var div2 = document.createElement("div");
  div2.setAttribute('class', 'xheadx')
  div.append(div2)

  var span = document.createElement("textarea");
  span.setAttribute('placeholder', 'Enter Text Here');
  span.setAttribute('class', 'head')
  div2.append(span)

  span.setAttribute('contentEditable',"true");

  span.addEventListener("keyup", (e) => {this.edit_text(e) })

  }


edit_text(e) {

  e.stopPropagation();
  e.preventDefault();
  var z = parseInt(e.target.parentNode.parentNode.id.split('_')[1])

  // boxes2[z].text = e.target.value;
  // boxes2[z].html = e.target.value.replace(/\n/g, '<br>');

  console.log(z,'in myDblClick', this.props, z)

  this.props.update_x(z, 'text', e.target.value)
  this.props.update_x(z, 'html', e.target.value.replace(/\n/g, '<br>'))

// set the box that is being written 
  this.props.selectedText(z)
  // show the selection boxes (9 boxes)
  mySel = this.props.boxes2[z];

  this.invalidate() 

  this.sendJsonToS3(e)

}

invalidate() {
    // canvasValid = false;
    
    this.props.invalidate(false)
  }

  // Sets mx,my to the mouse position relative to the canvas
  // unfortunately this can be tricky, we have to worry about padding and borders
getMouse(e) {
    var element = canvas,
      offsetX = 0,
      offsetY = 0;

    // var element = document.body,
    //   offsetX = 0,
    //   offsetY = 0;

    if (element && element.offsetParent) {
      do {
        offsetX += element.offsetLeft;
        offsetY += element.offsetTop;
      } while ((element = element.offsetParent));
    }

    // Add padding and border style widths to offset
    offsetX += stylePaddingLeft;
    offsetY += stylePaddingTop;

    offsetX += styleBorderLeft;
    offsetY += styleBorderTop;

    mx = e.pageX - offsetX;
    // mx = e.screenX - offsetX;
    my = e.pageY - offsetY

    // console.log('mx', mx, e.pageX)
    
  } //close getMouse


  // Sets mx,my to the mouse position relative to the canvas
  // unfortunately this can be tricky, we have to worry about padding and borders
getMouse_global(e) {
    var element = document.body,
      offsetX = 0,
      offsetY = 0;

    if (element.offsetParent) {
      do {
        offsetX += element.offsetLeft;
        offsetY += element.offsetTop;
      } while ((element = element.offsetParent));
    }

    // Add padding and border style widths to offset
    offsetX += stylePaddingLeft;
    offsetY += stylePaddingTop;

    offsetX += styleBorderLeft;
    offsetY += styleBorderTop;

    mx = e.pageX - offsetX;
    my = e.pageY - offsetY
  } //close getMouse


wrapText(context, text, x, y, maxWidth, lineHeight) {
        var cars = text.split("\n");

        for (var ii = 0; ii < cars.length; ii++) {

            var line = "";
            var words = cars[ii].split(" ");

          //   line = words[0]
          //   if(words.length == 1) {
          //   context.fillText(line, x, y);
          // } else {
          //   line = line + " "
          // }
            
            for (var n = 0; n < words.length; n++) {
                var testLine = line + words[n] + " ";
                var metrics = context.measureText(testLine);
                var testWidth = metrics.width;

                if (testWidth > maxWidth) {
                      context.fillText(line, x, y);
                      line = words[n] + " ";
                      y += lineHeight;
                }
                else {
                    line = testLine;
                }
            }

            context.fillText(line, x, y);
            // y += lineHeight;

            // if( words.length == 1) { } else {
            //   context.fillText(line, x, y);
            //   // if cars is 1 and only 1 word, no point in doing +lineHeight             
            // }
            y += lineHeight; 

        }

        return y

     }


TwrapTextDummy(context, text, x, y, maxWidth, lineHeight, fs, f, b, i) {
        context.font = `${b} ${i} ${fs}px '${f}'`;
        
        // context.fillStyle = 'rgba(0,0,0,0)';

        console.log('DUMMYYY', text)
        var cars = text.split("<br>");
        var org_y = y

        for (var ii = 0; ii < cars.length; ii++) {

            var line = "";
            var words = cars[ii].split(" ");

            for (var n = 0; n < words.length; n++) {
                var testLine = line + words[n] + " ";
                var metrics = context.measureText(testLine);
                var testWidth = metrics.width;

                if (testWidth > maxWidth) {
                      line = words[n] + " ";
                      y += lineHeight;
                }
                else {
                    line = testLine;
                }
            }

            y += lineHeight; 


        }

        return y - org_y - 0.4*marginB

     }



DrawText(context, rect_x, rect_y, rect_w, rect_h, text, fs, f, align, tc, b, i) {

         // var canvas = document.getElementById("imageCanvas");
         // var context = canvas.getContext("2d");

         // context.clearRect(0, 0, 100, 200);
  // ORGINAL CODE HAS CLEARRECT !!!
         context.clearRect(rect_x, rect_y, rect_w, rect_h);

         var maxWidth = rect_w - 2*marginB;
         var lineHeight = fs + 0.4*marginB;

         var x, y;

// for center
if(align == "center") {
         x = rect_x + (rect_w/2)
         y = rect_y + marginB + (fs/2)
}

// for right
if(align == "right") {
         x = rect_x + (rect_w) - marginB
         y = rect_y + marginB + (fs/2)
}

// for left
if(align == "left") {
         x = rect_x + marginB
         y = rect_y + marginB + (fs/2)
}


         // var text = document.getElementById("text").value.toUpperCase();
         // var text = "hello i am alright and and and nad see you on the other sied of the spectrum? yeah? are you thinking"                

         // context.fillStyle = "rgba(255, 0, 0, 1)";
         // context.fillRect(0, 0, 200, 100);

         context.fillRect(rect_x, rect_y, rect_w, rect_h);


// var d = document.createElement("span");
// d.style.font = "15px 'Helvetica'";
// d.textContent = text;
// d.style.maxWidth = maxWidth + 'px';
// d.style.position = 'absolute';
// document.body.appendChild(d);
// var emHeight = d.offsetHeight;
// document.body.removeChild(d);

        
         // this.roundRect(context, rect_x, rect_y, rect_w, rect_h, 5, true);

         context.font = `${b} ${i} ${fs}px '${f}'`;
         context.fillStyle = tc;
         context.textAlign = align;  //left, center, right
         context.textBaseline = "middle";

         var yr = this.wrapText(context, text, x, y, maxWidth, lineHeight);
     }


  // Happens when the mouse is clicked in the canvas
myDown(e) {
    this.getMouse(e);



    //we are over a selection box
    if (expectResize !== -1) {
      isResizeDrag = true;
      return;
    }

    this.clear(gctx);
    var l = this.props.boxes2.length;
    for (var i = l - 1; i >= 0; i--) {
      // draw shape onto ghost context
      this.props.boxes2[i].draw(gctx, 'black');

      // get image data at the mouse x,y pixel
      var imageData = gctx.getImageData(mx, my, 1, 1);
      var index = (mx + my * imageData.width) * 4;

      // if the mouse pixel exists, select it and break loop
      if (imageData.data[3] > 0) {
        // i-th box has been selected

        mySel = this.props.boxes2[i];

// set the Text box i - which has been selected
        this.props.selectedText(i)

        if(i == 0 ) { 
          // document.getElementById('xcolumn').style.display = 'none'
            showSubstyle();
         } else {
            showTextstyle();

            if(document.getElementById("text_" + i)) {

// document.getElementById('textstylx').children[0].id = "text_" + i;
// document.getElementById("text_" + i).children[1].children[0].value = this.props.boxes2[i].text;

            } else {

              this.addTextBox(i)
            }

      }
        // this.props.boxes2[i].text


        // this.getMouse_global(e);
        offsetx = mx - mySel.x;
        offsety = my - mySel.y;
        mySel.x = mx - offsetx;
        mySel.y = my - offsety;
        isDrag = true;

        this.invalidate();
        this.clear(gctx);
        return;
      }

    }
    // havent returned means we have selected nothing
    mySel = null;
    // clear the ghost canvas for next time
    this.clear(gctx);
    // invalidate because we might need the selection border to disappear
    this.invalidate();
  }

deselect(e) {

  // console.log(e, e.currentTarget.contains(canvas), e.target.contains(canvas))

  // if(e.target.id != 'canvas2' && !document.getElementById("xcolumn").contains(e.target) && !document.getElementById("ycolumn").contains(e.target)) {

  //           // havent returned means we have selected nothing
  //   // mySel = null;
  //   // clear the ghost canvas for next time
  //   // this.clear(gctx);
  //   // invalidate because we might need the selection border to disappear
  //   // this.invalidate();

  // } else {


  // }
    //   e.pageX 
    // e.pageY


}

myUp(e) {
    isDrag = false;
    isResizeDrag = false;
    expectResize = -1;

    this.sendJsonToS3(e)
  }



  //Initialize a new Box, add it, and invalidate the canvas
addRect(x, y, w, h, fill, text, fs, f, align, tc, html, a, b="",i="") {
    var rect = new Box2;
    rect.x = x;
    rect.y = y;
    rect.w = w
    rect.h = h;
// the back-color (of rectangle)
    rect.fill = fill;
    rect.text = text;

    rect.fs = fs;
    rect.f = f;
    rect.align = align; 
    rect.tc = tc;
    rect.html = html;
    rect.a = a;
    rect.b = b;
    rect.i = i

// to ensure that height of rect is in coherence with text height (while resizing)
    rect.h = 2*marginB + this.TwrapTextDummy(ctx, html, 0, 0, w - 2*marginB, fs + 0.4*marginB, fs, f, b, i)


    // color of outerline
// rect.strokeStyle = fill;
// rect.fillStyle = fill;
    
    // boxes2.push(rect);

    console.log('In addRect before increment', boxes2)
    
    this.props.increment(rect)

    this.invalidate();
  }




  //wipes the canvas context
  clear(c) {
    c.clearRect(0, 0, WIDTH, HEIGHT);
  }

  // Main draw loop.
  // While draw is called as often as the INTERVAL variable demands,
  // It only ever does something if the canvas gets invalidated by our code
  mainDraw() {

    if (this.props.canvasValid == false) {
      this.clear(ctx);

      try {
      document.getElementById("rangeset").value = document.getElementById('vid1').currentTime

      console.log('in mainDraw', document.getElementById("rangeset").value , document.getElementById('vid1').currentTime)
    } catch {}
      // console.log('boxes2', this.props.boxes2)
      // Add stuff you want drawn in the background all the time here


        var rgba = this.props.boxes2[0].fill;
        var fd = rgba.split("(")[1];
        var fdd = fd.split(",");
        

if (this.props.boxes2[0].text.replace(/\s/g, '') == "") { 

        var rgba_ = [parseInt(fdd[0]), parseInt(fdd[1]),parseInt(fdd[2]), 0]
        this.props.update_x(0, 'fill', 'rgba('+rgba_.join()+')')
    
}
      // draw all boxes
      var l = this.props.boxes2.length;

      for (var i = 0; i < l; i++) {
        this.props.boxes2[i].draw(ctx); // we used to call drawshape, but now each box draws itself
      }

      storeBox(this.props.boxes2)

      // Add stuff you want drawn on top all the time here

      // canvasValid = true;
      this.props.invalidate(true);

      try {
      document.getElementById("rangeset").value = document.getElementById('vid1').currentTime
} catch (e) {}

    }
  }

  // Happens when the mouse is moving inside the canvas
  myMove(e) {
    if (isDrag) {
      this.getMouse(e);

      // this moves box out of canvas on drag
      // this.getMouse_global(e);

      mySel.x = mx - offsetx;
      mySel.y = my - offsety;

      // something is changing position so we better invalidate the canvas!
      this.invalidate();
    } else if (isResizeDrag) {
      // time to resize!

      // current position of 'selection' box 
      // (this could ideally be outside right? CHANGE it)
      var oldx = mySel.x;
      var oldy = mySel.y;

      // 0  1  2
      // 3     4
      // 5  6  7
      switch (expectResize) {
        case 0:
          mySel.x = mx;
          mySel.y = my;
          mySel.w += oldx - mx;
          mySel.h += oldy - my;
          break;
        case 1:
          mySel.y = my;
          mySel.h += oldy - my;
          break;
        case 2:
          mySel.y = my;
          mySel.w = mx - oldx;
          mySel.h += oldy - my;
          break;
        case 3:
          mySel.x = mx;
          mySel.w += oldx - mx;
          break;
        case 4:
          mySel.w = mx - oldx;
          break;
        case 5:
          mySel.x = mx;
          mySel.w += oldx - mx;
          mySel.h = my - oldy;
          break;
        case 6:
          mySel.h = my - oldy;
          break;
        case 7:
          mySel.w = mx - oldx;
          mySel.h = my - oldy;
          break;
      }

      this.invalidate();
    }

    this.getMouse(e);
    // this.getMouse_global(e);
    // if there's a selection see if we grabbed one of the selection handles
    if (mySel !== null && !isResizeDrag) {
      for (var i = 0; i < 8; i++) {
        // 0  1  2
        // 3     4
        // 5  6  7

        var cur = selectionHandles[i];

        // we dont need to use the ghost context because
        // selection handles will always be rectangles
        if (mx >= cur.x && mx <= cur.x + mySelBoxSize &&
          my >= cur.y && my <= cur.y + mySelBoxSize) {
          // we found one!
          expectResize = i;
          this.invalidate();

          switch (i) {
            case 0:
              canvas.style.cursor = 'nw-resize';
              break;
            case 1:
              canvas.style.cursor = 'n-resize';
              break;
            case 2:
              canvas.style.cursor = 'ne-resize';
              break;
            case 3:
              canvas.style.cursor = 'w-resize';
              break;
            case 4:
              canvas.style.cursor = 'e-resize';
              break;
            case 5:
              canvas.style.cursor = 'sw-resize';
              break;
            case 6:
              canvas.style.cursor = 's-resize';
              break;
            case 7:
              canvas.style.cursor = 'se-resize';
              break;
          }
          return;
        }

      }

      // not over a selection box, return to normal
      isResizeDrag = false;
      expectResize = -1;
      canvas.style.cursor = 'auto';
    }

  }


deleteText_canvas(e) {
  // var y = e.target.parentNode.classList.contains("delH") || e.target.parentNode.parentNode.classList.contains("delH")
  
  console.log('deleteText', e.currentTarget, e.currentTarget.parentNode)
  var y ;

  if ( e.currentTarget.parentNode.classList.contains("delH") ) {
    y = e.currentTarget.parentNode
  } else {
    y = e.currentTarget.parentNode.parentNode
  }

  var z = y.id

  z = parseInt(z.split('_')[1])
  console.log('y', y, z)

console.log('BEFORE POP', this.props.boxes2)

  // for(var k=z; k < this.props.boxes2.length-1; k++) {
  //   console.log('before', this.props.boxes2, this.props.boxes2[k], this.props.boxes2[k+1])

  //   // boxes2[k] = this.props.boxes2[k+1]
  //   this.props.edit_boxes(k, this.props.boxes2[k+1])

  //   console.log('after', boxes2,boxes2[k], boxes2[k+1])
  // }


// the last box, delete that box basically
  // boxes2.pop()

  this.props.pop_boxes(parseInt(z))


  console.log('AFTER POP', this.props.boxes2)

  this.invalidate();

  localStorage.setItem('nBox', this.props.boxes2.length)

    // console.log('after pop boxes2', this.props.boxes2.length, this.props.boxes2)

  y.remove()

  var c = document.getElementById('textstylx').children

  for (var k=0; k<c.length; k++) {
    c[k].id = "text_" + (k + 1)
}
  // document.getElementsByClassName("heads-text-container")[0].remove()
  // document.getElementsByClassName("delH")[0].remove()

this.sendJsonToS3(e)

}


/**
 * Draws a rounded rectangle using the current state of the canvas.
 * If you omit the last three params, it will draw a rectangle
 * outline with a 5 pixel border radius
 * @param {CanvasRenderingContext2D} ctx
 * @param {Number} x The top left x coordinate
 * @param {Number} y The top left y coordinate
 * @param {Number} width The width of the rectangle
 * @param {Number} height The height of the rectangle
 * @param {Number} [radius = 5] The corner radius; It can also be an object 
 *                 to specify different radii for corners
 * @param {Number} [radius.tl = 0] Top left
 * @param {Number} [radius.tr = 0] Top right
 * @param {Number} [radius.br = 0] Bottom right
 * @param {Number} [radius.bl = 0] Bottom left
 * @param {Boolean} [fill = false] Whether to fill the rectangle.
 * @param {Boolean} [stroke = true] Whether to stroke the rectangle.
 */
roundRect(ctx, x, y, width, height, radius, fill, stroke) {
  if (typeof stroke === 'undefined') {
    stroke = true;
  }
  if (typeof radius === 'undefined') {
    radius = 5;
  }
  if (typeof radius === 'number') {
    radius = {tl: radius, tr: radius, br: radius, bl: radius};
  } else {
    var defaultRadius = {tl: 0, tr: 0, br: 0, bl: 0};
    for (var side in defaultRadius) {
      radius[side] = radius[side] || defaultRadius[side];
    }
  }
  ctx.beginPath();
  ctx.moveTo(x + radius.tl, y);
  ctx.lineTo(x + width - radius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  ctx.lineTo(x + width, y + height - radius.br);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
  ctx.lineTo(x + radius.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  ctx.lineTo(x, y + radius.tl);
  ctx.quadraticCurveTo(x, y, x + radius.tl, y);
  ctx.closePath();
  if (fill) {
    ctx.fill();
  }
  if (stroke) {
    ctx.stroke();
  }

}

  keytime_2(e) {

      e.preventDefault();
      e.stopPropagation();

  // highlight sub + vid current time + change video text
  console.log('in keytimer 1', e.target);
  console.log('in keytimer 2', e.currentTarget);


        var zz = document.getElementById(0)

        var text_title = zz.innerText

        var b = this.props.boxes2[0]

        var text_html = zz.innerHTML.replace(/\n/g,'<br>').replace(/&nbsp/g,'')
                

        text_html = text_html.replace(/&nbsp;/g,'')

        console.log('HTMLLL keytimer', text_html)

        console.log('before fill in keyitmer', this.props.boxes2)

        var rgba = b.fill

        var fd = rgba.split("(")[1];
        var fdd = fd.split(",");
        
        var alp = fdd[3].split(')')[0]
  // not single, there could be multiple spaces!
    // length will be 0 if empty string 
        if (!text_title.replace(/\s/g, '').length) {

        var rgba_ = [parseInt(fdd[0]), parseInt(fdd[1]),parseInt(fdd[2].split(')')[0]), 0]

        // this.props.update_x(0, 'fill', 'rgba('+rgba_.join()+')')
        this.props.update_x(0, 'h', 0)

        } else {

        var rgbax = [parseInt(fdd[0]), parseInt(fdd[1]),parseInt(fdd[2]), this.props.boxes2[0].a]

        // this.props.update_x(0, 'fill', 'rgba('+rgbax.join()+')')

        try {
        this.props.update_x(0, 'h', 2*marginB + this.TwrapTextDummy(ctx, text_html, 0, 0, b.w - 2*marginB, b.fs + 0.4*marginB, b.fs, b.f, b.b, b.i))
        } catch(e) {
          console.log(e)
        }
        // console.log('after boxes2[0]', boxes2[0], boxes2[0].h)

        }

        console.log('after fill before text in keyitmer', this.props.boxes2)
        // boxes2[0].text = text_title
        // boxes2[0].html = text_html

        this.props.update_x(0, 'text', text_title)
        this.props.update_x(0, 'html', text_html)

        console.log('after text in keyitmer', this.props.boxes2)

// show the 9 selection boxes
        // mySel = this.props.boxes2[0];

        this.invalidate()


    // props.changeKey(1);
    console.log('@@@@@ #### BEFORE storeSub\n', localStorage.getItem('subx'))


// this.sendJsonToS3(e)
}



  keytimer(e) {

      e.preventDefault();
      e.stopPropagation();

  // highlight sub + vid current time + change video text
  console.log('in keytimer 1', e.target);
  console.log('in keytimer 2', e.currentTarget);



    // if (e.target.classList.contains('caption')) {
    if (window.getSelection() && window.getSelection().rangeCount) {
      
      var select = window.getSelection();
      console.log('select', e.target.innerText);

      var range = select.getRangeAt(0);

      if (range.startContainer.parentNode.tagName == "SPAN" && (range.startContainer.parentNode.getAttribute('class') == "caption" || range.startContainer.parentNode.getAttribute('class') == "caption current-caption")) {

      var vid = document.getElementById("vid1");
      if (vid.play) {
      // // console.log('hello');
      vid.pause();
        var play = document.getElementById("play");
        var pause = document.getElementById("pause");

        play.style.display='inline-block';
        pause.style.display='none';
    }

    var zz = window.getSelection().getRangeAt(0).startContainer.parentNode;
    var idn = zz.id;

    
    // var startTime = props.state.strt;
    var vid = document.getElementById("vid1"); 

    var cursorPos = window.getSelection().getRangeAt(0).startOffset; 
    console.log('keytimer time', e.target,'idn', idn,'curpos', cursorPos)
    var t = gettime(idn, cursorPos);
    console.log("t", t);
    vid.currentTime = t/1000;

    if (document.getElementsByClassName("current-caption")[0]) {
    var current = document.getElementsByClassName("current-caption")[0]
 
    current.classList.remove("current-caption");

  }

  zz.classList.add("current-caption") 
  // setAttribute("className", "caption current-caption");
  // zz.setAttribute("class", "caption current-caption");




    // zz.innerText = tx;
    // props.changeSub(newsub)
   // props.changeCaptions(tx)
   // document.getElementById("subtitle-text-container").innerText = zz.innerText;

        var text_title = zz.innerText

        var b = this.props.boxes2[0]

        var text_html = zz.innerHTML.replace(/\n/g,'<br>').replace(/&nbsp/g,'')
                

        text_html = text_html.replace(/&nbsp;/g,'')

        console.log('HTMLLL keytimer', text_html)

        console.log('before fill in keyitmer', this.props.boxes2)

        var rgba = b.fill

        var fd = rgba.split("(")[1];
        var fdd = fd.split(",");
        
        var alp = fdd[3].split(')')[0]
  // not single, there could be multiple spaces!
    // length will be 0 if empty string 
        if (!text_title.replace(/\s/g, '').length) {

        var rgba_ = [parseInt(fdd[0]), parseInt(fdd[1]),parseInt(fdd[2].split(')')[0]), 0]

        this.props.update_x(0, 'fill', 'rgba('+rgba_.join()+')')
        this.props.update_x(0, 'h', 0)

        } else {

        var rgbax = [parseInt(fdd[0]), parseInt(fdd[1]),parseInt(fdd[2]), this.props.boxes2[0].a]

        this.props.update_x(0, 'fill', 'rgba('+rgbax.join()+')')

        try {
        this.props.update_x(0, 'h', 2*marginB + this.TwrapTextDummy(ctx, text_html, 0, 0, b.w - 2*marginB, b.fs + 0.4*marginB, b.fs, b.f, b.b, b.i))
        } catch(e) {
          console.log(e)
        }
        // console.log('after boxes2[0]', boxes2[0], boxes2[0].h)

        }

        console.log('after fill before text in keyitmer', this.props.boxes2)
        // boxes2[0].text = text_title
        // boxes2[0].html = text_html

        this.props.update_x(0, 'text', text_title)
        this.props.update_x(0, 'html', text_html)

        console.log('after text in keyitmer', this.props.boxes2)

// show the 9 selection boxes
        // mySel = this.props.boxes2[0];

        this.invalidate()

    // props.changeKey(1);
    console.log('@@@@@ #### BEFORE storeSub\n', localStorage.getItem('subx'))
    storeSub();

    
  }      
}

// this.sendJsonToS3(e)
}


convertToImage(e, firebase, email_) {

    e.preventDefault();
    e.stopPropagation();



// // user login // this is asynchronus | need to wait | so return
// if(!localStorage.getItem('uid')) { login_in_create(this.props, firebase); return; }

// // Text on button: UPGRADE TO DOWNLOAD
// var s = localStorage.getItem('cn') == "0" || !localStorage.getItem('cn')
// if(localStorage.getItem('uid') && s && localStorage.getItem('render') == "1") {
//     alert('Upgrade to Download the video')
//     // show pricing popup
//     document.getElementById('pvid').style.display = 'block'
//     // add the stripecheckout and return.!!! RENDER THE VIDEO?!
//     return;
//   }

// // if 11 videos have been rendered already
// if(localStorage.getItem('uid') && s && localStorage.getItem('render') == "11") {
//   // reset render value in table and localstorage; if payment successful
//     document.getElementById('pvid').style.display = 'block'
//     alert('Video Limit reached. Please subscribe again, to render this video')
//     return;
//   }

// RENDER AND SUBSCRIBE, you can get from api as opposed to localStorage!?
// reducers will reset on page change/reload, so atleast use it on same page?

document.getElementsByClassName("download_")[0].style.display = 'block'
document.getElementsByClassName("styling")[0].style.display = 'none'


console.log('props in convertToImage', this.props)
var t = JSON.parse(localStorage.getItem('strt'))
var en = JSON.parse(localStorage.getItem('en'))
var subx = JSON.parse(localStorage.getItem('subx'))



var ti = []
var new_s = []
var new_e = []
var vid = document.getElementById('vid1')

for(var k=0; k<t.length; k++) {
  new_s[k] = strToMili(t[k])/1000;
  new_e[k] = strToMili(en[k])/1000;
}


this.clear(ctx);

// draw all boxes
var l = this.props.boxes2.length;

for (var i = 1; i < l; i++) {
  this.props.boxes2[i].draw(ctx); 
  }


var s = {}
s['strt'] = 0
s['en'] = localStorage.getItem("duration");
var image = canvas.toDataURL("image/png")
s['image'] = image

console.log('SSS', s)
ti.push(s)

// for(var k=0; k<t.length; k++) {

var k = 0

while(k < t.length) {

        var text_title = subx[k]

        var b = this.props.boxes2[0]
        var text_html = subx[k].replace(/\n/g,'<br>').replace(/&nbsp/g,'')

        var rgba = b.fill

        var fd = rgba.split("(")[1];
        var fdd = fd.split(",");
        
        var alp = fdd[3].split(')')[0]
  // not single, there could be multiple spaces!
    // length will be 0 if empty string 
        if (!text_title.replace(/\s/g, '').length) {

        var rgba_ = [parseInt(fdd[0]), parseInt(fdd[1]),parseInt(fdd[2].split(')')[0]), 0]

        this.props.update_x(0, 'fill', 'rgba('+rgba_.join()+')')
        this.props.update_x(0, 'h', 0)

        } else {

        var rgbax = [parseInt(fdd[0]), parseInt(fdd[1]),parseInt(fdd[2]), this.props.boxes2[0].a]

        this.props.update_x(0, 'fill', 'rgba('+rgbax.join()+')')
        this.props.update_x(0, 'h', 2*marginB + this.TwrapTextDummy(ctx, text_html, 0, 0, b.w - 2*marginB, b.fs + 0.4*marginB, b.fs, b.f, b.b, b.i))
        
        // console.log('after boxes2[0]', boxes2[0], boxes2[0].h)

        }


        this.props.update_x(0, 'text', text_title)
        this.props.update_x(0, 'html', text_html)

        this.clear(ctx);

        // draw all boxes
        var l = this.props.boxes2.length;

        for (var i = 0; i < l; i++) {
          this.props.boxes2[i].draw(ctx); 
          }


  s = {}
  s['strt'] = new_s[k]
  s['en'] = new_e[k]
  var image = canvas.toDataURL("image/png")
  s['image'] = image

  // console.log('SSS', s)
  ti.push(s)
          
          // .replace("image/png", "image/octet-stream");

  console.log('IMAGE', ti)

  k = k + 1

}
// window.open(canvas.toDataURL('png'));
this.invalidate();  

var filename = localStorage.getItem("filename")
var xout = Date.now() + '_' + filename


//  nh nw
var nh = parseInt(document.getElementById("custom-seekbar11").getAttribute("nh"));
var nw = parseInt(document.getElementById("custom-seekbar11").getAttribute("nw"));

var seekh = 0.03*nh
var bck = document.getElementById("custom-seekbar1").style.backgroundColor
bck = gethex(bck)
var frnt = document.getElementById("custom-seekbar11").style.backgroundColor
frnt = gethex(frnt)

// padding color 
var pc = document.getElementById("vid").style.backgroundColor
pc = gethex(pc)
var width = nw
var height = nh
var back_bar = bck
var front_bar = frnt
var bar_height = Math.ceil(seekh)
var duration = localStorage.getItem("duration")

console.log('pc', pc)

pc = pc.split('#')[1]
back_bar = back_bar.split('#')[1]
front_bar = front_bar.split('#')[1]

var dvid = 'https://d2wxuz9jomxjzh.cloudfront.net/' + xout + '?cachebuster=' + Date.now()

localStorage.setItem('xout', xout)

this.props.render_x('filename', filename)
this.props.render_x('xout', xout)
this.props.render_x('width', width)
this.props.render_x('height', height)
this.props.render_x('pc', pc)
this.props.render_x('back_bar', back_bar)
this.props.render_x('front_bar', front_bar)
this.props.render_x('bar_height', bar_height)
this.props.render_x('duration', duration)

this.props.render_x('dvid', dvid)


if (localStorage.getItem('uid')) {
    var suburl = 'https://mxfrnd93z6.execute-api.us-west-2.amazonaws.com/default/read_subscribe_table?email=' + localStorage.getItem('email')

    console.log('suburl', suburl)

    axios.get(suburl).then(response => {

      this.props.render_x('pre_sub', response.data.Item.count.S)
  
    // if the item doesnt exist in subscribe table
    }).catch(e => {

      this.props.render_x('pre_sub', '0')
    })

    console.log('?? subscribe in Renderdown', this.props.pre_sub)
}


// if pre_sub is 1, no watermark is required

this.props.render_x('ti', JSON.stringify(ti))

// if no boxes on canvas, => no rendering return
if(ti == '[]') return;


var ucp = localStorage.getItem('uid') + '-' + filename.split('.')[0]+ + '-' + filename.split('.')[1]
if(!ucp) {ucp = localStorage.getItem('uuid') + '-' + filename.split('.')[0] + + '-' + filename.split('.')[1]}

// no watermark
var url = 'https://zuy32xbxsg.execute-api.us-west-2.amazonaws.com/default/canvas_render?filein=' + filename + '&fileout=' + xout + '&width=' + width + '&height=' + height + '&pc=' + pc + '&back_bar=' + back_bar + '&front_bar=' + front_bar + '&bar_height=' + bar_height + '&duration=' + duration

console.log('url', url)

var t = parseFloat(localStorage.getItem('duration'))

var urlx = 'https://cbrwnvuuf6.execute-api.us-west-2.amazonaws.com/default/check_output_video?key=' + xout

// generating two subtitles file
document.getElementById('an1').click()
get_srt(e, '1')

document.getElementById('an2').click()
get_srt(e, '2')


axios.post(url, ti,{
    headers: {
            'Content-Type': 'application/json',
          },
    }).then((res) => { 
      console.log('SENT')

      var update_count = 'https://yob6q3b5fi.execute-api.us-west-2.amazonaws.com/default/update_subscribe_table?email=' + email_

      axios.get(update_count).then((res) => {
        localStorage.removeItem('cn')
        console.log('updated count')
    })
      .catch(e => console.log(e))


      document.getElementsByClassName("download_")[0].style.display = 'none'


// renderdown.js
// this.props.props.x.history.push({
//      pathname: `/download/${ucp}`,
//      // state: { download: xyzu }
//  })

  navigate(`/download/?d=${ucp}`)



    }).catch((err) => {
      console.log('ERROR',e)


if (t > 60) { t = t*0.5*1000 }
else { t = t*1000 }

var vid_gen = 0

var vix = setInterval(() => {

  if (vid_gen == 1) { 
    // incorrect link
    urlx = 'https://cbrwnvuuf6.execute-api.us-west-2.amazonaws.com/default/check_output?key=' + xout
}
    

    // check if cloudfront has the video
    axios.get(urlx).then(res=> {
      console.log('VIDEO GENERATED NOW', res)

      vid_gen = 1
      console.log('vix', vix)
      clearInterval(vix)


      console.log('vix', vix)



      console.log('before navigate 1', urlx)


      try {
      document.getElementsByClassName("download_")[0].style.display = 'none'
      document.getElementsByClassName("styling")[0].style.display = 'flex'
      } catch(e) {console.log(e)}


      // renderdown.js
     //  this.props.props.x.history.push({
     //     pathname: `/download/${ucp}`,
     //     // state: { download: xyzu }
     // })
     

// update count !!!
      var update_count = 'https://yob6q3b5fi.execute-api.us-west-2.amazonaws.com/default/update_subscribe_table?email=' + email_

      axios.get(update_count).then((res) => {

        localStorage.removeItem('cn')
        console.log('updated count')

    })
      .catch(e => console.log(e))  //update_count axios


    console.log('before navigate 2')
    navigate(`/download/?d=${ucp}`)


    }).catch(err=> console.log('ERRORR! video not generated - in setInterval', err)) //close urlx
}, t) //close interval


    })
  console.log('## IMAGES ##', ti)
}



setVidDim (width, props) {

var c = document.getElementById("vid")
var g = (c.offsetWidth/c.offsetHeight).toFixed(1)
// var g = (props.state.width/props.state.height).toFixed(1)

// var vcx = document.getElementById("stage");
// var vidx = document.getElementById("vid1");
var head_fs, head_fs_new, sub_fs, sub_fs_new

var r, nh, nw, bar_height, bar_height_new, head_pad
if ( g == "0.6") {
//  9:16 w=1080, h=1920

nw = 1080; nh=1920;
document.getElementById("ar").textContent = "9:16";
console.log('def width', width, width/1080);
r = 1080/width;

}

else if (g == "1.8") {
  // 16:9 w=1920, h=1080

nw=1920; nh=1080;
document.getElementById("ar").textContent = "16:9";
console.log('def width', width, width/1920);
r = 1920/width;

}


else if (g == "1.0") {
  // 1:1 w=1080, h=1080

nw=1080; nh=1080;
r = 1080/width;
document.getElementById("ar").textContent = "1:1";
console.log('def width', width, width/1080);

}

else if(g == "0.8"){
  // 4:5 w=1080, h=1350 

nw=1080; nh=1350;
r = 1080/width;
document.getElementById("ar").textContent = "4:5";
console.log('def width', width, width/1080);

}

else {

// original w=1080 h=810  (4:3)
document.getElementById("ar").textContent = "Org";
console.log('def width', width, width/1080);
r = 1080/width;
var height = document.getElementById("stage").offsetHeight;

nw = 1080; nh = height*r

}

// store ratio in span-custom-seekbar11
document.getElementById("custom-seekbar11").setAttribute("ratio", r);
document.getElementById("custom-seekbar11").setAttribute("nw", nw);
document.getElementById("custom-seekbar11").setAttribute("nh", nh);



return [20, 20]

}


// ////////////////////////////////////////////////

componentDidMount() {

      // const firebase = useFirebase() ;
      const firebase = getFirebase();

  // initialize our canvas, add a ghost canvas, set draw loop
  // then add everything we want to intially exist on the canvas
  
  document.getElementById("addT").addEventListener("click", (e) => {

    e.stopPropagation();
    e.preventDefault();

    console.log('myDblClick')

    this.myDblClick(e)

    console.log('new boxes2', this.props.boxes2)
  })

  var key = document.getElementById("subdiv");


// key timer was being fired 100 times on clicking 'lang' because of this?
  // key.addEventListener("DOMSubtreeModified", (e) => this.keytimer(e));

  document.getElementById("an").addEventListener("click", (e) => {
    this.keytime_2(e)
  })

  key.addEventListener("click", (e) => {
    console.log('keytimer click')
    this.keytimer(e)
    // setWidth(2);

    // console.log('clinet', e.clientY, e.pageY)
    
    try {
    // document.getElementById("addSubz").removenext(document.getElementById("plus"))
    document.getElementById("plus").remove();
  } catch {
    console.log("no child");
  }


  function getpos(e) {
  var posx = 0;
  var posy = 0;
  if (!e) var e = window.event;
  if (e.pageX || e.pageY)   {
    posx = e.pageX;
    posy = e.pageY;
  }
  else if (e.clientX || e.clientY)  {
    posx = e.clientX + document.body.scrollLeft
      + document.documentElement.scrollLeft;
    posy = e.clientY + document.body.scrollTop
      + document.documentElement.scrollTop;
  }
  return [posx, posy]
}
    document.getElementById("addSubz").style.marginTop = getpos(e)[1] - 190 + 'px';
    // document.getElementById("addSubz").style.marginRight = -200 + 'px';


    var xp = document.getElementById("addSubz").getBoundingClientRect();

      var cy = getpos(e)[1]  ;
      var color = 'rgba(255,0,255,0.3)';
      var thickness = 1;
      var cx = e.clientX;

      
      var xx = xp.left + xp.width/3
      var length = xx - cx  
      var htmlLine = "<div id=plus style='padding:0px; margin:0px; height:" + thickness + "px; background-color:" + color + "; line-height:1px;left:" + cx + "px; position: fixed; top:" + cy + "px; width:" + length + "px' />";
    //
    // alert(htmlLine);
    // document.getElementById("addSubz").innerHTML += htmlLine;
    document.getElementsByClassName("tooltip")[0].insertAdjacentHTML('afterend', htmlLine);

    console.log('HELLOOO')
  });


  document.body.addEventListener("keyup", (e) => {
      console.log('target id', e.target.id, e)
      
      if (e.target.id == "subdiv" || e.target.parentNode.id == "subdiv") {
        this.keytimer(e); 
        return;
      }
})






  // If you dont want to use <body onLoad='init()'>
  // You could uncomment this init() reference and place the script reference inside the body tag
  //init();
  // window.init2 = init2;S
// })(window);

// Andy added, as a replacement for 
// <body onLoad="init2()">
// $(document).ready(function() {
//   // Your code here
  
console.log('init',this.props)
if ( this.props.props.state.width ) {
  var g = (this.props.props.state.width/this.props.props.state.height).toFixed(1)

} else {
  console.log('local storage', localStorage.getItem("width"), localStorage.getItem("height"), localStorage.getItem("duration"))
  var g = parseFloat(localStorage.getItem("width"))/parseFloat(localStorage.getItem("height"))

  try {
  g = g.toFixed(1)
  console.log('localgg', g);
} catch {}
}

  setDim(g, 'xyz')


  this.invalidate();
  this.init2();


window.addEventListener('resize', (e)=>{
  if(window.location.href.split('/')[3] === 'vidx') {
      this.setVideo();
  } 
});

// change Aspect Ratio
//var r = 0
//localStorage.setItem('r', '0')

document.getElementById('aratio').addEventListener("click", (e) => {

    // this.props.pop_boxes(1);
    // localStorage.setItem('nBox', this.props.boxes2.length)

    console.log('change aration')
//    if(localStorage.getItem('r') == '0') r='1:1'
//    else r=0
    
    this.setARatio(e);
    
//    localStorage.setItem('r', '')
    
})

//to set aspect ratio as 'org' so that seekbar is visible
document.getElementById('aratio').click()


document.getElementById("downloadx").addEventListener("click", (e) => {

    e.stopPropagation();
    e.preventDefault();


document.getElementsByClassName("download_")[0].style.display = 'block'
document.getElementsByClassName("styling")[0].style.display = 'none'



    console.log('this.props',this.props)

var get_email = 'https://hps0wyaacf.execute-api.us-west-2.amazonaws.com/default/read-turtleclip-store-email' + '?uid=' + localStorage.getItem('uid') 

        axios.get(get_email).then(res => {

            console.log('email fetched', res)
          // // props.render_x('subscribe', response.data.Item.sub.S)


// check if the user has counts remaining
// what if someone changes localStorage email to something else
var geturl = 'https://mxfrnd93z6.execute-api.us-west-2.amazonaws.com/default/read_subscribe_table?email=' + res.data

var email_ = res.data
console.log('geturl', geturl)
axios.get(geturl).then(res => {

       var cn = res.data.Item.count.S
      cn = parseInt(cn)
  
  //  if user data

    if(cn > 0 ) {

    this.convertToImage(e, firebase, email_)
  } else {

    document.getElementsByClassName("download_")[0].style.display = 'none'
    document.getElementsByClassName("styling")[0].style.display = 'flex'

  }

}).catch((e) => {
  console.log(e)
    document.getElementsByClassName("download_")[0].style.display = 'none'
    document.getElementsByClassName("styling")[0].style.display = 'flex'

})

        }).catch((e) => {
        console.log(e)

        document.getElementsByClassName("download_")[0].style.display = 'none'
        document.getElementsByClassName("styling")[0].style.display = 'flex'

        })











});


} // close componentDidMount

render () {

    // <video id="can" height='300px' width='400px' src={pop} controls />

// Map Redux state to React component props

// height='450px' width='300px'
// container3 is for subtitles

// <a id="download" style={{'left':'330px', 'position':'absolute'}} download="image.png"><button type="button">Download</button></a>

return (
  <div>
    <div id="container2">
      <canvas id="canvas2" >
      This text is displayed if your browser does not support HTML5 Canvas.
      </canvas>
    </div>
  </div>
)
}
}



const mapStateToProps = (state) => {
  console.log('!!!mapStateToProps', state)
  return {
  boxes2: state.data.boxes2,
  canvasValid: state.data.canvasValid,
  selectText: state.data.selectText,

  xout: state.data.xout,
  width: state.data.width,
  height: state.data.height,
  pc: state.data.pc,
  back_bar: state.data.back_bar, 
  front_bar: state.data.front_bar,
  bar_height: state.data.bar_height,
  duration: state.data.duration,
  dvid: state.data.dvid,
  pre_sub: state.data.pre_sub,
  ti: state.data.ti,

  render: state.data.render,
  subscribe: state.data.subscribe,
  uid: state.data.uid,
  email: state.data.email,
  pic: state.data.pic,
  filename: state.data.filename,
  
}}



Canvastry.displayName = "Canvastry";
export default connect(mapStateToProps, { increment, update_x, edit_boxes, pop_boxes, invalidate, selectedText, render_x, decrement, reset })(React.memo(Canvastry));


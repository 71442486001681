import React, { useEffect } from 'react';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import RefreshIcon from '@material-ui/icons/Refresh';
import './div_ticker.css';

import {setTickerState} from './utils';

import {increaseT, decreaseT} from './changeTime';

function TickerTime(props){


useEffect(() => {
	// console.log('ticker of props',props.tickid, props.posX, props.posY);

	var h = document.getElementById("rows").getBoundingClientRect().height //in px
	var tik = document.getElementById("rows");
	tik.style.left = props.posX - document.getElementById("vcolumn").getBoundingClientRect().width +"px";
	tik.style.top = props.posY +"px";

	// document.getElementById("rows").getBoundingClientRect().height

	tik.setAttribute("tickerId", props.tickid);

	// var pp = props.target;
	try {
		var x = document.getElementById(props.tickid).getAttribute("stime");	
		console.log('in tick x', x);
	} catch {
		var x = "00:00.0";	
	}
	
 	var a = x.split(':')[0].padStart(2,0);
 	var b = x.split(':')[1].split('.')[0].padStart(2,0);
 	var c = x.split(':')[1].split('.')[1].padStart(1,0);

 	document.getElementById("zero").childNodes[1].innerHTML = a;
 	document.getElementById("one").childNodes[1].innerHTML = b;
 	document.getElementById("two").childNodes[1].innerHTML = c;

})
	

useEffect(() => { 

	// var x = document.getElementsByClassName("up");



	var x = document.getElementsByClassName("up")

	for (var i=0; i< x.length; i++) {
		var icon = x[i];
		// console.log(icon.parentNode)
		var a=59,b=59,c=9;  //c == c*100
		var MaxAllowedTime = a*60*1000 + b*1000 + c*100;


		icon.addEventListener("click", (e) => {
			// console.log("props.target",props.posX, props.tickid)
			e.preventDefault();
    		e.stopPropagation();
			increaseT(e) ;
			setTickerState(document.getElementById("rows").getAttribute("tickerId"));
		})
	}


	var x = document.getElementsByClassName("down")

	for (var i=0; i< x.length; i++) {
		var icon = x[i];
		var MinAllowedTime = 0;
		icon.addEventListener("click", (e) => {
			// console.log("props.target", props.tickid);
			e.preventDefault();
    		e.stopPropagation();
			decreaseT(e);
			setTickerState(document.getElementById("rows").getAttribute("tickerId"));
		})
	}

	document.getElementById("delete").addEventListener("click", (e) => {

		var tId = document.getElementById("rows").getAttribute("tickerId");
		// first and last children shouldnt get deleted
		// var gPa = document.getElementById("subdiv").children
		// if (tId == gPa[1].id || tId == gPa[gPa.length-1].id) {
		// 	return;
		// }

		// console.log(tId);

		var x = document.getElementById(tId)
		var z = x.previousSibling;
		// console.log('xxx', x, z, x.innerText);  //z = get div caption-break > bt6
		// delete both x, z

		// after the element 
		// x.insertAdjacentText("afterend", x.innerText);
		z.previousSibling.innerText = z.previousSibling.innerText + ' ' + x.innerText
		z.previousSibling.setAttribute("etime", x.getAttribute("etime"));

		var parent = x.parentNode;
		console.log('parent', parent,'parent previous text', z.previousSibling);  //span id
		parent.removeChild(x);
		parent.removeChild(z);

	})

}, [])

	return (
<div id="rows" tickerId="0">

<div id= "row1">
  
 <div id="zero" className="col1">
 	<ArrowDropUpIcon className="up" color="secondary" style={{ fontSize: 35 }} /> 
	<span>01</span>
	<ArrowDropDownIcon className="down" color="secondary" style={{ fontSize: 35 }} />
 </div>

 <div className="col1">:</div>

 <div id="one" className="col1">
	<ArrowDropUpIcon className="up" color="secondary" style={{ fontSize: 35 }}/> 
 	<span>00</span>
 	<ArrowDropDownIcon className="down" color="secondary" style={{ fontSize: 35 }}/>
 </div>
 

 <div className="col1">:</div>

 <div id="two" className="col1">
	<ArrowDropUpIcon className="up" color="secondary" style={{ fontSize: 35 }} />
	<span>0</span>
	<ArrowDropDownIcon className="down" color="secondary" style={{ fontSize: 35 }}/>
 </div>

</div>

<div id="row2">


	<div className="col2">
		<DeleteForeverIcon id="delete" color="secondary" fontSize="medium" />
	</div>


</div>

</div>

		)
}


TickerTime.displayName = "TickerTime";
export default React.memo(TickerTime);

import React, { useEffect } from 'react';
import {strToMili, rgbToHex} from './utils';
import {trim, getrgb, gethex} from './utilsave.js';


import CircularProgress from '@material-ui/core/CircularProgress';

import './downloadvid.css'

import axios from 'axios'; 

/* global $ */

//  add multiple heads-text, their width, subtitle width, seekbar width
//  video position and dimensions!


//use useState to store the states,transfer it to state on page change

function Save(props){

  // localStorage.setItem('render', '0')

 //   var render_count = 'https://sz2oarqpyd.execute-api.us-west-2.amazonaws.com/default/add_render?uid=' + localStorage.getItem('uid') + '&render=' + '0'; 
 // axios.get(render_count)


  // console.log('props in SAVE', props.canv.render_count)

  useEffect(() => {

var uid = localStorage.getItem("uid")
var email = localStorage.getItem("email")

// var geturl = 'https://mxfrnd93z6.execute-api.us-west-2.amazonaws.com/default/read_subscribe_table?email=' + localStorage.getItem('email')
// console.log('geturl', geturl)

// axios.get(geturl).then(res => {

//        var cn = res.data.Item.count.S
//       cn = parseInt(cn)
  
//   //  if user data

//     if(!cn || cn == 0 ) {
//             localStorage.setItem('cn', 0)
//             document.getElementById('save').innerText = 'UPGRADE'
//             document.getElementById('save').style.display = 'block';
//             document.getElementById('save').style.color = 'black';
//             document.getElementById('save').style.backgroundColor = 'yellow';
//             document.getElementById('save').style.borderColor = 'yellow';
//       } else {

//             localStorage.setItem('cn', cn)

//             document.getElementById('save').innerText = 'CREATE!' 
//             document.getElementById('save').style.display = 'block';
//       }

 
// }).catch(err => { 

//     console.log(err)

//     localStorage.setItem('cn', 0)

//     document.getElementById('save').innerText = 'UPGRADE' 
//     document.getElementById('save').style.display = 'block';
// })





var cn = localStorage.getItem('cn')

cn = parseInt(cn)
  
  //  if user data

if(!cn || cn == 0 ) {
            localStorage.setItem('cn', 0)
            document.getElementById('save').innerText = 'UPGRADE'
            document.getElementById('save').style.display = 'block';
            document.getElementById('save').style.color = 'black';
            document.getElementById('save').style.backgroundColor = 'yellow';
            document.getElementById('save').style.borderColor = 'yellow';
      } else {

            document.getElementById('save').innerText = 'CREATE!' 
            document.getElementById('save').style.display = 'block';
}

 


//  add file to user row in table

},[])


// onClick={(e)=> saveState(e,props)}


// onClick={(e)=> convertToImage()}

// <a id="downloadx" onClick={(e)=> convertToImage()} download="image.png"><button id="save" type="button" className="btn btn-default" data-toggle="tab">Create!</button> </a>


return (
<div>



<a id="downloadx" download="image.png"><button id='save' type="button">CREATE!</button></a>


<div className="download_">
  <div className="text_download_">Generating Video. Please wait. <br/>Might take a few minutes, depending upon the length of the video</div>
  <CircularProgress className="Mbar" />
</div>

</div>
		)
}


Save.displayName = "Save";
export default React.memo(Save);


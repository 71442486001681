
import React, { useEffect } from 'react';
// import './colors2.css';
/* global $ */
import {setColor, setSaturationColor, hexToRgb,rgbToHex, getHsl, hslToRgb , hslToHex, setXY} from './utils';



function getColors2(event, parent, d) {
        
        var offset = event.currentTarget.getBoundingClientRect();  
	    var left = (event.clientX - offset.left);
	    var totalWidth = offset.width;
	    var percent = ( left / totalWidth )*100;



        // set the pointer position in hue bar
        $(".vc-hue-picker").css("margin-left", left);
        // console.log('vc-hue-picker', percent);

        // store the hue value to be passed on
        $(".vc-hue-pointer").css("left", percent);

        var h = $(".vc-hue-pointer").css("left");  //20px
    	var y = $(".vc-saturation-circle").css("top") //20px
    	var x = $(".vc-saturation-circle").css("left") //20px


    	h = h.split("px")[0];
    	x = x.split("px")[0];
    	y = y.split("px")[0];

		// if ( parseFloat(x)>99) {
		// 	x = 99;
		// }
		// if ( parseFloat(y)>99) {
		// 	y = 99;
		// }

    	var hsl = getHsl(h,x,y);
    	var rgb = hslToRgb(hsl);
    	if (rgb==2) {
    		return;
    	}
    	console.log('!!!hue',rgb, h,x,y);
    	var hex = hslToHex(hsl);

    	var result = setSaturationColor(percent);

    	// set the saturation chart color
        $(".vc-saturation").css("background", 'rgb('+result.join()+') none repeat scroll 0% 0%');


	    $("#hex").val(hex);
		$("#r").val(Math.round(rgb[0]));
		$("#g").val(Math.round(rgb[1]));
		$("#b").val(Math.round(rgb[2]));

        var a = $("#a").val();

        //set bg button color
        parent.style.fill = 'rgb('+rgb.join()+')';
        parent.style.fillOpacity = a;

        rgb.push(a)


        // console.log('rgba', alf, rgb);
    	 // set the active color
        $('.vc-sketch-active-color').css("background", 'rgb('+rgb.join()+') none repeat scroll 0% 0%');


        setColor(d, parent, rgb);

    }
// });

function getSaturationColor(e, parent, d) {

	console.log('boundingbox', e.currentTarget)
	var offset = e.currentTarget.getBoundingClientRect();  
    var left = (e.clientX - offset.left);
    var totalWidth = offset.width;
    var percent = ( left / totalWidth )*100;


    var top = (e.clientY - offset.top);
    var totalHeight = offset.height;
    var topcent = ( top / totalHeight )*100;
    console.log('sat circle X', e.pageX, e.clientX, offset.left, offset.width);
    console.log('sat circle Y', e.pageY, e.clientY, offset.top, offset.height);
// to move the pointer //left and top values are out of boundary
    $(".vc-saturation-pointer").css("left", left);
    $(".vc-saturation-pointer").css("top", top);
// to store the correct x,y values
    $(".vc-saturation-circle").css("left", percent);
    $(".vc-saturation-circle").css("top", topcent);

    console.log('set circle position:', left, top, percent, topcent)
    console.log('set circle position:', $(".vc-saturation-pointer").css("left"), $(".vc-saturation-pointer").css("left"), $(".vc-saturation-circle").css("left"), $(".vc-saturation-circle").css("top"));

    var h = $(".vc-hue-pointer").css("left");  //20px
    var y = $(".vc-saturation-circle").css("top") //20px
    var x = $(".vc-saturation-circle").css("left") //20px

   	h = h.split("px")[0];
	x = x.split("px")[0];
	y = y.split("px")[0];

	if ( parseFloat(x)>99) {
		x = 99;
	}
	if ( parseFloat(y)>99) {
		y = 99;
	}

	var hsl = getHsl(h,x,y);
	var rgb = hslToRgb(hsl);  //array
	if (rgb==2) {
    		return;
    	}
    console.log('!!!sat',rgb, h,x,y);
	var hex = hslToHex(hsl);

	// set the saturation chart color
     // $(".vc-saturation").css("background", 'rgb('+rgb.join()+') none repeat scroll 0% 0%');

	$("#hex").val(hex);
	$("#r").val(Math.round(rgb[0]));
	$("#g").val(Math.round(rgb[1]));
	$("#b").val(Math.round(rgb[2]));

    // var alf = $("#a").val();
    // rgb.push(parseFloat(alf))
    var a = $("#a").val();

    parent.style.fill = 'rgb('+rgb.join()+')';
    parent.style.fillOpacity = a;

    rgb.push(a);
	// set the active color
    $('.vc-sketch-active-color').css("background", 'rgb('+rgb.join()+') none repeat scroll 0% 0%');

    // console.log('rgba', rgb, alf);
    // console.log('e parent', e, parent);
    
    setColor(d, parent, rgb);

}

function alphaChange(e, parent, d) {
	var a = document.getElementById("a").value;

		// these rgb values will be int
	var r = document.getElementById("r").value;
	var g = document.getElementById("g").value;
	var b = document.getElementById("b").value;


	var rgba = [r,g,b,a];


    // rgb.push(parseFloat(a));
    
    // var hex = rgbaToHex('rgba('+rgb.join()+')');
   	// $("#hex").val(hex);

    // set the active color
    $('.vc-sketch-active-color').css("background", 'rgba('+rgba.join()+') none repeat scroll 0% 0%');

    // console.log('e parent', e, parent);
// set parent color

    parent.style.fill = 'rgb('+[r,g,b].join()+')';
    parent.style.fillOpacity = a;

    setColor(d, parent, rgba);
    // parent.style.fillOpacity = a;

}

function RgbColorChange(e, parent, d) {

	// these rgb values will be int
	var r = document.getElementById("r").value;
	var g = document.getElementById("g").value;
	var b = document.getElementById("b").value;
	var a = $("#a").val();


	setXY([r,g,b]);


	// var rgba = [r,g,b, alf];

    // set the saturation chart color
    // $(".vc-saturation").css("background", 'rgb('+rgb.join()+') none repeat scroll 0% 0%');


    var hex = rgbToHex(r,g,b);

	console.log('rgb',r,g,b, hex);

	document.getElementById("hex").value = hex;
    		// set the active color
    $('.vc-sketch-active-color').css("background", 'rgba('+[r,g,b,a].join()+') none repeat scroll 0% 0%');


    // console.log('e parent', e, parent);
// set parent color
    parent.style.fill = 'rgb('+[r,g,b].join()+')';
    parent.style.fillOpacity = a;
    setColor(d, parent, [r,g,b,a]);

 }

function setPaletteColor (e, parent, d) {
	var bg = e.target.style.backgroundColor

	var bgc = bg.split('(')[1].split(",")
	var r = bgc[0];
	var g = bgc[1];
	var b = bgc[2].split(')')[0]
	// var a = 1;

	var a = $("#a").val();
	
	$('.vc-sketch-active-color').css("background", bg);

	parent.style.fill = 'rgb('+[r,g,b].join()+')';
    parent.style.fillOpacity = a;
    setColor(d, parent, [r,g,b,a]);

    var hex = rgbToHex(r,g,b);

	document.getElementById("hex").value = hex;
	document.getElementById("r").value = r;
	document.getElementById("g").value = g;
	document.getElementById("b").value = b;
	


}
function hexColorChange(e, parent, d) {

	var hex = document.getElementById("hex").value;

    console.log('hex:', hex);

	var rgb = hexToRgb(hex);
	if (rgb == 2) { //if r/g/b/ is NaN
		return;
	}

	setXY(rgb);

	document.getElementById("r").value = rgb[0];
	document.getElementById("g").value = rgb[1];
	document.getElementById("b").value = rgb[2];



    // var alf = $("#a").val();
    // rgb.push(parseFloat(alf))

    // console.log('e parent', e, parent);
	
	var a = $("#a").val();

	parent.style.fill = 'rgb('+rgb.join()+')';
	parent.style.fillOpacity = a;

	rgb.push(a);
   	// set the active color
    $('.vc-sketch-active-color').css("background", 'rgba('+rgb.join()+') none repeat scroll 0% 0%');

// set parent color
    setColor(d, parent, rgb);

 }

function Colors2(props){	
// pass the bg color parent. [rgb] 
// set hex,r,g,b [rgbToHex], hue, saturationChart color
// change closeColor parent if-else
// set posX posY 

	useEffect(() => {

		// props.color
	if (props.target) {
		var parent = props.target;


		var rgb = parent.style.fill;
		var alf = parent.style.fillOpacity;
		var d = rgb.split("(")[1];
		var dd = d.split(",");
		console.log(dd)

		var rgb =  [parseInt(dd[0]), parseInt(dd[1]),parseInt(dd[2].split(')')[0])]
		console.log('props.target', parent, 'rgb', rgb);
		
		$("#r").val(rgb[0]);
		$("#g").val(rgb[1]);
		$("#b").val(rgb[2]);
		$("#a").val(alf);

		console.log('alpha', $("#a").val());
		// var rgba = [parseInt(dd[0]), parseInt(dd[1]),parseInt(dd[2].split(')')[0]), alf]

		var hex = rgbToHex(rgb[0],rgb[1],rgb[2]);

		$("#hex").val(hex);

		var rgba = [parseInt(dd[0]), parseInt(dd[1]),parseInt(dd[2].split(')')[0]), alf]
		// set the active color
    	$('.vc-sketch-active-color').css("background", 'rgba('+rgba.join()+') none repeat scroll 0% 0%');

		// setXY(rgb);

	    setXY(rgb);


	} else {
		var parent = 'none';
		console.log('else props.target', parent)
	}
	
	},[props.target])


return (
	<div id="ycolumn">
	 

	 <div className="relative">
	  <div className="color-picker mt-1">
	  <div role="application" aria-label="Sketch color picker" className="w-full vc-sketch">
	  <div onClick={(e) => getSaturationColor(e,props.target, props.d)} className="vc-sketch-saturation-wrap">
	  <div className="vc-saturation" style={{ background: 'rgb(255, 0, 0) none repeat scroll 0% 0%' }}>
	  <div className="vc-saturation--white"></div> 
	  <div className="vc-saturation--black"></div> 
	  <div className="vc-saturation-pointer" style={{ top: '18%', left: '75%'}}>
	  <div className="vc-saturation-circle"></div></div></div></div> 
	  <div className="vc-sketch-controls">
	  <div className="vc-sketch-sliders">
	  <div onClick={(e) => getColors2(e, props.target, props.d)} className="vc-sketch-hue-wrap">
	  <div onClick={(e) => getColors2(e, props.target, props.d)} className="vc-hue vc-hue--horizontal"><div role="slider" aria-valuenow="0" aria-valuemin="0" aria-valuemax="360" className="vc-hue-container">
	  <div role="presentation" className="vc-hue-pointer" style={{ top: '0px', left: '0%' }}>
	  <div className="vc-hue-picker"></div></div></div></div></div> 
	  </div> 

	  <div className="vc-sketch-color-wrap"><div aria-label="Current color is rgb(218,146,146)" className="vc-sketch-active-color" style={{ background: 'rgb(0, 0, 0) none repeat scroll 0% 0%' }}></div> <div className="vc-checkerboard" style={{ backgroundImage: '"url("data:image/png' }}
  />
	  </div></div> 
	 <div className="vc-sketch-field">
	 
	 <div className="vc-sketch-field--double">
	 <div className="vc-editable-input">
	 <input onInput={(e) => hexColorChange(e, props.target, props.d)} aria-labelledby="input__label__hex__045" id="hex" className="vc-input__input" /> 
	 <span for="hex" id="input__label__hex__045" className="vc-input__label">hex</span> 
	 <span className="vc-input__desc"></span>
	 </div>
	 </div> 
	 
	 <div className="vc-sketch-field--single">
	 <div className="vc-editable-input">
	 <input onInput={(e) => RgbColorChange(e, props.target, props.d)} id="r" aria-labelledby="input__label__r__871" id="r" className="vc-input__input" /> 
	 <span for="r" id="input__label__r__871" className="vc-input__label">r</span> 
	 <span className="vc-input__desc"></span></div></div> 
	 
	 <div className="vc-sketch-field--single">
	 <div className="vc-editable-input">
	 <input onInput={(e) => RgbColorChange(e, props.target, props.d)} id="g" aria-labelledby="input__label__g__125" id="g" className="vc-input__input" /> 
	 <span for="g" id="input__label__g__125" className="vc-input__label">g</span> 
	 <span class="vc-input__desc"></span></div></div> 
	 
	 <div class="vc-sketch-field--single">
	 <div class="vc-editable-input">
	 <input onInput={(e) => RgbColorChange(e, props.target, props.d)} id="b" aria-labelledby="input__label__b__478" class="vc-input__input" /> 
	 <span for="b" id="input__label__b__478" class="vc-input__label">b</span> 
	 <span class="vc-input__desc"></span></div></div> 

	 <div class="vc-sketch-field--single">
	 <div class="vc-editable-input">
	 <input onInput={(e) => alphaChange(e, props.target, props.d)} id="a" aria-labelledby="input__label__b__478" class="vc-input__input" /> 
	 <span for="a" id="input__label__a__478" class="vc-input__label">alpha</span> 
	 <span class="vc-input__desc"></span></div></div> 
	 </div> 

	 	<div onClick={(e) => setPaletteColor(e, props.target, props.d)} className="ColorPalette_section__3ijad" data-cy="color-palette">
	
	<div data-cy="color-FFFFFF" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(255, 255, 255) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-000000" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(0, 0, 0) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-EEEEEE" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(238, 238, 238) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-BDBDBD" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(189, 189, 189) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-757575" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(117, 117, 117) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-B0BEC5" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(176, 190, 197) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-78909C" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(120, 144, 156) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-546E7A" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(84, 110, 122) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-EF9A9A" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(239, 154, 154) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-EF5350" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(239, 83, 80) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-E53935" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(229, 57, 53) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-FF1744" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(255, 23, 68) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-F48FB1" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(244, 143, 177) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-EC407A" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(236, 64, 122) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-D81B60" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(216, 27, 96) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-F50057" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(245, 0, 87) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-CE93D8" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(206, 147, 216) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-AB47BC" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(171, 71, 188) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-8E24AA" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(142, 36, 170) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-D500F9" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(213, 0, 249) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-B39DDB" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(179, 157, 219) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-7E57C2" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(126, 87, 194) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-5E35B1" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(94, 53, 177) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-651FFF" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(101, 31, 255) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-9FA8DA" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(159, 168, 218) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-5C6BC0" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(92, 107, 192) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-3949AB" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(57, 73, 171) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-3D5AFE" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(61, 90, 254) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-90CAF9" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(144, 202, 249) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-42A5F5" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(66, 165, 245) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-1E88E5" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(30, 136, 229) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-2979FF" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(41, 121, 255) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-81D4FA" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(129, 212, 250) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-29B6F6" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(41, 182, 246) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-039BE5" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(3, 155, 229) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-00B0FF" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(0, 176, 255) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-80DEEA" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(128, 222, 234) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-26C6DA" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(38, 198, 218) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-00ACC1" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(0, 172, 193) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-00E5FF" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(0, 229, 255) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-80CBC4" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(128, 203, 196) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-26A69A" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(38, 166, 154) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-00897B" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(0, 137, 123) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-1DE9B6" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(29, 233, 182) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-A5D6A7" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(165, 214, 167) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-66BB6A" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(102, 187, 106) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-43A047" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(67, 160, 71) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-00E676" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(0, 230, 118) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-C5E1A5" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(197, 225, 165) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-9CCC65" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(156, 204, 101) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-7CB342" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(124, 179, 66) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-76FF03" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(118, 255, 3) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-E6EE9C" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(230, 238, 156) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-D4E157" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(212, 225, 87) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-C0CA33" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(192, 202, 51) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-C6FF00" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(198, 255, 0) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-FFF59D" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(255, 245, 157) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-FFEE58" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(255, 238, 88) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-FDD835" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(253, 216, 53) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-FFEA00" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(255, 234, 0) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-FFE082" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(255, 224, 130) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-FFCA28" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(255, 202, 40) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-FFB300" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(255, 179, 0) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-FFC400" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(255, 196, 0) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-FFCC80" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(255, 204, 128) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-FFA726" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(255, 167, 38) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-FB8C00" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(251, 140, 0) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-FF9100" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(255, 145, 0) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-FFAB91" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(255, 171, 145) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-FF7043" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(255, 112, 67) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-F4511E" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(244, 81, 30) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-FF3D00" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(255, 61, 0) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-BCAAA4" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(188, 170, 164) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-8D6E63" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(141, 110, 99) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-6D4C41" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(109, 76, 65) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-fef7ef" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(254, 247, 239) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-f7f8ff" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(247, 248, 255) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-f1fbf1" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(241, 251, 241) none repeat scroll 0% 0%'}}></div>
	<div data-cy="color-fbf1fb" className="ColorPalette_colorPreview__33iMp" style={{background: 'rgb(251, 241, 251) none repeat scroll 0% 0%'}}></div>
	
     </div>


	 </div></div></div>
	</div>
)
}


Colors2.displayName = "Colors2";
export default React.memo(Colors2);
import React, { useEffect, useState } from 'react';
import {strToMili, rgbToHex} from './utils';

import axios from 'axios'; 

/* global $ */

//  add multiple heads-text, their width, subtitle width, seekbar width
//  video position and dimensions!


//use useState to store the states,transfer it to state on page change

function Save(props){
  const [mount, setMount] = useState(false)

  useEffect(() => {
     setMount(true);
  
  },[])

const saveState = function() {

  // useEffect(() => {

    if(!mount) return;

  // var state = props.state;
  var ch = document.getElementById("subdiv").children;

var subtitle = [];

// dont consider last child (SPAN) which is END 
for (var i=0; i<ch.length-1 ; i++){
  let s = {}
  if (ch[i].tagName == "DIV") {continue;}

 // sub.push(ch[i].innerText);
 // stim.push(ch[i].getAttribute("stime"));
 // etim.push(ch[i].getAttribute("etime"));
 

 s['start_time'] = ch[i].getAttribute("stime");
 s['words'] = ch[i].innerText;
 s['end_time'] = ch[i].getAttribute("etime");
 subtitle.push(s)
}

var head = document.getElementById("head").innerText;
var title = {'head': head};
 // this.setState({...state, sub: sub });
 // var data = {'sub':sub, 'stime':stim, 'etime':etim}

function trim (str) {
  return str.replace(/^\s+|\s+$/gm,'');
}

function getrgb(rgba) {
var inParts = rgba.substring(rgba.indexOf("(")).split(",")
var r = parseInt(trim(inParts[0].substring(1)), 10),
      g = parseInt(trim(inParts[1]), 10),
      b = parseInt(trim(inParts[2]), 10)

try {
var a = parseFloat(parseFloat(trim(inParts[3].substring(0, inParts[3].length - 1))).toFixed(2));
} catch {
  var a = 1
}
 a = (a*100)
 var hex = rgbToHex(r,g,b)
 var z = hex.substring(0, 2)
 var y = hex.substring(2,4)
 var x = hex.substring(4,6)

 a = 100 - a 
 if (a == 100) {
  a = 'ff'
 } else {
  a = a.toString().padStart(2,0);
 }

var h = '&H' + a + x + y + z
console.log('hoolaa','r',r,'g',g,'b',b,'a',a, 'h', h)
return h 
}


function gethex(rgba) {

  var inParts = rgba.substring(rgba.indexOf("(")).split(",")
  var r = parseInt(trim(inParts[0].substring(1)), 10),
      g = parseInt(trim(inParts[1]), 10),
      b = parseInt(trim(inParts[2]), 10)

try {
var a = parseFloat(parseFloat(trim(inParts[3].substring(0, inParts[3].length - 1))).toFixed(2));
} catch {
  var a = 1
}
 a = a.toString()
 var hex = rgbToHex(r,g,b)

 return '#' + hex + '@' + a

}

//  ratio 
var r = document.getElementById("custom-seekbar11").getAttribute("ratio");
// subtitle text
var sfs = parseFloat(document.getElementById("subtitle-text-container").style.fontSize)*r
sfs = Math.ceil(sfs*3/2);

var sps = parseFloat(document.getElementById("subtitle-text-container").style.padding)*r
sps = Math.ceil(sps);

var sc = document.getElementById("subtitle-text-container").style.color
var sbc = document.getElementById("subtitle-text-container").style.backgroundColor

sc = getrgb(sc)
sbc = getrgb(sbc)
// head text
// need to add all the text content!
var hfs = parseFloat(document.getElementById("head-text-container").style.fontSize)*r
hfs = Math.ceil(hfs*3/2)
var hps = Math.ceil(parseFloat(document.getElementById("head-text-container").style.padding)*r)

var hc = document.getElementById("head-text-container").style.color
var hbc = document.getElementById("head-text-container").style.backgroundColor
hc = getrgb(hc)
hbc = getrgb(hbc)

// padding color 
var pc = document.getElementById("vid").style.backgroundColor
pc = gethex(pc)
// seekbar
var seekh = Math.ceil(parseFloat(document.getElementById("custom-seekbar1").style.height)*r)

var bck = document.getElementById("custom-seekbar1").style.backgroundColor
bck = gethex(bck)
var frnt = document.getElementById("custom-seekbar11").style.backgroundColor
frnt = gethex(frnt)

//  font
var font_s = document.getElementById("subtitle-text-container").style.fontFamily;
var font_h = document.getElementById("head-text-container").style.fontFamily;

//  nh nw
var nh = parseInt(document.getElementById("custom-seekbar11").getAttribute("nh"));
var nw = parseInt(document.getElementById("custom-seekbar11").getAttribute("nw"));


// var ar = parseFloat(props.state.state.ar)
 // calculate on the spot 
// var d = parseFloat(props.state.state.duration)
// var v = props.state.state.v;


var filename = localStorage.getItem("filename");
// console.log(props.state.state.filename)

var h, s, align_h, align_s;


var ht = document.getElementById("heads-container").style.top
var bt = document.getElementById("heads-container").style.bottom

var hs = document.getElementById("subtitles-container").style.top
var bs = document.getElementById("subtitles-container").style.bottom


if (bt == 'auto') {
  h = parseFloat(ht)
  align_h = 8
} else { 
  h = parseFloat(bt) 
  align_h = 2; 
}



if (bs == 'auto') {
  // s = $('#subtitles-container').position().top
  s = parseFloat(hs)
  align_s = 8
} else { 
  s = parseFloat(bs) 
  align_s = 2; 
}


// h = $('#heads-container').position().top
console.log(h,s)
h = h*r
s = s*r

// wrap or full back
var fs =0, fh = 0;
var hw = document.getElementsByClassName("wrap")[0].style.display 
var sw = document.getElementsByClassName("wrap")[1].style.display
if (sw == "none") { fs=1 }
if (hw == "none") { fh=1 }

//  seekbar position
var ytx = parseFloat(document.getElementById("custom-seekbar1").style.bottom);
ytx = ytx*r

var jdata = {subtitle, title, pc, seekh, bck, frnt, hfs,hps,hc,hbc, sfs,sps,sc,sbc, font_h, font_s,nh,nw,filename, s, h, fs, fh, ytx, align_s, align_h};
 // fetch('http://localhost:5000/save', {
 //      method: "POST",
 //      // body: suby,
 //      dataType: 'json',
 //      type: 'POST',
 //      body: JSON.stringify(jdata),
 //      contentType: 'application/json',

 //      // headers: {
 //      //   'Content-Type': 'application/json',
 //      // }

 //    });

 axios.post('http://localhost:5000/save', JSON.stringify(jdata), 
    {
      headers: {
        'Content-Type': 'application/json',
        // 'Cache-Control': 'no-cache',
      }

    }).then((res) => {
      console.log('save', res.data)  //res.data has processed file path, 
      // show + download it

      var url = 'http://localhost:5000/download/' + res.data + '?cachebuster=' + Date.now()
      // a.setAttribute('download', res.data)
      // a.click()

      var a = document.createElement('a');
      // a.target = '_blank';
      a.href = url;
      // a.style.display = 'none';
      // document.getElementById('subdiv').appendChild(a); // Required for Firefox
      a.click();
      // a.remove();

    })



 // props.changeBothTime(sub, stim, etim);
 
// },[])

}

return (
<div>


<button id="save" type="button" className="btn btn-default" data-toggle="tab" onClick={(e)=> saveState()}>Create!</button> 


</div>
		)
}


Save.displayName = "Save";
export default React.memo(Save);



/* global $ */

import {gettime, miliToTime, miliToHrCommaTime} from './utils';



const addTickerF = function(t) { 
  console.log('in addticker');

    

	var totalchars = document.getElementById('pooo').textContent.length

	var poW = document.getElementById("pooo").offsetWidth
	var oneWidth = totalchars / poW
	var charP = $('#vid').width() * oneWidth;
	charP = Math.ceil(charP);
	console.log('old churpos', charP, totalchars, 'vid', $('#vid').width(), poW, oneWidth)

  	const selectedSub = t
  	const idn = selectedSub.id;
  	console.log("span idn ", idn);


    /* var cursorPos = $('#text').prop('selectionStart') */
    // var cursorPos = window.getSelection().getRangeAt(0).startOffset;
    var cursorPos = charP;
    
    // var v = document.getElementById(idn).innerText;
    const v = selectedSub.innerText;
    console.log('text',v);
    console.log('char',v.charAt(cursorPos), 'cursor:',cursorPos,'v', v);

    var pa_stime = selectedSub.getAttribute("stime");
    var pa_etime = selectedSub.getAttribute("etime");
    // console.log(v.charAt(cursorPos) == ' ')


    while (v.charAt(cursorPos) != ' ') {

    	cursorPos = cursorPos - 1;
    	console.log('cur find', cursorPos);
    }

   

    console.log('new curpos', cursorPos );
    // console.log('!!add ticker cursorPos', cursorPos, 'v',v.length);
    var tim = gettime(idn, cursorPos);
    // console.log('in add ticker get time:',t);
    var t = miliToTime(tim);
    //console.log('in add ticker get time:',t);

    // console.log('selected sub-', 'sub value before-',selectedSub.value);
    var textBefore = v.substring(0,  cursorPos );
    var textAfter  = v.substring( cursorPos, v.length );
    
    selectedSub.innerText =  textBefore;
    console.log(selectedSub.innerText, 'tB',textBefore);
    // console.log('selected sub', 'cur',cursorPos,'textBefore', textBefore, 'selected sub value:', selectedSub.value);
    // props.changeCaptions(textBefore);

    // console.log("child", document.getElementById("subdiv").children );
    // console.log(' strt length:', strt);

    var i = document.getElementsByClassName("caption").length;
    var pa = selectedSub;

    var cdiv = document.createElement("div");
    cdiv.setAttribute("caption-break", "");  

 
    cdiv.className = "caption-break";
    cdiv.contentEditable = "true";
    cdiv.draggable = "true";


    // var ticker = document.createElement("button");
    var ticker = document.createElement("span");

    //do not write random text in time button
    ticker.setAttribute("contentEditable", "false");
    ticker.setAttribute("id", "bt"+i.toString());
    // console.log('bttn id', ticker.id);
    ticker.style.position = 'relative';

    
    // cdiv.appendChild(tickdiv);
    cdiv.appendChild(ticker);
    // ticker.appendChild(spantick);
    // tickdiv.appendChild(inputtick);

    // pa.appendChild(cdiv);
    pa.insertAdjacentElement("afterend", cdiv);

    var cspan = document.createElement("span");
    cspan.setAttribute("stime", t);
    cspan.setAttribute("etime", pa_etime);

    selectedSub.setAttribute("etime", t);

    cspan.setAttribute("className", "caption current-caption");
    cspan.setAttribute("class", "caption current-caption");
        
    cspan.setAttribute("contentEditable", "true");

    cspan.innerText = textAfter; //sub[i];
    cspan.setAttribute("id", i);

    console.log('new ticker text', cspan.innerText);



    // pa.appendChild(cspan);
    cdiv.insertAdjacentElement("afterend", cspan);

    // cspan.className = "caption";
    cspan.setAttribute("className", "caption");
    cspan.setAttribute("class", "caption");

    ticker.style.backgroundColor = 'black';
   	ticker.style.color = 'white';
   	ticker.style.borderColor = "pink";
   	ticker.style.padding = "2px";
   	ticker.setAttribute("width", "30px");
   	// inputtick.setAttribute("type", "hidden");
   	cspan.setAttribute("lineHeight","10em");
    ticker.innerHTML = t;


}

const deleteNode = function(cx, cx_prev)
{


  console.log('@@@@@!!', cx_prev.textContent, cx.textContent);
  cx_prev.textContent = cx_prev.textContent + ' ' + cx.textContent;
  cx_prev.setAttribute("etime", cx.getAttribute("etime"));
  cx.parentNode.removeChild(cx.previousSibling);
  cx.parentNode.removeChild(cx);


}

export const setSubTime = function() {

  $("#loading").css("display", "block");
  
  var ch = document.getElementById('subtitle-text-container');
  var chead = document.getElementById('subtitles-text-container');

  var style = window.getComputedStyle(ch);
  
  var padding_left = parseFloat(style.getPropertyValue("padding-left"));
  var padding_right = parseFloat(style.getPropertyValue("padding-right"));
   
  var border_left = parseFloat(style.getPropertyValue("border-left-width"));
  var border_right = parseFloat(style.getPropertyValue("border-right-width"));
  
  var cx = document.getElementById("subdiv").children;

  console.log('cx', cx);

  let x = []
  for (var i=0; i<cx.length ; i++){
    if (cx[i].tagName == "SPAN") {

        console.log('ZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZ')
        console.log(cx[i])
        x.push(cx[i].id)
    }
  }

//  not deleting END
  console.log('x' ,x, x.length)
  for (var i=1; i<x.length-1 ; i++){

    var cx = document.getElementById(x[i])
    // i-1 could have been deleted
    console.log('i',i,'cx', cx, 'pre',cx.previousSibling)
    var cx_prev = cx.previousSibling.previousSibling

    console.log('cx_prev', cx_prev)
    console.log('cx', cx)
    
    var new_sub = cx.textContent + ' ' + cx_prev.textContent
    
     
      console.log('newsub', new_sub)
    var cS = ch.cloneNode();
    cS.style.visibility = "hidden";
    cS.setAttribute('id', 'po')
    cS.setAttribute('class', 'po')
    cS.textContent = new_sub;
    cS.style.whiteSpace = 'nowrap';
    chead.appendChild(cS);
    console.log('***wid span', new_sub,$('#po').width(),cS.offsetWidth);


    var ff = cS.offsetWidth
    chead.removeChild(cS)

    // ff = ff / Math.ceil(ff / $('#vid').width());
    ff = 40 + ff + padding_left + padding_right + border_left + border_right
    console.log('@@@ff',ff,$('#vid').width())
    // ff = ff;
    if (ff < ($('#vid').width())) { 
        console.log('delete', cx)   
        console.log('del them',cx, cx_prev)
       deleteNode(cx, cx_prev)
    }

    

}

$("#loading").css("display", "none");
}


export const arrangeSub = function() {

	var ch = document.getElementById('subtitle-text-container');
    var chead = document.getElementById('subtitles-text-container');
  

    var style = window.getComputedStyle(ch);

    var padding_left = parseFloat(style.getPropertyValue("padding-left"));
    var padding_right = parseFloat(style.getPropertyValue("padding-right"));
   
    var border_left = parseFloat(style.getPropertyValue("border-left-width"));
    var border_right = parseFloat(style.getPropertyValue("border-right-width"));

    var cS = ch.cloneNode();
    cS.style.visibility = "hidden";
    cS.setAttribute('id', 'pooo')
    cS.setAttribute('class', 'pooo')
    cS.style.whiteSpace = 'nowrap';
    chead.appendChild(cS);

    var childrens = document.getElementById("subdiv").children;

   for (var i=0; i<childrens.length ; i++){
   	 if (childrens[i].tagName == 'SPAN') {
   	 	console.log('child', childrens[i]);
   	 	cS.textContent = childrens[i].textContent;
   	 	var ff = cS.offsetWidth;  
   	 	var n = Math.ceil(ff / $('#vid').width());
   	 	// ff = ff / n;
    	console.log('ff',ff, 'textContent', cS.textContent)
    	// ff = 10 + ff + padding_left + padding_right + border_left + border_right

    	console.log('ff net', ff, $('#vid').width() - 10)
    	console.log( ff > ($('#vid').width() - 10) )

    	if (ff > $('#vid').width()) {
       		addTickerF(childrens[i])
    	}

   	 	
   	 }
   }

    chead.removeChild(cS)
    
}





export const setSubBegin = function() {

  $("#loading").css("display", "block");
  
  var ch = document.getElementById('subtitle-text-container');
  var chead = document.getElementById('subtitles-text-container');

  var style = window.getComputedStyle(ch);
  
  var padding_left = parseFloat(style.getPropertyValue("padding-left"));
  var padding_right = parseFloat(style.getPropertyValue("padding-right"));
   
  var border_left = parseFloat(style.getPropertyValue("border-left-width"));
  var border_right = parseFloat(style.getPropertyValue("border-right-width"));
  

    const meta = JSON.parse(localStorage.getItem('meta'));

    var strt=[];
    var en=[];
    var sub=[];
    // for(var i=0;i<meta.length;i++){
    //   strt.push(meta[i].start_time);
    //   en.push(meta[i].end_time);
    //   sub.push(meta[i].words);
    // }

//  not deleting END
  // console.log('x' ,x, x.length)
  // for (var i=1; i<x.length-1 ; i++){
    
  var i = 0
  var k = 0   
  var test_sub = ''

  var s=0; 
  console.log('meta', meta)
  console.log('meta length', meta.length)

  var start = meta[0].start_time

  while( i < meta.length) {

    // i-1 could have been deleted

    var new_sub = test_sub + meta[i].words + ' '
    
     
    console.log('newsub', new_sub, 'META',meta[i].words, 'TEST', test_sub)
    var cS = ch.cloneNode();
    cS.style.visibility = "hidden";
    cS.setAttribute('id', 'po')
    cS.setAttribute('class', 'po')
    cS.textContent = new_sub;
    cS.style.whiteSpace = 'nowrap';
    chead.appendChild(cS);

    var ff = cS.offsetWidth
    chead.removeChild(cS)

    // ff = ff / Math.ceil(ff / $('#vid').width());
    ff = 40 + ff + padding_left + padding_right + border_left + border_right
    console.log('@@@ff',ff,$('#vid').width())
    // ff = ff;
    if (ff < ($('#vid').width())) {  
        test_sub = new_sub  
        i = i + 1
        console.log('&&&sub:',sub.length)

       // deleteNode(cx, cx_prev)
    } else {
      // strt.push(meta[s].start_time)
      strt.push(start)
      en.push(meta[i].end_time)
      sub.push(test_sub)
      console.log('##IN##: ',i,test_sub)
      test_sub = ''

      start = meta[i].end_time
      
      s = i
      console.log('ELSE: sub!!',sub.length)
    }


    
      console.log('####SUB',sub.length, sub)
}

  if ( test_sub != '') {

      strt.push(meta[s].start_time)
      en.push(meta[i-1].end_time)
      sub.push(test_sub)
      console.log('@###SUB',sub.length)
  }
    var viddur = miliToTime(parseFloat(localStorage.getItem("duration"))*1000)

    // var se = ['33', '9']

// change end_time to duration for last element
    en[sub.length-1] = viddur;
    strt[sub.length] = viddur;
    en[sub.length] = viddur;
    sub[sub.length] = " ";

    console.log('---SUB',sub.length, en.length, strt.length)
    console.log('@@sub:', sub)
    localStorage.setItem('subx', JSON.stringify(sub));
    localStorage.setItem('langx', JSON.stringify(sub));
    localStorage.setItem('en', JSON.stringify(en));
    localStorage.setItem('strt', JSON.stringify(strt));
    console.log('lengthhh', sub.length, JSON.stringify(sub))


$("#loading").css("display", "none");
}




export const splitSub = function(lang) {

  $("#loading").css("display", "block");
  
  var ch = document.getElementById('subtitle-text-container');
  var chead = document.getElementById('subtitles-text-container');

  

    const meta = JSON.parse(localStorage.getItem('meta'));

    var strt=[];
    var en=[];
    var sub=[];
    // for(var i=0;i<meta.length;i++){
    //   strt.push(meta[i].start_time);
    //   en.push(meta[i].end_time);
    //   sub.push(meta[i].words);
    // }

//  not deleting END
  // console.log('x' ,x, x.length)
  // for (var i=1; i<x.length-1 ; i++){
    
  var i = 0
  var k = 0   
  var test_sub = ''
  var start

  var s=0; 
  console.log('meta', meta)
  console.log('meta length', meta.length)

  if (meta.length  != 0 ) start = meta[0].start_time

  var len, dot, comma, split

  while( i < meta.length) {
    split = 0

    // i-1 could have been deleted

    var new_sub = test_sub + meta[i].words + ' '
    len = new_sub.length
      
    console.log('meta.words', meta[i].words.includes("."))
    dot = meta[i].words.includes(".")
    comma = meta[i].words.includes(",")
    if(meta[i].words.toLowerCase() == 'to') { split = 1 }
    try{
    if(meta[i+1].words.toLowerCase() == "but") { split = 1}
    if(meta[i+1].words.toLowerCase() == "and") { split = 1}
    if((new_sub + meta[i+1].words).length > 84) { split = 1}
    console.log('@@@ Word length', (new_sub + meta[i+1].words).length)

  } catch(e) { console.log(e) }


    if (dot || comma || split) {  

      // strt.push(meta[s].start_time)
      strt.push(start)
      en.push(meta[i].end_time)

      // remove trailing and leading spaces
      new_sub = new_sub.trim()
      sub.push(new_sub)
      console.log('##IN##: ',i,new_sub)
      test_sub = ''
      console.log('START-END', start, meta[i].end_time)

      // start = meta[i+1].start_time

      try {

      start = meta[i+1].start_time

      } catch (e) {

          console.log(e)
          console.log('meta i+1 is not needed')
      }
      
      
      s = i+1
      i = i+1
      console.log('ELSE: sub!!',sub.length)

    } else {
      
      test_sub = new_sub  
      i = i + 1
      console.log('&&&sub:',sub.length)

     // deleteNode(cx, cx_prev)

    }


    
      console.log('####SUB',sub.length, sub)
}

// last subtitle is remaining
  if ( test_sub != '') {

      strt.push(meta[s].start_time)
      en.push(meta[i-1].end_time)

      test_sub = test_sub.trim()
      sub.push(test_sub)
      console.log('@###SUB',sub.length)
  }
  var viddur = miliToHrCommaTime(parseFloat(localStorage.getItem("duration"))*1000)



    // var se = ['33', '9']

// change end_time to duration for last element
    en[sub.length-1] = viddur;
    strt[sub.length] = viddur;
    en[sub.length] = viddur;
    sub[sub.length] = " ";

    console.log('---SUB',sub.length, en.length, strt.length)
    console.log('@@sub:', sub)
    localStorage.setItem('subx', JSON.stringify(sub));
    localStorage.setItem('langx', JSON.stringify(sub));
    localStorage.setItem('en', JSON.stringify(en));
    localStorage.setItem('strt', JSON.stringify(strt));
    console.log('lengthhh', sub.length, JSON.stringify(sub))


$("#loading").css("display", "none");
}



export const arab_splitSub = function(lang) {

  $("#loading").css("display", "block");
  


    const meta = JSON.parse(localStorage.getItem('meta'));

    var strt=[];
    var en=[];
    var sub=[];

    for(var i=0;i<meta.length;i++)
    {
      strt.push(meta[i].start_time);
      en.push(meta[i].end_time);
      sub.push(meta[i].words);
    }



    console.log('---SUB',sub.length, en.length, strt.length)
    console.log('@@sub:', sub)
    localStorage.setItem('subx', JSON.stringify(sub));
    localStorage.setItem('langx', JSON.stringify(sub));
    localStorage.setItem('en', JSON.stringify(en));
    localStorage.setItem('strt', JSON.stringify(strt));
    console.log('lengthhh', sub.length, JSON.stringify(sub))


$("#loading").css("display", "none");
}


import './navbarhome.css';

import React, { useState,useEffect } from 'react';


import { Link } from "gatsby"


function Navpricing(props) {




	return (
		<div>


		<nav className="navbar navbar-inverse navbar-fixed-top home-fixed-top" role="navigation">
    
        <div className="navbar-header">

            <div className="navbar-brand" >
                <Link className="CB" to="/">TurtleClip</Link> 
            </div>
        
        
    </div>
</nav>


		</div>

		)
}


Navpricing.displayName = "Navpricing";
export default React.memo(Navpricing);

// the setting bar towards left to change styling


import React, { useState,useEffect } from 'react';
// import Color from './colors';
import Colors2 from './colors2';
import Textstyle from './textstyle';
import './vidstyle.css';

import Tab1 from './tab1';

import CancelIcon from '@material-ui/icons/Cancel';

import {setTemplate1} from './setTemplate';
import {setWidth, setDim, miliToTime} from './utils'
import axios from 'axios'; 

// import {fade} from 'material-ui/styles/colorManipulator';

import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const progUp = function (e) {

  document.getElementById("custom-seekbar1").style.bottom = parseFloat(document.getElementById("custom-seekbar1").style.bottom) + 1 + 'px'; 

}

const progDwn = function (e) {
  console.log('prog', document.getElementById("custom-seekbar1").style.bottom)
    document.getElementById("custom-seekbar1").style.bottom = parseFloat(document.getElementById("custom-seekbar1").style.bottom) - 1 + 'px';

}

const closeStyle = function (e) {
      document.getElementById("xcolumn").style.display="none"
}


function setARatio(e, props) {
  var txtt = e.target.innerText.split(' ')[0]
  console.log('txtt', txtt)
   e.currentTarget.previousSibling.firstChild.innerHTML = txtt;


  var r = e.target.id 

  setDim(r, props)



// var vidx = document.getElementById("vid1");
// vidx.load();
// vidx.onloadeddata = function() {


  setTemplate1(1, props);
//     // setWidth(1);


// };

   // console.log('set ration', e.currentTarget.previousSibling.innerText, e.currentTarget, e.target.innerText);
}



function closeWidget(e) {

// close if user is writing subtitles
try{
  if(document.getElementById("subdiv").contains(e.target)) {
    
    document.getElementById('xcolumn').style.display='none'
    
  }
} catch {}

try {
  if (!document.getElementById("xx").contains(e.target)) {
  
    document.getElementById("rx").style.display="block"
    document.getElementById("xx").style.display="none"
    document.getElementsByClassName("vid-fixed-top")[0].style.opacity = 1

    console.log(e.target)
  }
} catch {}
// id is either parent circle or button
	if (e.target.classList.contains("bgColor")) {
	// document.getElementById("ycolumn").style.display = "block";
	return;
	} 

  if (document.getElementById("ycolumn")) {
// if the target is a child of 'color widget'
	if (document.getElementById("ycolumn").contains(e.target)) {
	   // document.getElementById("ycolumn").style.display = "block";
	return;	
	}

  document.getElementById("ycolumn").style.display = "none";

// Zoom slider
  if (e.target.classList.contains("zoomd") || e.target.id == "zoomd") {
    // document.getElementById("overlayzoom").style.display = "block"
    return;
  }
  
  if (document.getElementById("overlayzoom")) {
      if (document.getElementById("overlayzoom").contains(e.target)) {
    // document.getElementById("overlayzoom").style.display = "block";
    return;
  } 
  
  document.getElementById("overlayzoom").style.display = "none"

  debugger
  // fonts - head and sub
  var ft = document.getElementsByClassName("SubtitleTools_subtitleDropDownList__2Q4CU");
  var i = 0
  if (e.target.id == "fontin") {
    // ft[i].style.display = "block"
    return;
  }
  
  if (ft[0].style.display != "none") {
      if (ft[0].contains(e.target)) {
        // ft[i].style.display = "block";
        return;
  } 
}

  if (ft[1].style.display != "none") {
      if (ft[1].contains(e.target)) {
        // ft[i].style.display = "block";
        return;
  } 
}

// ft[0].style.display = "none"
// ft[1].style.display = "none"
  
  
  }
}

}



function calcSub() {

    const meta = JSON.parse(localStorage.getItem('meta'));

    console.log('meta', meta);
    // // console.log(props.match.params, props.location.state);
    // // console.log('dur',props.location.state.vid.duration);
    var strt=[];
    var en=[];
    var sub=[];
    for(var i=0;i<meta.length;i++){
      strt.push(meta[i].start_time);
      en.push(meta[i].end_time);
      sub.push(meta[i].words);
    }

    // var se = parseFloat(props.location.state.vid.duration).toFixed(1).split('.');
    // var se = parseFloat(localStorage.getItem("duration")).toFixed(1).split('.');
    var viddur = miliToTime(parseFloat(localStorage.getItem("duration"))*1000)

    // var se = ['33', '9']

// change end_time to duration for last element
    en[i-1] = viddur;
    strt[i] = viddur;
    en[i] = viddur;
    sub[i] = " ";

    localStorage.setItem('sub', JSON.stringify(sub));
    localStorage.setItem('en', JSON.stringify(en));
    localStorage.setItem('strt', JSON.stringify(strt));

    console.log('DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD');
    console.log(JSON.parse(localStorage.getItem('sub')));

}


function generateSub(e, s, setS) {

  var data = {}
  data['filename'] = localStorage.getItem('filename');
  axios.post('http://localhost:5000/getsub', data,
      {
    
      headers: {
        'Content-Type': 'application/json',
      // 'Content-Type': 'multipart/form-data',
      },

    }).then((response) => {


      return response;

    }).then((body) => {
        console.log('body r', body.data.data)

        var meta = body.data.data.meta
        localStorage.setItem('meta', JSON.stringify(meta))
        
        calcSub();
        setS('true');
        document.getElementById("dcolumn").style.display = 'block';

})

}

function zoomD(e) {
  console.log('zoom', e.target)
  document.getElementById("overlayzoom").style.display = "block"
}

function setbgColor(e, setTarget) {
  if (e.target.classList.contains("bgColor") || e.target.parentNode.classList.contains("bgColor")) {
  document.getElementById("ycolumn").style.display = "block";

  var tar = e.currentTarget;

  console.log('%',tar, tar.tagName == 'BUTTON', tar.children[0])
  if (tar.tagName == 'BUTTON') {
      tar = tar.children[0];
      console.log(tar);

  }

  console.log('setTarget', tar);

  setTarget(tar);

  var offset = tar.getBoundingClientRect();
  //var left = (offset.left + offset.width/2);
  var top = (offset.top + offset.height); 
  // var top = offset.top;  
  var left = offset.left;

  // e.currentTarget.style.color = document.getElementsByClassName('vc-sketch-active-color')[0].style.background.split(" none")[0];

  console.log('widget', tar, top, left);

  // document.getElementById("ycolumn").style.marginTop = top +'px';
  // document.getElementById("ycolumn").style.marginLeft = 0 + 'px';
}
}

function Vidstyle(props){

// set the bkgrnd color as default
  const [target, setTarget] = useState(0); 
  const [d, setD] = useState("s"); 
  const [s, setS] = useState("false")

  // const props = props;
  // console.log('##tar', target);

	useEffect(() => {

	document.body.addEventListener("click", (e) => closeWidget(e));


// zoom

  // Zoom in video 
  /* Array of possible browser specific settings for transformation */
  var properties = ['transform', 'WebkitTransform', 'MozTransform',
                    'msTransform', 'OTransform'],
      prop = properties[0];

  /* Iterators and stuff */    
  var i,j;

  var stage = document.getElementById('stage');
  /* Find out which CSS transform the browser supports */
  for(i=0,j=properties.length;i<j;i++){
    if(typeof stage.style[properties[i]] !== 'undefined'){
      prop = properties[i];
      break;
    }
  }

  var v = document.getElementById('vid1');
  var slider = document.getElementById("zoom");
  slider.addEventListener("input", function(e){
    e.preventDefault();
    e.stopPropagation();
    // document.getElementById("demo").innerHTML = this.value;
    slider.value = parseInt(slider.value) 
    var zoom = 1 + parseInt(slider.value)/10 ;
  // // console.log('zoom',zoom);
    v.style[prop]='scale('+zoom+')';
  });

  // var slide = document.getElementById("opacity");
  // slide.addEventListener("input", function(e){
  //   e.preventDefault();
  //   e.stopPropagation();
  //   // document.getElementById("demo").innerHTML = this.value;
  //   slide.value = parseInt(slide.value) 
  //   var opacity = parseInt(slide.value)/10 ;

  // });


	},)


// vc-saturation selects the color

return (
  <div style={{display: 'flex'}}>
	<div id="xcolumn">
  <CancelIcon id="stylecancel" onClick={closeStyle} style={{ display: 'inline-block', cursor: 'pointer' }} />
	 
  <Colors2 d={d} target={target}/>

	<div className="sidenav">

    <div className="flexy">

    <div className="flexy">

    <div className="coly">
    
	 	<div className="textinwidget">Video</div>
    <div className='aspec'>
    <div className="dropdown aspect">
  			<button type="button" className="btn btn-default dropbtn" data-toggle="tab">Size
          <br/> 
          <span id='ar'>9:16</span>
          <ExpandMoreIcon/>
        </button>
  			
        <div onClick={(e) => setARatio(e,props)} className="dropdown-content">
    			<a id='1:1'>1:1 Square</a>
    			<a id='16:9'>16:9 LandScape</a>
    			<a id='9:16'>9:16 Vertical</a>
    			<a id='4:5'>4:5 Portrait</a>
    			<a id='org'>Org Size</a>	
  			</div>

	 	</div>

      <button onClick={(e) => setbgColor(e, setTarget)} type="button" className="v btn btn-default bgColor" data-toggle="tab"><FiberManualRecordIcon id="v" onClick={(e) => setbgColor(e, setTarget)} className="v bgColor" style={{ fill: 'rgb(139,0,0)', 'fill-opacity':"1", display:'block', margin:'auto' }}/>vidbck</button>

      <button onClick={zoomD} id="zoomd" type="button" className="btn btn-default zoomd" data-toggle="tab">Zoom</button>    

      <button type="button" className="btn btn-default" data-toggle="tab">LOGO</button> 
    </div>
    </div>
    

    <div className="coly2">
      <div className="textinwidget">progress bar</div>  
      <div className="flexy">
        <button onClick={(e) => setbgColor(e, setTarget)} type="button" className="bp btn btn-default bgColor" data-toggle="tab"><FiberManualRecordIcon id="bp" onClick={(e) => setbgColor(e, setTarget)} className="bp bgColor" style={{ fill: 'rgb(155,123,0)', 'fill-opacity':"1", display:'block', margin:'auto' }}/> bck </button>
        <button onClick={(e) => setbgColor(e, setTarget)} type="button" className="fp btn btn-default bgColor" data-toggle="tab"><FiberManualRecordIcon id="fp" onClick={(e) => setbgColor(e, setTarget)} className="fp bgColor" style={{ fill: 'rgb(14,125,205)', 'fill-opacity':"1", display:'block', margin:'auto' }}/>front</button>


        <button onClick={(e) => progDwn(e, props)} type="button" className="btn btn-default" data-toggle="tab">
        <ArrowDownwardIcon style={{display:'block', margin: 'auto'}}/>
        dwn 
        </button> 

        <button onClick={(e) => progUp(e, props)} type="button" className="btn btn-default" data-toggle="tab"><ArrowUpwardIcon style={{display:'block', margin: 'auto'}}/>up</button>


      </div>  
    </div>




    </div>

      <div className="coly">
      <div id="heady" style={{display: 'block'}} className="coly">
        <div className="coly">
          <div className="textinwidget">Heading</div> 
        </div>
        <Textstyle d="h" setTarget={setTarget} setD={setD}/>
      </div>
      <button onClick={(e) => generateSub(e, s, setS)} id='generateSub' type="button" className="btn btn-default" data-toggle="tab">Generate Subtitles</button>
      <div id="suby" style={{ display: 'block'}}>
        <div className="textinwidget">Subtitle</div>
        <Textstyle d="s" setTarget={setTarget} setD={setD}/>
      </div>
      </div>
    
    </div>


      <div id="overlayzoom">
      <div className="slidercontain">
        <input type="range" className="slider" min="0" max="10" step="1" value="0" id="zoom" /> 
      </div>
      </div>
      


	   </div>

  </div>
  <Tab1 s={s} style={{position: 'absolute'}} />
	</div>
)
}


Vidstyle.displayName = "Vidstyle";
export default React.memo(Vidstyle);
import axios from 'axios'; 


export function get_srt(e, lang) {

                e.preventDefault();
                e.stopPropagation();

                var t = JSON.parse(localStorage.getItem('strt'))
                var en = JSON.parse(localStorage.getItem('en'))
                var subx = JSON.parse(localStorage.getItem('subx'))



                var subtitle = [];
                // dont consider last child (SPAN) which is END 
                for (var i=0; i<subx.length-1 ; i++){

                  let s = {}

                 s['start_time'] = t[i]; 
                 s['words'] = subx[i];
                 s['end_time'] = en[i];

                
                 subtitle.push(s)


                }



              var url = "https://ubfxpkg884.execute-api.us-west-2.amazonaws.com/default/translatebear_create_srt"

                axios.post(url, subtitle,
                {
              
                headers: {
                  'Content-Type': 'application/json',
                },

              }).then((response) => {



                console.log('response', response.data)


    var data = new Blob([response.data], {type: 'text/srt'});


    var textFile = window.URL.createObjectURL(data);


    var link = document.createElement('a');
    link.setAttribute('download', 'subtitle_' + lang + '.srt');
    link.href = textFile;
    document.body.appendChild(link);
    link.click()

    document.body.removeChild(link);

}).catch(err => {
            console.log('nooo, error :(', err)
            document.getElementById("circleTrans").style.display = 'none'
          }) 
  }

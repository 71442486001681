import {strToMili, rgbToHex} from './utils';

export function trim (str) {
  return str.replace(/^\s+|\s+$/gm,'');
}

export function getrgb(rgba) {
var inParts = rgba.substring(rgba.indexOf("(")).split(",")
var r = parseInt(trim(inParts[0].substring(1)), 10),
      g = parseInt(trim(inParts[1]), 10),
      b = parseInt(trim(inParts[2]), 10)

try {
var a = parseFloat(parseFloat(trim(inParts[3].substring(0, inParts[3].length - 1))).toFixed(2));
} catch {
  var a = 1
}
 a = (a*100)
 var hex = rgbToHex(r,g,b)
 var z = hex.substring(0, 2)
 var y = hex.substring(2,4)
 var x = hex.substring(4,6)

 a = 100 - a 
 if (a == 100) {
  a = 'ff'
 } else {
  a = a.toString().padStart(2,0);
 }

var h = '&H' + a + x + y + z
// console.log('hoolaa','r',r,'g',g,'b',b,'a',a, 'h', h)
return h 
}


export function gethex(rgba) {

  var inParts = rgba.substring(rgba.indexOf("(")).split(",")
  var r = parseInt(trim(inParts[0].substring(1)), 10),
      g = parseInt(trim(inParts[1]), 10),
      b = parseInt(trim(inParts[2]), 10)

try {
var a = parseFloat(parseFloat(trim(inParts[3].substring(0, inParts[3].length - 1))).toFixed(2));
} catch {
  var a = 1
}
 a = a.toString()
 var hex = rgbToHex(r,g,b)

 return '#' + hex + '@' + a

}
